import { useParams } from "react-router-dom";
import useFlagEnabled from "@/features";
import {
  LayoutDashboard,
  MessageSquare,
  MessagesSquare,
  BarChart,
  ClipboardCheck,
  Settings,
  BookOpen,
  Users,
  Tags,
  Puzzle,
  Handshake,
} from "lucide-react";

export function useLocalNav() {
  const { projectId } = useParams();
  const flagTabProfiles = useFlagEnabled("tab-profiles");
  const flagSales = useFlagEnabled("sales");

  if (projectId === undefined) {
    return {
      insights: [],
      chatbot: [],
      misc: [],
    };
  }

  const insights = [
    {
      name: "Dashboard",
      href: `/${projectId}`,
      Icon: LayoutDashboard,
    },
    {
      name: "Conversations",
      href: `/${projectId}/conversations`,
      Icon: MessagesSquare,
    },
    {
      name: "Messages",
      href: `/${projectId}/messages/search`,
      Icon: MessageSquare,
    },
    {
      name: "Topics",
      href: `/${projectId}/topics`,
      Icon: Tags,
    },
    {
      name: "Metrics",
      href: `/${projectId}/metrics`,
      Icon: BarChart,
    },
  ];

  const chatbot = [
    {
      name: "Integration",
      href: `/${projectId}/frames`,
      Icon: Puzzle,
    },
    {
      name: "Knowledge",
      href: `/${projectId}/dataproviders`,
      Icon: BookOpen,
    },
    {
      name: "Evaluation",
      href: `/${projectId}/testsuites`,
      Icon: ClipboardCheck,
    },
  ];

  if (flagTabProfiles)
    chatbot.push({
      name: "Profiles",
      href: `/${projectId}/profiles`,
      Icon: Users,
    });

  const misc = [
    {
      name: "Settings",
      href: `/${projectId}/settings`,
      Icon: Settings,
    },
  ];

  if (flagSales)
    misc.push({
      name: "Sales Dashboard",
      href: `/${projectId}/sales`,
      Icon: Handshake,
    });

  return { insights, chatbot, misc };
}
