import { RocketIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";

import type { LauncherSettings as LauncherSettingsType } from "@/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { FrameSettingsValues } from "./FrameEditorPage";
import { ColorField } from "../ColorField";

type LauncherSettingsPath = `launcher_settings.${keyof LauncherSettingsType}`;

const MarginField = ({
  name,
  label,
}: {
  name: LauncherSettingsPath;
  label: string;
}) => {
  const form = useFormContext<FrameSettingsValues>();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="space-y-2">
          <FormLabel className="text-sm font-medium text-gray-700">
            {label}
          </FormLabel>
          <FormControl>
            <Input
              placeholder="20px"
              {...field}
              value={field.value ?? ""}
              className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
            />
          </FormControl>
          <FormMessage className="text-xs text-red-500" />
        </FormItem>
      )}
    />
  );
};

export const LauncherSettings = () => {
  const form = useFormContext<FrameSettingsValues>();

  return (
    <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <RocketIcon className="h-5 w-5 text-primary" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            Launcher Settings
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="space-y-8 p-6">
        <div className="grid grid-cols-2 gap-8">
          <div className="space-y-2">
            <ColorField
              label="Button Color"
              name="launcher_settings.button_color"
              className="text-sm font-medium text-gray-700"
            />
            <p className="text-sm text-gray-500">
              Choose a color that matches your brand
            </p>
          </div>

          <FormField
            control={form.control}
            name="launcher_settings.alignment"
            render={({ field }) => (
              <FormItem className="space-y-2">
                <FormLabel className="text-sm font-medium text-gray-700">
                  Button Alignment
                </FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="h-9 rounded-md border border-gray-200 bg-white/50 shadow-sm">
                        <SelectValue placeholder="Select alignment" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="left">Left</SelectItem>
                      <SelectItem value="right">Right</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage className="text-xs text-red-500" />
              </FormItem>
            )}
          />
        </div>

        <div>
          <h3 className="mb-4 text-sm font-medium text-gray-700">
            Spacing Settings
          </h3>
          <div className="grid grid-cols-2 gap-8 rounded-lg">
            <MarginField
              name="launcher_settings.side_margin"
              label="Side Margin"
            />
            <MarginField
              name="launcher_settings.bottom_margin"
              label="Bottom Margin"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default LauncherSettings;
