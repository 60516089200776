import { useUser } from "@clerk/clerk-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  RowSelectionState,
} from "@tanstack/react-table";
import {
  Check,
  Search,
  X,
  UserPlus,
  ClipboardCheck,
  Send as SendIcon,
  RefreshCw,
  HandshakeIcon,
  ArrowDown,
  ArrowUp,
  ArrowUpDown,
} from "lucide-react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { FrameList, ProjectRead } from "@/client";
import {
  getProjectOptions,
  listFramesOptions,
  searchProjectsOptions,
} from "@/client/@tanstack/react-query.gen";
import { MemberSelect } from "@/components/MemberSelect";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { createEnumHandler, useQueryState } from "@/hooks/useQueryState";
import { cn } from "@/lib/utils";

import Spinner from "./Loading";
import { Assignee } from "./ProjectSalesPage";
import { DealStatus } from "./sales/types";
import { DEFAULT_CAMPAIGNS } from "./sales/CampaignCard";
import { Checkbox } from "./ui/checkbox";
import ExportButton from "./ExportButton";
import { getPreviewUrl } from "./PreviewUrl";
import NavigationHeader from "./sidebar/NavigationHeader";

type Campaign = (typeof DEFAULT_CAMPAIGNS)[number];

export interface Project {
  id: string;
  name: string;
  website_url: string | null;
  created_at: string;
  included_internal_metadata: {
    reviewed: boolean;
    assignee: string | null;
    deal_status: DealStatus | null;
  };
}

interface FilterConfig {
  assignee?: string | null;
  campaign?: Campaign | "all" | "none";
  reviewed?: boolean;
  search?: string;
  deal_status?: DealStatus | "all";
}

interface TabConfig {
  disabledFields: Array<keyof FilterConfig>;
  fixedFilters: Partial<FilterConfig>;
}

type TabOption = "assign" | "review" | "send" | "sell";

const columnHelper = createColumnHelper<Project>();

const getSortIcon = (column: any) => {
  if (!column.getIsSorted()) return <ArrowUpDown className="ml-2 h-4 w-4" />;
  return column.getIsSorted() === "asc" ? (
    <ArrowUp className="ml-2 h-4 w-4" />
  ) : (
    <ArrowDown className="ml-2 h-4 w-4" />
  );
};

const handleSort = (column: any) => {
  if (!column.getIsSorted()) {
    column.toggleSorting(false); // set to desc
  } else if (column.getIsSorted() === "desc") {
    column.clearSorting(); // reset
  } else {
    column.toggleSorting(true); // set to asc
  }
};

const baseColumns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
        aria-label="Select all"
        className="mt-1"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        className="mt-1"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.accessor("id", {
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => handleSort(column)}
        className="-ml-4"
      >
        ID
        {getSortIcon(column)}
      </Button>
    ),
    cell: (info) => (
      <Link to={`/${info.row.original.id}`} className="hover:text-blue-600">
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("name", {
    header: () => "Name",
    cell: (info) => info.getValue(),
    filterFn: "includesString",
  }),
  columnHelper.accessor("website_url", {
    header: () => "Domain",
    cell: (info) => (
      <Link
        to={`/${info.row.original.id}/sales`}
        className="hover:text-blue-600"
      >
        {getDomainFromUrl(info.getValue())}
      </Link>
    ),
    filterFn: "includesString",
  }),
  columnHelper.accessor("included_internal_metadata.assignee", {
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => handleSort(column)}
        className="-ml-4"
      >
        Assignee
        {getSortIcon(column)}
      </Button>
    ),
    cell: (info) => (
      <div className="w-52">
        <Assignee projectId={Number(info.row.original.id)} />
      </div>
    ),
  }),
  columnHelper.accessor("included_internal_metadata.reviewed", {
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => handleSort(column)}
        className="-ml-4"
      >
        Reviewed
        {getSortIcon(column)}
      </Button>
    ),
    cell: (info) =>
      info.getValue() ? (
        <Badge className="bg-green-100 text-green-800">
          <Check className="mr-1 h-3 w-3" />
          Reviewed
        </Badge>
      ) : (
        <Badge variant="secondary" className="bg-gray-100 text-gray-800">
          <X className="mr-1 h-3 w-3" />
          Not Reviewed
        </Badge>
      ),
  }),
  columnHelper.accessor("included_internal_metadata.deal_status", {
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => handleSort(column)}
        className="-ml-4"
      >
        Deal Status
        {getSortIcon(column)}
      </Button>
    ),
    cell: (info) => {
      const status = info.getValue();
      if (!status || status === "null") return "-";
      return (
        <Badge
          variant="secondary"
          className={cn("capitalize", {
            "bg-blue-100 text-blue-800": status === "open",
            "bg-red-100 text-red-800": status === "lost",
            "bg-green-100 text-green-800": status === "closed",
          })}
        >
          {status}
        </Badge>
      );
    },
  }),
  columnHelper.accessor("created_at", {
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => handleSort(column)}
        className="-ml-4"
      >
        Created At
        {getSortIcon(column)}
      </Button>
    ),
    cell: (info) => (
      <Link
        to={`/${info.row.original.id}/sales`}
        className="hover:text-blue-600"
      >
        {new Date(info.getValue()).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </Link>
    ),
    sortingFn: "datetime",
  }),

  columnHelper.accessor("id", {
    id: "actions", // unique id for the column
    header: () => "",
    cell: (info) => (
      <div className="flex gap-2">
        <Button
          variant="outline"
          size="sm"
          asChild
          className="whitespace-nowrap"
        >
          <Link to={`/${info.row.original.id}/frames`}>Integrations</Link>
        </Button>
        <Button variant="ghost" size="sm" asChild className="whitespace-nowrap">
          <Link to={`/${info.row.original.id}/sales`}>Sales</Link>
        </Button>
      </div>
    ),
    enableSorting: false,
  }),
];

function buildFilters(config: FilterConfig): string[] {
  {
    /*
  # Basic filters without quotes (no spaces in values)
  "internal_sales.status=active",              # Exact match
  "internal_sales.amount>1000",                # Numeric comparison
  "internal_sales.customer.name~john",         # ILIKE '%john%'
  "internal_sales.email~*%@company.com",       # ILIKE with pattern

  # Array operations
  "internal_sales.tags@>[\"premium\"]",        # Array contains "premium"
  "internal_sales.permissions@>[\"admin\"]",   # Array contains "admin"

  # Nested paths with various operators
  "internal_sales.customer.region!=EU",        # Not equal
  "internal_sales.metrics.views>=1000",        # Greater than or equal
  "internal_sales.address.country~united",     # Matches %united%

  # Numeric comparisons
  "internal_sales.metrics.conversion<0.5",     # Less than
  "internal_sales.revenue>=50000",             # Greater than or equal

  "internal_sales.customer.name!=null",        # Not null (essentially checks that path exists and value is not null)
  "internal_sales.customer.name=null",         # Null (if any part of path is null, true; if value is null, true)


  # Advanced

  # Quotes required only for values with spaces
  'customer.name="John Doe"',         # Quotes needed for space

  # AND/OR operations - by default AND
  'status=active && amount>1000',
  'country="United States" || country=Canada',

  # Complex combinations
  '(name~"john doe" || name~jane) && active=true',
  'status=active && (amount>1000 || amount<500)'
  */
  }
  const filters: string[] = [];

  if (config.assignee !== undefined) {
    if (config.assignee === null) {
      filters.push("assignee=null");
    } else {
      filters.push(`assignee=${config.assignee}`);
    }
  }

  if (config.reviewed !== undefined) {
    filters.push(`reviewed=${config.reviewed ? "true" : "false"}`);
  }

  // undefined -> all
  // null -> not with campaign
  // campaign -> with campaign
  if (config.campaign !== undefined && config.campaign !== "all") {
    if (config.campaign === "none") {
      filters.push("campaigns=null || campaigns=[]");
    } else {
      filters.push(`campaigns@>["${config.campaign}"]`);
    }
  }

  if (config.deal_status) {
    // const q = config.deal_status
    //   .map((status) => `deal_status=${status}`)
    //   .join(" || ");
    filters.push(`deal_status=${config.deal_status}`);
  }

  return filters;
}

function getDomainFromUrl(url: string | null): string {
  if (!url) return "-";
  try {
    return new URL(url).hostname;
  } catch {
    return "-";
  }
}

interface ProjectsTableProps {
  data: Project[];
  localSearch: string;
}

function EnhancedProjectsTable({ data, localSearch }: ProjectsTableProps) {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "created_at",
      desc: false,
    },
  ]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns: baseColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter: localSearch,
      sorting,
      rowSelection,
    },
    enableRowSelection: true,
    enableGlobalFilter: true,
    globalFilterFn: "fuzzy" as any,
    filterFns: {
      fuzzy: (row, _, filterValue) => {
        const searchValue = String(filterValue).toLowerCase();

        // Check name field
        const name = String(row.getValue("name")).toLowerCase();
        if (name.includes(searchValue)) return true;

        // Check domain
        const url = row.getValue("website_url");
        // @ts-expect-error type correctly https://github.com/TanStack/table/pull/4185
        const domain = getDomainFromUrl(url).toLowerCase();
        if (domain.includes(searchValue)) return true;

        return false;
      },
    },
  });

  const queryClient = useQueryClient();
  const exportTransformRows = async (rows: Project[]) => {
    // Fetch frame details for all selected projects
    const projectsWithFrames = await Promise.all(
      rows.map(async (project: Project) => {
        const frameResponse = await queryClient.fetchQuery<FrameList>(
          listFramesOptions({
            path: {
              project_id: Number(project.id),
            },
          }) as any
        );

        const firstFrame = frameResponse?.data?.[0];
        const frameId = firstFrame?.id || "";
        const assistantName = firstFrame?.settings?.assistant_name || "";

        // Generate preview URL
        const previewUrl = getPreviewUrl(frameId, project.website_url || "");
        return {
          id: project.id,
          website_url: project.website_url || "",
          frameId,
          assistantName,
          previewUrl,
        };
      })
    );
    return projectsWithFrames;
  };
  const exportColumns = [
    { key: "id", name: "Project Id" },
    { key: "website_url", name: "Account Website" },
    { key: "assistantName", name: "Assistant Name" },
    { key: "previewUrl", name: "Preview Url" },
  ];

  const handleExportComplete = () => setRowSelection({});
  const selectedRows = table.getSelectedRowModel().rows;
  return (
    <div className="space-y-4">
      <div className="flex justify-end gap-8 items-center">
        <span className="text-sm text-gray-500">
          {selectedRows.length} row{selectedRows.length !== 1 ? "s" : ""}{" "}
          selected
        </span>
        <ExportButton
          label="Outreach CSV"
          columns={exportColumns}
          enabled={selectedRows.length > 0}
          rows={selectedRows.map((r) => r.original)}
          transformRows={exportTransformRows}
          onSuccess={handleExportComplete}
        />
      </div>
      <div className="rounded-md border overflow-x-auto">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className="group hover:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={baseColumns.length}
                  className="h-24 text-center"
                >
                  No results found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

interface FilterBarProps {
  filters: FilterConfig;
  setFilters: (filters: FilterConfig) => void;
  disabledFields: Array<keyof FilterConfig>;
  localSearch: string;
  setLocalSearch: (search: string) => void;
}

const FilterBar = ({
  filters,
  setFilters,
  disabledFields,
  localSearch,
  setLocalSearch,
}: FilterBarProps) => {
  const renderSearchFilter = () => (
    <div>
      <label className="text-sm font-medium text-gray-700">Search</label>
      <div className="relative">
        <Input
          placeholder="Search names and domains..."
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
          className="pl-8"
        />
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-400" />
      </div>
    </div>
  );

  const renderCampaignFilter = () => {
    if (disabledFields.includes("campaign")) return null;

    return (
      <div>
        <label className="text-sm font-medium text-gray-700">Campaign</label>
        <Select
          value={filters.campaign || "all"}
          onValueChange={(value) =>
            setFilters({
              ...filters,
              campaign: value,
            })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Filter by campaign" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All</SelectItem>
            <SelectItem value="none">No Campaign</SelectItem>
            {DEFAULT_CAMPAIGNS.map((campaign) => (
              <SelectItem key={campaign} value={campaign}>
                {campaign}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderAssigneeFilter = () => {
    if (disabledFields.includes("assignee")) return null;

    return (
      <div>
        <label className="text-sm font-medium text-gray-700">Assignee</label>
        <MemberSelect
          value={filters.assignee}
          onValueChange={(value) =>
            setFilters({
              ...filters,
              assignee: value,
            })
          }
          placeholder="Filter by assignee"
        />
      </div>
    );
  };

  const renderReviewFilter = () => {
    if (disabledFields.includes("reviewed")) return null;

    return (
      <div>
        <label className="text-sm font-medium text-gray-700">
          Review Status
        </label>
        <Select
          value={
            filters.reviewed === undefined
              ? "all"
              : filters.reviewed
                ? "reviewed"
                : "not-reviewed"
          }
          onValueChange={(value) =>
            setFilters({
              ...filters,
              reviewed:
                value === "all"
                  ? undefined
                  : value === "reviewed"
                    ? true
                    : false,
            })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Filter by review status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="reviewed">Reviewed</SelectItem>
            <SelectItem value="not-reviewed">Not Reviewed</SelectItem>
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderDealStatusFilter = () => {
    if (disabledFields.includes("deal_status")) return null;

    return (
      <div>
        <label className="text-sm font-medium text-gray-700">Deal Status</label>
        <Select
          value={filters.deal_status ?? "all"}
          onValueChange={(value) =>
            setFilters({
              ...filters,
              deal_status:
                value === "all"
                  ? undefined
                  : value === null
                    ? "null"
                    : (value as DealStatus),
            })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Filter by deal status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="null">None</SelectItem>
            <SelectItem value="open">Open</SelectItem>
            <SelectItem value="lost">Lost</SelectItem>
            <SelectItem value="closed">Closed</SelectItem>
          </SelectContent>
        </Select>
      </div>
    );
  };

  const getActiveFilterCount = () => {
    return Object.keys(filters).filter(
      (key) =>
        !disabledFields.includes(key as keyof FilterConfig) &&
        filters[key as keyof FilterConfig] !== undefined
    ).length;
  };

  const activeFilterCount = getActiveFilterCount();

  return (
    <div className="space-y-4">
      {activeFilterCount > 0 && (
        <div className="text-sm text-gray-500">
          Active filters: {activeFilterCount}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {renderSearchFilter()}
        {renderCampaignFilter()}
        {renderAssigneeFilter()}
        {renderReviewFilter()}
        {renderDealStatusFilter()}
      </div>
    </div>
  );
};

export default function SalesPage() {
  const { user } = useUser();
  const [localSearch, setLocalSearch] = useState("");
  const [filters, setFilters] = useState<FilterConfig>({});
  const queryClient = useQueryClient();

  const [tab, setTab] = useQueryState<TabOption>(
    "tab",
    "assign",
    createEnumHandler(["assign", "review", "send", "sell"])
  );

  const tabConfigs = useMemo<Record<TabOption, TabConfig>>(
    () => ({
      assign: {
        disabledFields: ["reviewed", "campaign", "assignee", "deal_status"],
        fixedFilters: {
          assignee: null,
          reviewed: false,
        },
      },
      review: {
        disabledFields: ["reviewed", "campaign", "assignee", "deal_status"],
        fixedFilters: {
          reviewed: false,
          assignee: user?.id ?? null,
        },
      },
      send: {
        disabledFields: ["campaign", "reviewed"],
        fixedFilters: {
          campaign: "none",
          reviewed: true,
          assignee: user?.id ?? null,
        },
      },
      sell: {
        disabledFields: [],
        fixedFilters: {
          reviewed: true,
          assignee: user?.id ?? null,
        },
      },
    }),
    [user?.id]
  );

  // Reset filters when changing tabs
  const handleTabChange = (newTab: TabOption) => {
    setTab(newTab);
    setFilters(tabConfigs[newTab].fixedFilters);
    setLocalSearch("");
  };

  const query = searchProjectsOptions({
    query: {
      include_internal: true,
      internal_filters: buildFilters(filters),
    },
  });

  const { data: projects, isLoading } = useQuery({
    ...query,
    select: (projects) => {
      // NOTE(liamvdv): prevent N+1 queries with Assignee component
      // Update individual project caches for each project in the results
      // -> Also requires enabled: !queryClient.getQueryData(projectQuery.queryKey).
      // TODO(liamvdv): Memben - shouldn't strcutured cashing handle this?
      projects.projects.forEach((project: ProjectRead) => {
        const projectQueryKey = getProjectOptions({
          path: { project_id: project.id },
          query: { include_internal: true },
        }).queryKey;

        queryClient.setQueryData(projectQueryKey, project);
      });

      return projects;
    },
  });

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey: query.queryKey });
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <NavigationHeader items={[{ label: "Sales" }]} />
      <div className="">
        <CardHeader className="flex flex-row items-center justify-between px-0 md:px-6">
          <CardTitle>Sales Dashboard</CardTitle>
          <Button onClick={refresh} variant="outline" size="sm">
            <RefreshCw className="mr-2 h-4 w-4" />
            Refresh
          </Button>
        </CardHeader>
        <CardContent className="space-y-4 px-0 md:px-6">
          <Tabs value={tab} onValueChange={handleTabChange as any}>
            <div className="overflow-x-auto">
              <TabsList className="mb-4 w-full md:w-auto">
                <TabsTrigger
                  value="assign"
                  className="flex items-center sm:min-w-32"
                >
                  <UserPlus className="mr-2 h-4 w-4" />
                  <span className="hidden md:inline">Assign</span>
                  <span className="md:hidden">A</span>
                </TabsTrigger>
                <TabsTrigger
                  value="review"
                  className="flex items-center sm:min-w-32"
                >
                  <ClipboardCheck className="mr-2 h-4 w-4" />
                  <span className="hidden md:inline">Review</span>
                  <span className="md:hidden">R</span>
                </TabsTrigger>
                <TabsTrigger
                  value="send"
                  className="flex items-center sm:min-w-32"
                >
                  <SendIcon className="mr-2 h-4 w-4" />
                  <span className="hidden md:inline">Send</span>
                  <span className="md:hidden">S</span>
                </TabsTrigger>
                <TabsTrigger
                  value="sell"
                  className="flex items-center sm:min-w-32"
                >
                  <HandshakeIcon className="mr-2 h-4 w-4" />
                  <span className="hidden md:inline">Sell</span>
                  <span className="md:hidden">$</span>
                </TabsTrigger>
              </TabsList>
            </div>

            <FilterBar
              filters={filters}
              setFilters={setFilters}
              disabledFields={tabConfigs[tab].disabledFields}
              localSearch={localSearch}
              setLocalSearch={setLocalSearch}
            />

            <div className="mt-4">
              {!projects?.projects.length ? (
                <div className="text-center py-8 text-gray-500">
                  No projects match your filters
                </div>
              ) : (
                <EnhancedProjectsTable
                  data={projects.projects as any}
                  localSearch={localSearch}
                />
              )}
            </div>
          </Tabs>
        </CardContent>
      </div>
    </>
  );
}
