import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNowStrict } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import { getDataproviderOptions } from "@/client/@tanstack/react-query.gen";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentProjectBreadcrumbs, usePollPendingSnapshots } from "@/hooks";

import { HumanUploadButton, WebCrawlerSyncButton } from "./DataProviderActions";
import { ProviderDropdown } from "./DataProviderDropdown";
import { SnapshotTimeline } from "./SnapshotTimeline";
import SourcesList, { EmptySourcesState } from "../sources/SourcesList";
import LiveTimeDistance from "../LiveTimeDistance";
import NavigationHeader, {
  NavigationHeaderSkeleton,
} from "../sidebar/NavigationHeader";
import PageHeader, { PageHeaderSkeleton } from "../PageHeader";

function LoadingState() {
  return (
    <>
      <NavigationHeaderSkeleton />
      <PageHeaderSkeleton hasSubtitle />
      <div className="container mx-auto p-6 space-y-6">
        {/* Current Sources Card Skeleton */}
        <Card>
          <CardHeader>
            <Skeleton className="h-6 w-32 mb-2" />
            <Skeleton className="h-4 w-64" />
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <Skeleton className="h-12 w-full" />
              <Skeleton className="h-12 w-full" />
              <Skeleton className="h-12 w-full" />
            </div>
          </CardContent>
        </Card>

        {/* Source History Card Skeleton */}
        <Card>
          <CardHeader>
            <Skeleton className="h-6 w-32 mb-2" />
            <Skeleton className="h-4 w-64" />
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div className="flex items-center gap-4">
                <Skeleton className="h-8 w-8 rounded-full" />
                <div className="flex-1">
                  <Skeleton className="h-12 w-full rounded-lg" />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <Skeleton className="h-8 w-8 rounded-full" />
                <div className="flex-1">
                  <Skeleton className="h-12 w-full rounded-lg" />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default function DataProviderPage() {
  const { projectId, providerId } = useParams();
  const navigate = useNavigate();

  if (!projectId || !providerId) {
    throw new Error("Missing required parameters");
  }

  const { data: provider, isPending: providerIsPending } = useQuery({
    ...getDataproviderOptions({
      path: {
        project_id: Number(projectId),
        data_provider_id: providerId,
      },
    }),
  });

  const { snapshots, isPending: snapshotIsPending } = usePollPendingSnapshots({
    projectId: Number(projectId),
    dataProviderId: providerId,
  });

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: "./..", label: "Knowledge" },
    { label: provider?.name ?? "DataProvider" },
  ];

  if (providerIsPending || snapshotIsPending) {
    return <LoadingState />;
  }

  if (!provider || !snapshots) {
    return null;
  }

  const latestSnapshot = snapshots ? snapshots[0] : null;
  const latestSnapshotActive = latestSnapshot
    ? latestSnapshot.status === "PENDING"
    : false;

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="flex justify-between">
        <PageHeader title={provider.name} />
        <div className="flex items-start space-x-1">
          <div className="flex items-center">
            {provider.data_provider_type === "webcrawler" && (
              <div className="flex items-center space-x-1">
                {latestSnapshot && (
                  <div className="text-xs text-gray-500">
                    {latestSnapshot.end_time ? (
                      <>
                        Last synced{" "}
                        {formatDistanceToNowStrict(latestSnapshot.end_time, {
                          addSuffix: true,
                        })}
                      </>
                    ) : (
                      <>
                        Sync started{" "}
                        <LiveTimeDistance date={latestSnapshot.start_time} />
                      </>
                    )}
                  </div>
                )}
                <WebCrawlerSyncButton
                  provider={provider}
                  isSyncing={latestSnapshotActive}
                />
              </div>
            )}
            {provider.data_provider_type === "human" && (
              <HumanUploadButton
                provider={provider}
                hasActiveSnapshot={latestSnapshotActive}
              />
            )}

            <ProviderDropdown
              provider={provider}
              onDelete={() => {
                navigate(`/${projectId}/sources`);
              }}
            />
          </div>
        </div>
      </div>

      <div className="space-y-3">
        <h2 className="text-2xl font-bold">Current Sources</h2>
        {snapshots.length > 0 ? (
          <SourcesList
            projectId={Number(projectId)}
            providerId={providerId}
            snapshotId={snapshots[0].id}
            allowMutations={true}
          />
        ) : (
          <EmptySourcesState providerId={providerId} />
        )}
      </div>

      <div className="space-y-3">
        <div>
          <h2 className="text-2xl font-bold">Source History</h2>
          <p className="text-muted-foreground mt-1">
            Historical snapshots and changes to indexed content
          </p>
        </div>
        <SnapshotTimeline
          projectId={Number(projectId)}
          providerId={providerId}
        />
      </div>
    </>
  );
}
