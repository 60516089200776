import { Clock, CheckCircle2, XCircle, Loader2 } from "lucide-react";

import { DataProviderSnapshotStatus } from "@/client/types.gen";

export const SnapshotStatus = ({
  status,
}: {
  status: DataProviderSnapshotStatus;
}) => {
  const getStatusConfig = () => {
    switch (status) {
      case "COMPLETED":
        return {
          icon: CheckCircle2,
          className: "text-green-500",
          label: "Completed",
        };
      case "FAILED":
        return {
          icon: XCircle,
          className: "text-red-500",
          label: "Failed",
        };
      case "PENDING":
        return {
          icon: Loader2,
          className: "text-blue-500 animate-spin",
          label: "Running",
        };
      default:
        return {
          icon: Clock,
          className: "text-gray-500",
          label: status,
        };
    }
  };

  const { icon: Icon, className, label } = getStatusConfig();

  return (
    <div className="flex items-center gap-2">
      <Icon className={`h-4 w-4 ${className}`} />
      <span className="text-sm">{label}</span>
    </div>
  );
};
