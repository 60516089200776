// NOTE(memben): created with https://claude.ai/chat/adcb03c9-ec44-4019-aaec-9ef80bfc91e3
// using https://nextui.org/docs/components/scroll-shadow, https://github.com/nextui-org/nextui/tree/canary/packages/components/scroll-shadow/src as a foundation
import * as React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { cn } from "@/lib/utils";
import { ScrollBar } from "./ui/scroll-area";

interface ScrollShadowProps
  extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
  /**
   * The size of the shadow in pixels
   * @default 40
   */
  shadowSize?: number;
  /**
   * Whether to hide the scrollbar
   * @default false
   */
  hideScrollbar?: boolean;
  /**
   * The scroll orientation
   * @default "vertical"
   */
  orientation?: "horizontal" | "vertical" | "both";
  /**
   * Shadow offset in pixels
   * @default 0
   */
  offset?: number;
  /**
   * The distance over which the shadow fades (in pixels)
   * @default 20
   */
  fadeDistance?: number;
}

const ScrollShadow = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  ScrollShadowProps
>(
  (
    {
      className,
      children,
      shadowSize = 40,
      hideScrollbar = false,
      orientation = "vertical",
      offset = 0,
      fadeDistance = 20,
      ...props
    },
    ref
  ) => {
    const [shadowOpacity, setShadowOpacity] = React.useState({
      start: 0,
      end: 0,
    });

    const viewportRef = React.useRef<HTMLDivElement>(null);

    const calculateOpacity = React.useCallback(
      (value: number, threshold: number): number => {
        // Clamp the opacity between 0 and 1
        return Math.max(0, Math.min(1, value / threshold));
      },
      []
    );

    const handleScroll = React.useCallback(() => {
      const viewport = viewportRef.current;
      if (!viewport) return;

      const isHorizontal = orientation === "horizontal";
      const size = isHorizontal ? viewport.clientWidth : viewport.clientHeight;
      const scrollSize = isHorizontal
        ? viewport.scrollWidth
        : viewport.scrollHeight;
      const scroll = isHorizontal ? viewport.scrollLeft : viewport.scrollTop;

      // Calculate start opacity based on scroll position
      const startOpacity = calculateOpacity(scroll - offset, fadeDistance);

      // Calculate end opacity based on remaining scroll
      const remainingScroll = scrollSize - (scroll + size);
      const endOpacity = calculateOpacity(
        remainingScroll - offset,
        fadeDistance
      );

      setShadowOpacity({
        start: startOpacity,
        end: endOpacity,
      });
    }, [offset, orientation, fadeDistance, calculateOpacity]);

    React.useEffect(() => {
      const viewport = viewportRef.current;
      if (!viewport) return;

      handleScroll(); // Initial check
      viewport.addEventListener("scroll", handleScroll);
      // Add resize observer to handle content changes
      const observer = new ResizeObserver(handleScroll);
      observer.observe(viewport);

      return () => {
        viewport.removeEventListener("scroll", handleScroll);
        observer.disconnect();
      };
    }, [handleScroll]);

    // Dynamic shadow styles using CSS-in-JS
    const shadowStyle = React.useMemo(() => {
      const isVertical = orientation !== "horizontal";
      const isHorizontal = orientation !== "vertical";

      const createShadow = (direction: "start" | "end", opacity: number) => {
        if (opacity <= 0) return "";
        const size = shadowSize;
        const shadowColor = `rgba(0, 0, 0, ${opacity * 0.1})`; // Max opacity of 0.1

        const shadowProps = {
          top:
            direction === "start" && isVertical
              ? `inset 0 ${size}px ${size}px -${size}px ${shadowColor}`
              : "",
          bottom:
            direction === "end" && isVertical
              ? `inset 0 -${size}px ${size}px -${size}px ${shadowColor}`
              : "",
          left:
            direction === "start" && isHorizontal
              ? `inset ${size}px 0 ${size}px -${size}px ${shadowColor}`
              : "",
          right:
            direction === "end" && isHorizontal
              ? `inset -${size}px 0 ${size}px -${size}px ${shadowColor}`
              : "",
        };

        return Object.values(shadowProps).filter(Boolean).join(", ");
      };

      const combinedShadows = [
        createShadow("start", shadowOpacity.start),
        createShadow("end", shadowOpacity.end),
      ]
        .filter(Boolean)
        .join(", ");

      return {
        boxShadow: combinedShadows || "none",
        transition: "box-shadow 0.15s ease-in-out",
      };
    }, [orientation, shadowSize, shadowOpacity]);

    return (
      <ScrollAreaPrimitive.Root
        ref={ref}
        className={cn("relative overflow-hidden", className)}
        style={shadowStyle}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          ref={viewportRef}
          className="h-full w-full rounded-[inherit]"
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        {!hideScrollbar && (
          <>
            {(orientation === "vertical" || orientation === "both") && (
              <ScrollBar orientation="vertical" />
            )}
            {(orientation === "horizontal" || orientation === "both") && (
              <ScrollBar orientation="horizontal" />
            )}
            <ScrollAreaPrimitive.Corner />
          </>
        )}
      </ScrollAreaPrimitive.Root>
    );
  }
);

ScrollShadow.displayName = "ScrollShadow";

export { ScrollShadow };
export type { ScrollShadowProps };
