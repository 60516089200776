import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader2, Save } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ProfileRead, AliasRead } from "@/client";
import {
  listProfilesOptions,
  createProfileMutation,
  createBuildDeploymentJobMutation,
  getBuildDeploymentJobOptions,
  listAliasesOptions,
  updateAliasMutation,
} from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import NavigationHeader from "../sidebar/NavigationHeader";
import { useCurrentProjectBreadcrumbs } from "@/hooks";

const SystemPromptForm = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [systemPrompt, setSystemPrompt] = useState("");
  const [jobId, setJobId] = useState<string | null>(null);
  const [isPolling, setIsPolling] = useState(false);

  // Get existing profiles
  const { data: profilesData, isLoading: isLoadingProfiles } = useQuery({
    ...listProfilesOptions({
      path: { project_id: Number(projectId) },
    }),
  });

  // Get aliases
  const { data: aliasesData } = useQuery({
    ...listAliasesOptions({
      path: { project_id: Number(projectId) },
    }),
  });

  // Get production alias
  const getProductionAlias = (): AliasRead | null => {
    if (!aliasesData?.aliases) return null;
    const productionAliases = aliasesData.aliases.filter(
      (alias) => alias.type === "MUTABLE"
    );
    if (productionAliases.length !== 1) {
      console.error(
        "Expected exactly one production alias, found:",
        productionAliases.length
      );
      return null;
    }
    return productionAliases[0];
  };

  // Update alias mutation
  const updateAlias = useMutation({
    ...updateAliasMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "Production alias updated successfully",
      });
      void queryClient.invalidateQueries({
        queryKey: listAliasesOptions({
          path: { project_id: Number(projectId) },
        }).queryKey,
      });
    },
    onError: (error) => {
      toast.error("Error", {
        description: "Failed to update production alias",
      });
      console.error("Failed to update alias:", error);
    },
  });

  // Create new profile mutation
  const createProfile = useMutation({
    ...createProfileMutation(),
    onSuccess: (data) => {
      createBuildJob.mutate({
        path: { project_id: Number(projectId) },
        body: {
          project_id: Number(projectId),
          profile_id: data.id,
          description: `Auto build for profile update ${getNextVersion()}`,
        },
      });
    },
  });

  // Create build job mutation
  const createBuildJob = useMutation({
    ...createBuildDeploymentJobMutation(),
    onSuccess: (data) => {
      setJobId(data.id);
      setIsPolling(true);
    },
  });

  // Poll for job status if we have a jobId
  const { data: jobStatus } = useQuery({
    ...getBuildDeploymentJobOptions({
      path: {
        project_id: Number(projectId),
        job_id: jobId || "",
      },
    }),
    enabled: !!jobId && isPolling,
    refetchInterval: 1000,
  });

  // Handle job status updates
  useEffect(() => {
    if (!jobStatus || !isPolling) return;

    if (jobStatus.status === "SUCCESS") {
      setIsPolling(false);
      setJobId(null);

      // Update production alias
      const productionAlias = getProductionAlias();
      if (productionAlias) {
        updateAlias.mutate({
          path: {
            project_id: Number(projectId),
            alias_id: productionAlias.id,
          },
          body: {
            id: productionAlias.id,
            deployment_id: jobStatus.success!.deployment_id,
            description: productionAlias.description,
            type: "MUTABLE",
          },
        });
      }

      toast("Success", {
        description: `Deployment completed successfully. Deployment ID: ${jobStatus.success!.deployment_id}`,
      });
      console.log("Deployment ID:", jobStatus.success!.deployment_id);

      void queryClient.invalidateQueries({
        queryKey: listProfilesOptions({
          path: { project_id: Number(projectId) },
        }).queryKey,
      });
    } else if (jobStatus.status === "FAILURE") {
      setIsPolling(false);
      setJobId(null);
      toast.error("Error", {
        description: "Deployment failed",
      });
    }
  }, [jobStatus, isPolling, projectId, queryClient, toast, updateAlias]);

  // Rest of the component remains the same...
  const getLatestProfile = () => {
    if (!profilesData?.profiles.length) return null;

    return profilesData.profiles.reduce(
      (latest: ProfileRead | null, current: ProfileRead) => {
        if (!latest) return current;
        return new Date(latest.created_at) > new Date(current.created_at)
          ? latest
          : current;
      },
      null
    );
  };

  const getNextVersion = () => {
    const latestProfile = getLatestProfile();
    if (!latestProfile) return "v0.0";

    const currentVersion = latestProfile.name;
    const match = currentVersion.match(/v(\d+)\.(\d+)/);
    if (!match) return "v0.0";

    const [_, major, minor] = match;
    return `v${major}.${parseInt(minor) + 1}`;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const latestProfile = getLatestProfile();
    if (!latestProfile) return;

    // Validate production alias exists
    const productionAlias = getProductionAlias();
    if (!productionAlias) {
      toast.error("Error", {
        description: "Expected exactly one production alias",
      });
      return;
    }

    const newProfile = {
      project_id: Number(projectId),
      name: getNextVersion(),
      content: {
        ...latestProfile.content,
        prompt: {
          ...latestProfile.content.prompt,
          system_message: systemPrompt,
        },
      },
    };

    createProfile.mutate({
      path: { project_id: Number(projectId) },
      body: newProfile,
    });
  };

  // Set initial system prompt from latest profile
  React.useEffect(() => {
    const latestProfile = getLatestProfile();
    if (latestProfile) {
      setSystemPrompt(latestProfile.content.prompt.system_message);
    }
  }, [profilesData]);

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Profiles" },
  ];

  if (isLoadingProfiles) {
    return (
      <div className="flex justify-center p-4">
        <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
      </div>
    );
  }

  const isSubmitting =
    createProfile.isPending || createBuildJob.isPending || isPolling;

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <Card>
        <CardHeader>
          <CardTitle>Update System Prompt</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Textarea
                value={systemPrompt}
                onChange={(e) => setSystemPrompt(e.target.value)}
                className="min-h-[400px]"
                placeholder="Enter system prompt..."
                disabled={isSubmitting}
              />
              <p className="text-sm text-gray-500">
                Next version will be: {getNextVersion()}
              </p>
            </div>
            <div className="flex justify-end">
              <Button
                type="submit"
                disabled={isSubmitting || !systemPrompt}
                variant={"default"}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {isPolling ? "Building Deployment..." : "Saving..."}
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    Save New Version
                  </>
                )}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default SystemPromptForm;
