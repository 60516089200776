import { useEffect, useState } from "react";

import { Feature } from "@/features";

import Spinner from "./Loading";

function IFrameWrapper({ src }: { src: string }) {
  return (
    <div style={{ height: "calc(100dvh - 4rem)" }} className="flex flex-col">
      <div className="flex-grow">
        <iframe
          className="w-full h-full"
          id="iframe"
          src={src}
          scrolling="no"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default function AdminDashboard() {
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    const getCredentials = async () => {
      const platformToken = await (window as any).Clerk.session.getToken({
        template: "api_botbrains_io",
      });
      setUrl(
        `${
          import.meta.env.VITE_DASHBOARD_URL
        }?embed=true&token=${platformToken}`
      );
    };
    getCredentials();
  });
  return (
    <Feature flag="admin-dashboard">
      {url === "" ? <Spinner /> : <IFrameWrapper src={url} />}
    </Feature>
  );
}
