import { Link, useLocation, LinkProps } from "react-router-dom";

export default function LinkWithQuery({ to, ...props }: LinkProps) {
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);

  let path = "";
  let toSearchParams = new URLSearchParams();

  if (typeof to === "string") {
    const [pathname, search] = to.split("?");
    path = pathname;
    toSearchParams = new URLSearchParams(search);
  } else if (to && typeof to === "object") {
    path = to.pathname || "";
    toSearchParams = new URLSearchParams(to.search);
  }

  // Merge the search parameters, giving precedence to 'to' search params
  for (const [key, value] of currentSearchParams.entries()) {
    if (!toSearchParams.has(key)) {
      toSearchParams.set(key, value);
    }
  }

  const toWithQuery = `${path}?${toSearchParams.toString()}`;

  return <Link to={toWithQuery} {...props} />;
}
