import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { CheckSquare, Square } from "lucide-react";
import { useState, useCallback, useEffect } from "react";
import { toast } from "sonner";

import { ProjectUpdate } from "@/client";
import {
  updateProjectMutation,
  getProjectOptions,
} from "@/client/@tanstack/react-query.gen";
import { Badge } from "@/components/ui/badge";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

import StatusIndicator from "../StatusIndicator";
import { DealStatus, DEAL_STATUSES } from "./types";

export const NotesCard = ({ projectId }: { projectId: number }) => {
  const [notes, setNotes] = useState("");
  const [syncStatus, setSyncStatus] = useState<"synced" | "syncing" | "error">(
    "synced"
  );
  const queryClient = useQueryClient();

  const projectQuery = getProjectOptions({
    path: { project_id: projectId },
    query: { include_internal: true },
  });

  const { data: project, isLoading: isLoadingProject } = useQuery({
    ...projectQuery,
  });

  useEffect(() => {
    if (project?.included_internal_metadata?.notes) {
      setNotes(project.included_internal_metadata.notes);
    }
  }, [project]);

  const updateProject = useMutation({
    ...updateProjectMutation({
      query: { merge: true },
    }),
    onSuccess: () => {
      setSyncStatus("synced");
      queryClient.invalidateQueries({ queryKey: projectQuery.queryKey });
    },
    onError: (error) => {
      console.error("Failed to save notes:", error);
      setSyncStatus("error");
      toast.error("Failed to save notes");
    },
  });

  const debouncedSave = useCallback(
    debounce((updatedNotes: string) => {
      if (!project) return;

      const updatedProject: ProjectUpdate = {
        ...project,
        internal_metadata: {
          notes: updatedNotes,
        },
      };

      updateProject.mutate({
        path: { project_id: projectId },
        body: updatedProject,
      });
    }, 1000),
    [projectId, project]
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setNotes(newValue);
    setSyncStatus("syncing");
    debouncedSave(newValue);
  };

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Notes</CardTitle>
        <div className="flex items-center gap-2">
          <StatusIndicator status={syncStatus} isLoading={isLoadingProject} />
        </div>
      </CardHeader>
      <CardContent>
        <Textarea
          value={notes}
          onChange={handleChange}
          placeholder="Add internal notes here..."
          className="min-h-[200px]"
          disabled={isLoadingProject}
        />
      </CardContent>
    </Card>
  );
};

export const ReviewToggle = ({ projectId }: { projectId: number }) => {
  const [isReviewed, setIsReviewed] = useState(false);
  const [syncStatus, setSyncStatus] = useState<"synced" | "syncing" | "error">(
    "synced"
  );
  const queryClient = useQueryClient();

  const projectQuery = getProjectOptions({
    path: { project_id: projectId },
    query: { include_internal: true },
  });

  const { data: project, isLoading: isLoadingProject } = useQuery({
    ...projectQuery,
  });

  useEffect(() => {
    if (project?.included_internal_metadata?.reviewed !== undefined) {
      setIsReviewed(project.included_internal_metadata.reviewed);
    }
  }, [project]);

  const updateProject = useMutation({
    ...updateProjectMutation({
      query: { merge: true },
    }),
    onSuccess: () => {
      setSyncStatus("synced");
      queryClient.invalidateQueries({ queryKey: projectQuery.queryKey });
    },
    onError: (error) => {
      console.error("Failed to update review status:", error);
      setSyncStatus("error");
      setIsReviewed(!isReviewed); // Revert on error
      toast.error("Failed to update review status");
    },
  });

  const handleToggle = () => {
    if (!project || isLoadingProject) return;

    const newReviewedState = !isReviewed;
    setIsReviewed(newReviewedState);
    setSyncStatus("syncing");

    const updatedProject: ProjectUpdate = {
      ...project,
      internal_metadata: {
        reviewed: newReviewedState,
      },
    };

    updateProject.mutate({
      path: { project_id: projectId },
      body: updatedProject,
    });
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Demo Checklist</CardTitle>
        <div className="flex items-center gap-2">
          <StatusIndicator status={syncStatus} isLoading={isLoadingProject} />
        </div>
      </CardHeader>
      <CardContent>
        <div
          className={`flex items-center gap-3 p-3 rounded-lg transition-colors ${
            isLoadingProject ? "opacity-50" : "cursor-pointer hover:bg-gray-50"
          }`}
          onClick={handleToggle}
        >
          {isReviewed ? (
            <CheckSquare className="h-5 w-5 text-primary" />
          ) : (
            <Square className="h-5 w-5 text-gray-400" />
          )}
          <span className="font-medium text-gray-700">
            {isReviewed ? "Reviewed" : "Mark as Reviewed"}
          </span>
          <div
            className={`h-2.5 w-2.5 rounded-full ml-2 ${
              isReviewed ? "bg-green-500" : "bg-yellow-500"
            }`}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export const DealStatusSelector = ({ projectId }: { projectId: number }) => {
  const [dealStatus, setDealStatus] = useState<DealStatus>("null");

  const queryClient = useQueryClient();

  const projectQuery = getProjectOptions({
    path: { project_id: projectId },
    query: { include_internal: true },
  });

  const { data: project, isLoading: isLoadingProject } = useQuery({
    ...projectQuery,
  });

  useEffect(() => {
    if (project?.included_internal_metadata?.deal_status !== undefined) {
      setDealStatus(
        (project.included_internal_metadata?.deal_status as DealStatus) ||
          "null"
      );
    }
  }, [project]);

  const updateProject = useMutation({
    ...updateProjectMutation({
      query: { merge: true },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectQuery.queryKey });
    },
    onError: (error) => {
      console.error("Failed to update deal status:", error);

      toast.error("Failed to update deal status");
    },
  });

  const handleStatusChange = (newStatus: DealStatus) => {
    if (!project || isLoadingProject) return;

    setDealStatus(newStatus);

    const updatedProject: ProjectUpdate = {
      ...project,
      internal_metadata: {
        deal_status: newStatus === "null" ? null : newStatus,
      },
    };

    updateProject.mutate({
      path: { project_id: projectId },
      body: updatedProject,
    });
  };

  const getCurrentStatus = () => {
    const status = DEAL_STATUSES.find((s) => s.value === dealStatus);
    return status || DEAL_STATUSES[0];
  };

  return (
    <Select
      value={dealStatus}
      onValueChange={(value) => handleStatusChange(value as DealStatus)}
      disabled={isLoadingProject}
    >
      <SelectTrigger className="w-full">
        <SelectValue>
          <Badge
            variant="secondary"
            className={`capitalize ${getCurrentStatus().color}`}
          >
            {getCurrentStatus().label}
          </Badge>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {DEAL_STATUSES.map(({ value, label, color }) => (
          <SelectItem key={value} value={value}>
            <Badge variant="secondary" className={`capitalize ${color}`}>
              {label}
            </Badge>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
