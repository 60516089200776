export const mimeTypeLabels = {
  "text/plain": "Plain Text",
  "text/html": "HTML",
  "text/markdown": "Markdown",
  "text/csv": "CSV",
  "application/pdf": "PDF",
  "application/msword": "Word",
  "application/vnd.ms-excel": "Excel",
  "application/vnd.ms-powerpoint": "PowerPoint",
  "image/jpeg": "JPEG",
  "image/png": "PNG",
  "application/epub": "ePub",
  "application/xml": "XML",
  "application/vnd.oasis.opendocument.text": "OpenDocument Text",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "Word (OpenXML)",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "Excel (OpenXML)",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "PowerPoint (OpenXML)",
  "application/epub+zip": "ePub (ZIP)",
  "text/xml": "XML",
  "text/rtf": "RTF",
  "text/x-markdown": "Markdown",
  "text/x-csv": "CSV",
  "text/prs.fallenstein.rst": "RST",
  "application/csv": "CSV",
  "application/vnd.ms-outlook": "Outlook",
  "application/rtf": "RTF",
  "message/rfc822": "RFC822",
} as const;
export type MimeTypeLabel = keyof typeof mimeTypeLabels;
