import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  FilterFn,
} from "@tanstack/react-table";
import { formatDistanceToNowStrict } from "date-fns";
import {
  FileText,
  Link,
  ArrowUpDown,
  PlusCircle,
  MinusCircle,
  CircleDot,
  CircleSlash,
  GitCompare,
} from "lucide-react";
import { useState } from "react";

import { SourceRead } from "@/client";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  booleanHandler,
  nullableNumberHandler,
  useQueryState,
} from "@/hooks/useQueryState";
import { cn } from "@/lib/utils";

import { mimeTypeLabels } from "../dataproviders/FileType";
import { TableActionsProps } from "./SourceDataTableActions";
import { DataTableToolbar } from "./SourcesDataTableToolbar";
import { SourceViewDialog } from "./SourceViewDialog";
import { DataTablePagination } from "../tables/data-table-pagination";
import { Badge } from "../ui/badge";
import { Checkbox } from "../ui/checkbox";

const multiColumnFilter: FilterFn<SourceWithDiff> = (
  row,
  _, // columnId
  filterValue: string
) => {
  const searchableContent =
    `${row.original.name} ${row.original.resource_id}`.toLowerCase();
  return searchableContent.includes(filterValue.toLowerCase());
};

export type DiffStatus = "added" | "removed" | "unchanged" | "changed";
export interface SourceWithDiff extends SourceRead {
  status?: DiffStatus;
  changes?: {
    from: SourceRead;
  };
}

export const statusOptions = [
  { label: "Added", value: "added" },
  { label: "Removed", value: "removed" },
  { label: "Changed", value: "changed" },
  { label: "Unchanged", value: "unchanged" },
];

// Utility functions
export function formatFileSize(bytes: number): string {
  const kb = bytes / 1024;
  if (kb < 1024) return `${kb.toFixed(1)} KB`;
  const mb = kb / 1024;
  if (mb < 1024) return `${mb.toFixed(1)} MB`;
  const gb = mb / 1024;
  return `${gb.toFixed(1)} GB`;
}

const createColumns = (
  uniqueFileTypes: number
): ColumnDef<SourceWithDiff>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <div className="flex items-center">
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "status",
    header: () => {
      return <GitCompare className="h-4 w-4" />;
    },
    cell: ({ row }) => {
      const status = row.original.status;
      if (!status) return null;

      const icons = {
        added: <PlusCircle className="h-4 w-4" />,
        removed: <MinusCircle className="h-4 w-4" />,
        changed: <CircleDot className="h-4 w-4" />,
        unchanged: <CircleSlash className="h-4 w-4" />,
      };

      return (
        <div
          className={cn(
            "flex items-center justify-center",
            status === "added" && "text-green-600",
            status === "removed" && "text-destructive",
            status === "changed" && "text-yellow-600",
            status === "unchanged" && "text-muted-foreground"
          )}
        >
          {icons[status]}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "file_type",
    header: ({ column }) => {
      const canSort = uniqueFileTypes > 1;
      return canSort ? (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className={"cursor-default"}
        >
          Type
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ) : (
        <p className="text-muted-foreground px-4 py-2">Type</p>
      );
    },
    cell: ({ row }) => {
      const mimeType = row.original.file_type;
      const label = mimeTypeLabels[mimeType];
      return (
        <div className="flex items-center gap-2">
          <FileText className="h-4 w-4 text-muted-foreground" />
          <Badge variant="secondary">{label}</Badge>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const source = row.original;
      const hasNameChanged =
        source.changes && source.changes.from.name !== source.name;

      return (
        <div className="space-y-1 ">
          <div className="flex items-center gap-2">
            <span
              className="font-medium truncate group-hover:whitespace-normal"
              title={source.name}
            >
              {source.name}
            </span>
          </div>
          {hasNameChanged && (
            <div
              // NOTE(memben) hidden group-hover:block to prevent layout shift
              className="pl-6 text-sm text-destructive line-through"
              title={source.changes?.from.name}
            >
              {source.changes?.from.name}
            </div>
          )}
        </div>
      );
    },
    filterFn: multiColumnFilter, // Apply multi-column filter to the name column
  },
  {
    accessorKey: "resource_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Resource URL
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const source = row.original;
      return (
        <div className="flex items-center gap-2 w-full">
          <Link className="h-4 w-4 flex-shrink-0 text-muted-foreground" />
          <a
            href={source.resource_id}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline text-sm truncate group-hover:whitespace-normal"
            title={source.resource_id}
            onClick={(e) => e.stopPropagation()}
          >
            {source.resource_id}
          </a>
        </div>
      );
    },
  },
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Added
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="text-muted-foreground">
        {formatDistanceToNowStrict(row.getValue("created_at"), {
          addSuffix: true,
        })}
      </div>
    ),
  },
  {
    accessorKey: "file_bytesize",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Size
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const source = row.original;
      const hasSizeChanged =
        source.changes &&
        source.changes.from.file_bytesize !== source.file_bytesize;

      return (
        <div className="text-muted-foreground flex items-start gap-2">
          {hasSizeChanged && (
            <span className="text-destructive line-through text-sm">
              {formatFileSize(source.changes?.from.file_bytesize || 0)}
            </span>
          )}
          <span>{formatFileSize(source.file_bytesize)}</span>
        </div>
      );
    },
  },
];

interface DataTableProps {
  data: SourceWithDiff[];
  columnVisibility?: Partial<Record<keyof SourceWithDiff | "select", boolean>>;
  actions?: React.ComponentType<TableActionsProps<SourceWithDiff>>;
  rowClassName?: (row: SourceWithDiff) => string;
}

export function SourcesDataTable({
  data,
  columnVisibility: initialColumnVisibility = {},
  actions: Actions = () => (
    <p className="text-muted-foreground text-sm">No Actions available</p>
  ),
  rowClassName,
}: DataTableProps) {
  const uniqueFileTypes = new Set(data.map((item) => item.file_type)).size;

  const columns = createColumns(uniqueFileTypes);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialColumnVisibility
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedSource, setSelectedSource] = useQueryState<number | null>(
    "selectedSource",
    null,
    nullableNumberHandler
  );
  const [selectedPreviousSource, setSelectedPreviousSource] = useQueryState<
    number | null
  >("selectedPreviousSource", null, nullableNumberHandler);

  const [dialogOpen, setDialogOpen] = useQueryState<boolean>(
    "dialogOpen",
    false,
    booleanHandler
  );

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
  });

  const selectedRows = table
    .getSelectedRowModel()
    .rows.map((row) => row.original);

  return (
    <div className="space-y-4">
      <DataTableToolbar
        table={table}
        showStatus={initialColumnVisibility.status ?? false}
        onSearch={(value) => table.getColumn("name")?.setFilterValue(value)}
      />
      {selectedRows.length > 0 && Actions && (
        <Actions
          selectedRows={selectedRows}
          table={table}
          clearSelection={() => table.toggleAllRowsSelected(false)}
        />
      )}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={cn(
                    "group",
                    "hover:bg-muted/50",
                    "cursor-pointer",
                    rowClassName?.(row.original),
                    row.original.status === "removed" && "opacity-60"
                  )}
                  onClick={() => {
                    setSelectedSource(row.original.id);
                    setSelectedPreviousSource(
                      row.original.changes?.from.id ?? null
                    );
                    setDialogOpen(true);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "max-w-sm p-3",
                        cell.column.id === "select" && "cursor-auto"
                      )}
                      onClick={(e) => {
                        if (cell.column.id === "select") {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
      <SourceViewDialog
        sourceId={selectedSource}
        previousSourceId={selectedPreviousSource}
        open={dialogOpen}
        onOpenChange={setDialogOpen}
      />
    </div>
  );
}
