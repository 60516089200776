import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { ConversationReadFull } from "@/client/types.gen";

import { Message } from "./Message";

export function Conversation({
  conversation,
  renderDetails = true,
}: {
  conversation: ConversationReadFull;
  renderDetails?: boolean;
}) {
  const { frame_session, messages } = conversation;
  const [searchParams] = useSearchParams();
  const highlightMessageId = searchParams.get("message_id");
  const messageRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const fullName = frame_session?.user
    ? `${frame_session.user.first_name || ""} ${frame_session.user.last_name || ""}`.trim() ||
      "Anonymous User"
    : "Anonymous User";

  useEffect(() => {
    if (highlightMessageId && messageRefs.current[highlightMessageId]) {
      messageRefs.current[highlightMessageId]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [highlightMessageId]);

  return (
    <div className="flex flex-col gap-1">
      {messages.map((message, index) => {
        const prevMessage = index > 0 ? messages[index - 1] : null;
        const showHeader = !prevMessage || prevMessage.role !== message.role;

        return (
          <Message
            key={message.id}
            message={message}
            conversation={conversation}
            showHeader={showHeader}
            renderDetails={renderDetails}
            user_name={fullName}
            isHighlighted={highlightMessageId === message.id}
            messageRef={(el) => {
              messageRefs.current[message.id] = el;
            }}
          />
        );
      })}
    </div>
  );
}

export default Conversation;
