import { Skeleton } from "../ui/skeleton";

export default function TopicsClusterSkeleton() {
  return (
    <div className="h-full w-full space-y-4 p-6">
      <Skeleton className="h-[60vh] w-full rounded-xl" />
      <div className="flex justify-center">
        <Skeleton className="h-4 w-48" />
      </div>
    </div>
  );
}
