import { ArrowUpRight, Check, Copy } from "lucide-react";
import { useState } from "react";
import { Button, ButtonProps } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import {
  getProjectOptions,
  listFramesOptions,
} from "@/client/@tanstack/react-query.gen";

// Utility function to generate preview URL
export function getPreviewUrl(frameId: string, url: string) {
  return `https://preview.botbrains-cdn.com/?frameId=${frameId}&target=${url}`;
}

// Hook to handle copy functionality
function useCopyToClipboard() {
  const [copyStatus, setCopyStatus] = useState<"idle" | "copied" | "failed">(
    "idle"
  );

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus("copied");
      setTimeout(() => setCopyStatus("idle"), 2000);
    } catch (err) {
      setCopyStatus("failed");
      setTimeout(() => setCopyStatus("idle"), 2000);
    }
  };

  return { copyStatus, copy };
}

// Hook to fetch project URL
function useProjectUrl(projectId: number) {
  const { data: project, isLoading } = useQuery({
    ...getProjectOptions({
      path: {
        project_id: projectId,
      },
    }),
  });

  return { url: project?.website_url || "", isLoading };
}

// Hook to fetch frame ID
function useFirstFrameId(projectId: number) {
  const { data: frames, isLoading } = useQuery({
    ...listFramesOptions({
      path: {
        project_id: projectId,
      },
    }),
  });

  return { frameId: frames?.data?.[0]?.id || "", isLoading };
}

// Base Preview URL Button component
interface PreviewUrlButtonProps extends Omit<ButtonProps, "onClick"> {
  frameId: string;
  projectId: number;
}

export function PreviewUrlLink({
  frameId,
  projectId,
  children,
  className = "text-gray-600 hover:text-gray-700",
  ...buttonProps
}: PreviewUrlButtonProps & { children: React.ReactNode }) {
  const { url, isLoading: projectLoading } = useProjectUrl(projectId);
  const previewUrl = getPreviewUrl(frameId, url);

  if (projectLoading) return null;

  return (
    <a href={previewUrl} target="_blank" rel="noopener noreferrer">
      <Button
        className={className}
        variant="ghost"
        type="button"
        {...buttonProps}
      >
        <ArrowUpRight className="mr-1" />
        {children}
      </Button>
    </a>
  );
}

export default function PreviewUrlButton({
  frameId,
  projectId,
  className = "text-gray-600 hover:text-gray-700",
  ...buttonProps
}: PreviewUrlButtonProps) {
  const { url, isLoading: projectLoading } = useProjectUrl(projectId);
  const { copyStatus, copy } = useCopyToClipboard();
  const previewUrl = getPreviewUrl(frameId, url);

  if (projectLoading) return null;

  return (
    <Button
      onClick={() => copy(previewUrl)}
      className={className}
      type="button"
      {...buttonProps}
    >
      {copyStatus === "copied" ? (
        <Check className="mr-2" />
      ) : (
        <Copy className="mr-2" />
      )}
      Preview URL
    </Button>
  );
}

// Project-specific Preview URL Button that auto-resolves frameId
interface ProjectPreviewUrlButtonProps extends Omit<ButtonProps, "onClick"> {
  projectId: number;
}

export function ProjectPreviewUrlButton({
  projectId,
  ...buttonProps
}: ProjectPreviewUrlButtonProps) {
  const { frameId, isLoading: frameLoading } = useFirstFrameId(projectId);

  if (frameLoading) return null;

  return (
    <PreviewUrlButton
      frameId={frameId}
      projectId={projectId}
      {...buttonProps}
    />
  );
}

// Hook to get preview URL (keeping for backward compatibility if needed)
export function usePreviewUrl({ projectId }: { projectId: number }): string {
  const { url, isLoading: projectLoading } = useProjectUrl(projectId);
  const { frameId, isLoading: frameLoading } = useFirstFrameId(projectId);

  if (projectLoading || frameLoading) return "";
  return getPreviewUrl(frameId, url);
}
