import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import { RootState } from "../store";
import {
  Conversation,
  TestCaseRun,
  TestSuite,
  TestSuiteRun,
  Testcase,
  Check,
} from "../structs";

interface EvaluationsState {
  // project_id: status mapping
  testSuitesStatus: Record<number, "idle" | "loading" | "succeeded" | "failed">;
  testSuites: TestSuite[];
  testSuitesError: Record<number, string | null>;

  // testsuiteId: status mapping
  testCasesStatus: Record<string, "idle" | "loading" | "succeeded" | "failed">;
  testCases: Testcase[];
  testCasesError: Record<string, string | null>;

  // testsuiterunId: status mapping
  testSuiteRunsStatus: Record<
    string,
    "idle" | "loading" | "succeeded" | "failed"
  >;
  testSuiteRunsError: Record<string, string | null>;
  testSuiteRuns: TestSuiteRun[];

  // NOTE(memben): there also exists a mapping from testCase to testCaseRun not represented here
  // testsuiterunId: status mapping
  testCaseRunsStatus: Record<
    string,
    "idle" | "loading" | "succeeded" | "failed"
  >;
  testCaseRunsError: Record<string, string | null>;
  testCaseRuns: TestCaseRun[];
}

const initialState: EvaluationsState = {
  testSuitesStatus: {},
  testSuitesError: {},
  testSuites: [],

  testCasesStatus: {},
  testCasesError: {},
  testCases: [],

  testCaseRunsStatus: {},
  testCaseRunsError: {},
  testCaseRuns: [],

  testSuiteRunsStatus: {},
  testSuiteRunsError: {},
  testSuiteRuns: [],
};

export interface FetchTestSuites {
  project_id: number;
}

export interface FetchTestSuite {
  project_id: number;
  testSuiteId: string;
}

export interface FetchTestCase {
  project_id: number;
  testCaseId: string;
}

export interface FetchTestSuiteRuns {
  project_id: number;
}

export interface FetchTestSuiteRun {
  project_id: number;
  testsuite_run_id: string;
}

export interface CreateTestSuite {
  project_id: number;
  name: string;
  description: string;
}

export interface UpdateTestSuite extends CreateTestSuite {
  testsuite_id: string;
}

export interface CreateTestSuiteRun {
  project_id: number;
  testsuite_id: string;
}

export interface UpdateTestCase {
  project_id: number;
  testcase_id: string;
  name: string;
  description: string;
  input: Conversation;
  check: Check;
}

export interface CreateTestCase {
  testsuite_id: string;
  project_id: number;
  name: string;
  description: string;
  input: Conversation;
  check: Check;
}

export interface DeleteTestSuite {
  project_id: number;
  testsuite_id: string;
}
export interface DeleteTestCase {
  project_id: number;
  testcase_id: string;
}

export interface DeleteTestSuiteRun {
  project_id: number;
  testsuite_run_id: string;
}

const makeApiRequest = async (
  url: string,
  method: string,
  body: any,
  apiToken: string
) => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${apiToken}`,
    },
  };

  if (method !== "GET" && method !== "DELETE") {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  if (
    response.status === 204 ||
    response.headers.get("content-length") === "0"
  ) {
    return null;
  }

  return await response.json();
};

const createEvaluationsApiThunk = ({
  actionName,
  urlPathBuilder,
  conditionCheck,
  method = "GET",
}: {
  actionName: string;
  urlPathBuilder: (arg: any) => string;
  conditionCheck: (arg: any, getState: () => RootState) => boolean;
  method?: "GET" | "POST" | "PUT" | "DELETE";
}) => {
  return createAsyncThunk(
    `evaluations/${actionName}`,
    async (arg: any, { rejectWithValue }) => {
      // TODO(liamvdv): check if we need to use the conditionCheck here. pass getState via { rejectWithValue, getState } prop.
      // if (conditionCheck && !conditionCheck(getState())) {
      //   return false; // Prevent thunk from executing
      // }
      const apiToken = await (window as any).Clerk.session.getToken({
        template: "api_botbrains_io",
      });
      try {
        const url = `${import.meta.env.VITE_API_BASE_URL}${urlPathBuilder(arg)}`;
        return await makeApiRequest(url, method, arg, apiToken);
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    },
    {
      condition: (arg: any, { getState }) =>
        conditionCheck(arg, getState as () => RootState),
    }
  );
};

// Fetching test suites for a specific project
export const fetchTestSuites = createEvaluationsApiThunk({
  actionName: "fetchTestSuites",
  urlPathBuilder: ({ project_id }: FetchTestSuites) =>
    `/v1/projects/${project_id}/testsuites`,
  conditionCheck: (arg, getState) => {
    const status = selectTestSuitesStatus(arg)(getState());
    return status === "idle";
  },
});

// Fetching a specific test suite
export const fetchTestSuite = createEvaluationsApiThunk({
  actionName: "fetchTestSuite",
  urlPathBuilder: ({ project_id, testSuiteId }: FetchTestSuite) =>
    `/v1/projects/${project_id}/testsuites/${testSuiteId}`,
  conditionCheck: (arg, getState) => {
    const status = selectTestCasesStatus(arg)(getState());
    return status === "idle";
  },
});

// List test suite runs for a project
export const fetchTestSuiteRuns = createEvaluationsApiThunk({
  actionName: "fetchTestSuiteRuns",
  urlPathBuilder: ({ project_id }: FetchTestSuiteRuns) =>
    `/v1/projects/${project_id}/testsuite-runs`,
  conditionCheck: (arg, getState) => {
    const status = selectTestSuiteRunsStatus(arg)(getState());
    return status === "idle";
  },
});

// Fetching a specific test suite run
export const fetchTestSuiteRun = createEvaluationsApiThunk({
  actionName: "fetchTestSuiteRun",
  urlPathBuilder: ({
    project_id,
    testsuite_run_id: testSuiteRunId,
  }: FetchTestSuiteRun) =>
    `/v1/projects/${project_id}/testsuite-runs/${testSuiteRunId}`,
  conditionCheck: (arg, getState) => {
    const status = selectTestCaseRunsStatus(arg)(getState());
    return status === "idle";
  },
});

export const createTestSuite = createEvaluationsApiThunk({
  actionName: "createTestSuite",
  urlPathBuilder: ({ project_id }: CreateTestSuite) =>
    `/v1/projects/${project_id}/testsuites`,
  conditionCheck: () => true,
  method: "POST",
});

export const createTestSuiteRun = createEvaluationsApiThunk({
  actionName: "createTestSuiteRun",
  urlPathBuilder: ({ project_id }: CreateTestSuiteRun) =>
    `/v1/projects/${project_id}/testsuite-runs`,
  conditionCheck: () => true,
  method: "POST",
});

export const createTestCase = createEvaluationsApiThunk({
  actionName: "createTestCase",
  urlPathBuilder: ({ project_id }: CreateTestCase) =>
    `/v1/projects/${project_id}/testcases`,
  conditionCheck: () => true,
  method: "POST",
});

export const updateTestSuite = createEvaluationsApiThunk({
  actionName: "updateTestSuite",
  urlPathBuilder: ({ project_id, testsuite_id }: UpdateTestSuite) =>
    `/v1/projects/${project_id}/testsuites/${testsuite_id}`,
  conditionCheck: () => true,
  method: "PUT",
});

export const updateTestCase = createEvaluationsApiThunk({
  actionName: "updateTestCase",
  urlPathBuilder: ({ project_id, testcase_id }: UpdateTestCase) =>
    `/v1/projects/${project_id}/testcases/${testcase_id}`,
  conditionCheck: () => true,
  method: "PUT",
});

export const deleteTestSuite = createEvaluationsApiThunk({
  actionName: "deleteTestSuite",
  urlPathBuilder: ({ project_id, testsuite_id }: DeleteTestSuite) =>
    `/v1/projects/${project_id}/testsuites/${testsuite_id}`,
  conditionCheck: () => true,
  method: "DELETE",
});

export const deleteTestCase = createEvaluationsApiThunk({
  actionName: "deleteTestCase",
  urlPathBuilder: ({ project_id, testcase_id }: DeleteTestCase) =>
    `/v1/projects/${project_id}/testcases/${testcase_id}`,
  conditionCheck: () => true,
  method: "DELETE",
});

export const deleteTestSuiteRun = createEvaluationsApiThunk({
  actionName: "deleteTestSuiteRun",
  urlPathBuilder: ({ project_id, testsuite_run_id }: DeleteTestSuiteRun) =>
    `/v1/projects/${project_id}/testsuite-runs/${testsuite_run_id}`,
  conditionCheck: () => true,
  method: "DELETE",
});

export const pollTestSuiteRuns = createEvaluationsApiThunk({
  actionName: "pollTestSuiteRuns",
  urlPathBuilder: ({ project_id }: FetchTestSuiteRuns) =>
    `/v1/projects/${project_id}/testsuite-runs`,
  conditionCheck: () => true,
  method: "GET",
});

export const pollTestSuiteRun = createEvaluationsApiThunk({
  actionName: "pollTestSuiteRun",
  urlPathBuilder: ({ project_id, testsuite_run_id }: FetchTestSuiteRun) =>
    `/v1/projects/${project_id}/testsuite-runs/${testsuite_run_id}`,
  conditionCheck: () => true,
  method: "GET",
});

export const evaluationsSlice = createSlice({
  name: "evaluations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchTestSuites
      .addCase(fetchTestSuites.pending, (state, action) => {
        state.testSuitesStatus[action.meta.arg.project_id] = "loading";
      })
      .addCase(fetchTestSuites.fulfilled, (state, action) => {
        state.testSuitesStatus[action.meta.arg.project_id] = "succeeded";
        state.testSuites = [
          ...state.testSuites.filter(
            (testSuite) =>
              !action.payload.testsuites.some(
                (newTestSuite: TestSuite) => newTestSuite.id === testSuite.id
              )
          ), // Overwrite existing test suites, prevent duplicates e.g. when creating a new test suite without fetching before
          ...action.payload.testsuites,
        ];
      })
      .addCase(fetchTestSuites.rejected, (state, action) => {
        state.testSuitesStatus[action.meta.arg.project_id] = "failed";
        state.testSuitesError[action.meta.arg.project_id] =
          action.payload as string;
      })
      // Handle fetchTestSuite
      .addCase(fetchTestSuite.fulfilled, (state, action) => {
        state.testCasesStatus[action.meta.arg.testSuiteId] = "succeeded";
        state.testCases = [
          ...state.testCases.filter(
            (testCase) =>
              !action.payload.testcases.some(
                (newTestCase: Testcase) => newTestCase.id === testCase.id
              )
          ), // Overwrite existing test cases
          ...action.payload.testcases,
        ];
      })
      .addCase(fetchTestSuite.rejected, (state, action) => {
        state.testCasesStatus[action.meta.arg.testSuiteId] = "failed";
        state.testCasesError[action.meta.arg.testSuiteId] =
          action.payload as string;
      })
      .addCase(fetchTestSuite.pending, (state, action) => {
        state.testCasesStatus[action.meta.arg.testSuiteId] = "loading";
      })
      // Handle fetchTestSuiteRuns
      .addCase(fetchTestSuiteRuns.pending, (state, action) => {
        state.testSuiteRunsStatus[action.meta.arg.project_id] = "loading";
      })
      .addCase(fetchTestSuiteRuns.fulfilled, (state, action) => {
        state.testSuiteRunsStatus[action.meta.arg.project_id] = "succeeded";
        state.testSuiteRuns = [
          ...state.testSuiteRuns.filter(
            (testSuiteRun) =>
              !action.payload.testsuite_runs.some(
                (newTestSuiteRun: TestSuiteRun) =>
                  newTestSuiteRun.id === testSuiteRun.id
              )
          ), // Overwrite existing test suite runs
          ...action.payload.testsuite_runs,
        ];
      })
      .addCase(fetchTestSuiteRuns.rejected, (state, action) => {
        state.testSuiteRunsStatus[action.meta.arg.project_id] = "failed";
        state.testSuiteRunsError[action.meta.arg.project_id] =
          action.payload as string;
      })
      // Handle fetchTestSuiteRun
      .addCase(fetchTestSuiteRun.pending, (state, action) => {
        state.testCaseRunsStatus[action.meta.arg.testSuiteRunId] = "loading";
      })
      .addCase(fetchTestSuiteRun.fulfilled, (state, action) => {
        state.testCaseRunsStatus[action.meta.arg.testSuiteRunId] = "succeeded";
        state.testCaseRuns = [
          ...state.testCaseRuns.filter(
            (testCaseRun) =>
              !action.payload.testcase_runs.some(
                (newTestCaseRun: TestCaseRun) =>
                  newTestCaseRun.id === testCaseRun.id
              )
          ), // Overwrite existing test case runs
          ...action.payload.testcase_runs,
        ];
      })
      .addCase(fetchTestSuiteRun.rejected, (state, action) => {
        state.testCaseRunsStatus[action.meta.arg.testSuiteRunId] = "failed";
        state.testCaseRunsError[action.meta.arg.testSuiteRunId] =
          action.payload as string;
      })
      // Handle createTestSuite
      .addCase(createTestSuite.fulfilled, (state, action) => {
        state.testSuites = [...state.testSuites, action.payload];
      })
      .addCase(createTestSuite.rejected, (_, action) => {
        throw new Error(action.payload as string);
      })
      // Handle updateTestSuite
      .addCase(updateTestSuite.fulfilled, (state, action) => {
        const idx = state.testSuites.findIndex(
          (testSuite) => testSuite.id === action.payload.id
        );
        state.testSuites[idx] = action.payload;
      })
      // Handle createTestCase
      .addCase(createTestCase.fulfilled, (state, action) => {
        state.testCases = [...state.testCases, action.payload];
      })
      .addCase(createTestCase.rejected, (_, action) => {
        throw new Error(action.payload as string);
      })
      // Handle updateTestCase
      .addCase(updateTestCase.fulfilled, (state, action) => {
        const idx = state.testCases.findIndex(
          (testCase) => testCase.id === action.payload.id
        );
        state.testCases[idx] = action.payload;
      })
      // Handle createTestSuiteRun
      .addCase(createTestSuiteRun.fulfilled, (state, action) => {
        state.testSuiteRuns = [...state.testSuiteRuns, action.payload];
      })
      // Handle deleteTestSuite
      .addCase(deleteTestSuite.fulfilled, (state, action) => {
        state.testSuites = state.testSuites.filter(
          (testSuite) => testSuite.id !== action.meta.arg.testsuite_id
        );
      })
      // Handle deleteTestCase
      .addCase(deleteTestCase.fulfilled, (state, action) => {
        state.testCases = state.testCases.filter(
          (testCase) => testCase.id !== action.meta.arg.testcase_id
        );
      })
      // Handle deleteTestSuiteRun
      .addCase(deleteTestSuiteRun.fulfilled, (state, action) => {
        state.testSuiteRuns = state.testSuiteRuns.filter(
          (testSuiteRun) => testSuiteRun.id !== action.meta.arg.testsuite_run_id
        );
      })
      // Handle pollTestSuiteRuns
      .addCase(pollTestSuiteRuns.fulfilled, (state, action) => {
        state.testSuiteRuns = [
          ...state.testSuiteRuns.filter(
            (testSuiteRun) =>
              !action.payload.testsuite_runs.some(
                (newTestSuiteRun: TestSuiteRun) =>
                  newTestSuiteRun.id === testSuiteRun.id
              )
          ), // Overwrite existing test suite runs
          ...action.payload.testsuite_runs,
        ];
      })
      // Handle pollTestSuiteRun
      .addCase(pollTestSuiteRun.fulfilled, (state, action) => {
        state.testCaseRuns = [
          ...state.testCaseRuns.filter(
            (testCaseRun) =>
              !action.payload.testcase_runs.some(
                (newTestCaseRun: TestCaseRun) =>
                  newTestCaseRun.id === testCaseRun.id
              )
          ), // Overwrite existing test case runs
          ...action.payload.testcase_runs,
        ];
      });
  },
});
// Selectors
export const selectTestSuites = ({ project_id }: FetchTestSuites) =>
  createSelector(
    (state: RootState) => state.evaluations.testSuites,
    (testSuites) =>
      testSuites.filter((testSuite) => testSuite.project_id === project_id) ??
      []
  );

export const selectTestSuitesError = ({ project_id }: FetchTestSuites) =>
  createSelector(
    (state: RootState) => state.evaluations.testSuitesError,
    (testSuitesError) => testSuitesError[project_id] ?? null
  );

export const selectTestSuitesStatus = ({ project_id }: FetchTestSuites) =>
  createSelector(
    (state: RootState) => state.evaluations.testSuitesStatus,
    (testSuitesStatus) => testSuitesStatus[project_id] ?? "idle"
  );

export const selectTestSuiteRuns = ({ project_id }: FetchTestSuiteRuns) =>
  createSelector(
    (state: RootState) => state.evaluations.testSuiteRuns,
    (testSuiteRuns) =>
      testSuiteRuns.filter(
        (testSuiteRun) => testSuiteRun.project_id === project_id
      ) ?? []
  );

export const selectTestSuiteRunsError = ({ project_id }: FetchTestSuiteRuns) =>
  createSelector(
    (state: RootState) => state.evaluations.testSuiteRunsError,
    (testSuiteRunsError) => testSuiteRunsError[project_id] ?? null
  );

export const selectTestSuiteRunsStatus = ({ project_id }: FetchTestSuiteRuns) =>
  createSelector(
    (state: RootState) => state.evaluations.testSuiteRunsStatus,
    (testSuiteRunsStatus) => testSuiteRunsStatus[project_id] ?? "idle"
  );

export const selectTestCases = ({ project_id, testSuiteId }: FetchTestSuite) =>
  createSelector(
    (state: RootState) => state.evaluations.testCases,
    (testCases) =>
      testCases.filter(
        (testcase) =>
          testcase.project_id === project_id &&
          testcase.testsuite_id === testSuiteId
      ) ?? []
  );

export const selectTestCasesError = ({ testSuiteId }: FetchTestSuite) =>
  createSelector(
    (state: RootState) => state.evaluations.testCasesError,
    (testCasesError) => testCasesError[testSuiteId] ?? null
  );

export const selectTestCasesStatus = ({ testSuiteId }: FetchTestSuite) =>
  createSelector(
    (state: RootState) => state.evaluations.testCasesStatus,
    (testCasesStatus) => testCasesStatus[testSuiteId] ?? "idle"
  );

export const selectTestCaseRuns = ({
  testsuite_run_id: testSuiteRunId,
}: FetchTestSuiteRun) =>
  createSelector(
    (state: RootState) => state.evaluations.testCaseRuns,
    (testCaseRuns) =>
      testCaseRuns.filter(
        (testcaseRun) => testcaseRun.testsuite_run_id === testSuiteRunId
      ) ?? []
  );

export const selectTestCaseRunsError = ({
  testsuite_run_id: testSuiteRunId,
}: FetchTestSuiteRun) =>
  createSelector(
    (state: RootState) => state.evaluations.testCaseRunsError,
    (testCaseRunsError) => testCaseRunsError[testSuiteRunId] ?? null
  );

export const selectTestCaseRunsStatus = ({
  testsuite_run_id: testSuiteRunId,
}: FetchTestSuiteRun) =>
  createSelector(
    (state: RootState) => state.evaluations.testCaseRunsStatus,
    (testCaseRunsStatus) => testCaseRunsStatus[testSuiteRunId] ?? "idle"
  );
export default evaluationsSlice.reducer;
