import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  Cloud,
  File,
  Loader2,
  UploadCloud,
  X,
  CheckCircle2,
  XCircle,
} from "lucide-react";
import { useState, useRef, useEffect } from "react";

import { DataProviderRead } from "@/client";
import {
  completeDataproviderSnapshotMutation,
  listDataproviderSnapshotsOptions,
  listSourcesOptions,
  snapshotDataproviderMutation,
  uploadSourcesMutation,
} from "@/client/@tanstack/react-query.gen";
import { HTTPValidationError, SourceRead } from "@/client/types.gen";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "sonner";
import { usePollPendingSnapshots } from "@/hooks";
import { cn, getErrorMessages, uuidv4 } from "@/lib/utils";

import { mimeTypeLabels } from "../dataproviders/FileType";
import { AxiosErrorBox } from "../Error";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";

interface UploadedFile {
  id: string;
  file: File;
  resource_id: string;
  status: "pending" | "uploading" | "success" | "error";
  progress: number;
  error?: string;
}

interface FileConflict {
  id: string;
  fileName: string;
  newFile: File;
  existingFile: UploadedFile | SourceRead;
}
interface DuplicateDialogProps {
  open: boolean;
  conflict: FileConflict;
  onKeepBoth: (conflict: FileConflict) => void;
  onOverwrite: (conflict: FileConflict) => void;
  onCancel: (conflict: FileConflict) => void;
}

function DuplicateDialog({
  open,
  conflict,
  onKeepBoth,
  onOverwrite,
  onCancel,
}: DuplicateDialogProps) {
  return (
    <AlertDialog open={open} onOpenChange={() => onCancel(conflict)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Duplicate File</AlertDialogTitle>
          <AlertDialogDescription>
            A file named "{conflict.fileName}" already exists. What would you
            like to do?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex gap-2">
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => onKeepBoth(conflict)}>
            Keep Both
          </AlertDialogAction>
          <AlertDialogAction
            className={buttonVariants({ variant: "destructive" })}
            onClick={() => onOverwrite(conflict)}
          >
            Overwrite
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

interface UploadDialogProps {
  provider: DataProviderRead;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function UploadDialog({
  provider,
  open,
  onOpenChange,
}: UploadDialogProps) {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [conflicts, setConflicts] = useState<FileConflict[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);
  const [error, setError] = useState<AxiosError<HTTPValidationError> | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  // Poll for existing snapshot
  const { snapshots } = usePollPendingSnapshots({
    projectId: provider.project_id,
    dataProviderId: provider.id,
  });
  const activeSnapshot = snapshots?.find((s) => s.status === "PENDING");
  const lastSuccessfulSnapshot = snapshots?.find(
    (s) => s.status === "COMPLETED"
  );

  // Query existing sources if we have an active snapshot
  // NOTE(memben): current sources are either from the active snapshot or the last successful snapshot (as they will be copied)
  const { data } = useQuery({
    ...listSourcesOptions({
      path: {
        project_id: provider.project_id,
      },
      query: {
        data_provider_snapshot_id:
          activeSnapshot?.id || lastSuccessfulSnapshot?.id,
      },
    }),
    enabled: !!activeSnapshot?.id || !!lastSuccessfulSnapshot?.id,
  });

  const existingSources = data?.sources;

  // Auto-close dialog after successful completion
  useEffect(() => {
    if (files.length > 0 && files.every((f) => f.status === "success")) {
      const timer = setTimeout(() => {
        onOpenChange(false);
        setFiles([]);
        setOverallProgress(0);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [files, onOpenChange]);

  // Create a new snapshot
  const createSnapshot = useMutation({
    ...snapshotDataproviderMutation(),
    onSuccess: (data) => {
      void queryClient.invalidateQueries({
        queryKey: listDataproviderSnapshotsOptions({
          path: {
            project_id: provider.project_id,
            data_provider_id: provider.id,
          },
        }).queryKey,
      });
      setError(null);
      void startUpload(data.id);
    },
    onError: (error) => {
      setError(error);
      toast.error("Error", {
        description: getErrorMessages(error)[0],
      });
    },
  });

  // Upload sources
  const uploadSources = useMutation({
    ...uploadSourcesMutation(),
    onSuccess: () => {
      if (activeSnapshot) {
        void queryClient.invalidateQueries({
          queryKey: listSourcesOptions({
            path: {
              project_id: provider.project_id,
            },
            query: {
              data_provider_snapshot_id: activeSnapshot.id,
            },
          }).queryKey,
        });
      }
      setOverallProgress(75);
    },
    onError: (error) => {
      setError(error);
    },
  });

  // Complete snapshot
  const completeSnapshot = useMutation({
    ...completeDataproviderSnapshotMutation(),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: listDataproviderSnapshotsOptions({
          path: {
            project_id: provider.project_id,
            data_provider_id: provider.id,
          },
        }).queryKey,
      });
      setOverallProgress(100);
      toast("Success", {
        description: "Files uploaded successfully",
      });
    },
    onError: (error) => {
      setError(error);
    },
  });

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setError(null);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(Array.from(e.dataTransfer.files));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError(null);
    if (e.target.files && e.target.files[0]) {
      handleFiles(Array.from(e.target.files));
    }
  };

  const handleFiles = (newFiles: File[]) => {
    const existingFileNames = new Set([
      ...files.map((f) => f.file.name),
      ...(existingSources?.map((s) => s.name) || []),
    ]);

    for (const file of newFiles) {
      if (existingFileNames.has(file.name)) {
        // TODO: handle multiple matches
        const existingFile =
          files.find((f) => f.file.name === file.name) ||
          existingSources?.find((s) => s.name === file.name);

        if (!existingFile) {
          throw new Error("Existing file not found");
        }
        setConflicts((prev) => [
          ...(prev || []),
          {
            id: uuidv4(), // local only
            fileName: file.name,
            newFile: file,
            existingFile,
          },
        ]);
      } else {
        addFile(file);
      }
    }
  };

  const handleKeepBoth = (conflict: FileConflict) => {
    addFile(conflict.newFile);
    setConflicts((prev) => prev.filter((c) => c.id !== conflict.id));
  };

  const handleOverwrite = (conflict: FileConflict) => {
    addFile(conflict.newFile, conflict.existingFile.resource_id);
    setConflicts((prev) => prev.filter((c) => c.id !== conflict.id));
  };

  const cancelConflict = (conflict: FileConflict) => {
    setConflicts((prev) => prev.filter((c) => c.id !== conflict.id));
  };

  const addFile = (file: File, resource_id?: string) => {
    const uploadedFile: UploadedFile = {
      id: uuidv4(), // local only
      file: file,
      resource_id: resource_id || uuidv4(),
      status: "pending",
      progress: 0,
    };
    setFiles((prev) => [...prev, uploadedFile]);
  };

  const removeFile = (id: string) => {
    setFiles((prev) => prev.filter((f) => f.id !== id));
  };

  const startUpload = async (snapshotId: string) => {
    setOverallProgress(25);
    setFiles((prev) =>
      prev.map((f) => ({
        ...f,
        status: "uploading" as const,
      }))
    );

    try {
      await uploadSources.mutateAsync({
        path: { project_id: provider.project_id },
        body: {
          data_provider_snapshot_id: snapshotId,
          files: files.map((f) => f.file),
          resource_ids: files.map((f) => f.resource_id),
        },
      });

      setFiles((prev) =>
        prev.map((f) => ({
          ...f,
          status: "success" as const,
          progress: 100,
        }))
      );

      await completeSnapshot.mutateAsync({
        path: {
          project_id: provider.project_id,
          data_provider_id: provider.id,
          data_provider_snapshot_id: snapshotId,
        },
        body: {
          data_provider_id: provider.id,
          id: snapshotId,
          status: "COMPLETED",
        },
      });
    } catch (error) {
      setFiles((prev) =>
        prev.map((f) => ({
          ...f,
          status: "error" as const,
          error: "Upload failed",
        }))
      );
      console.error("Upload failed", error);
    }
  };

  const handleUpload = () => {
    if (files.length === 0) return;
    setError(null);

    if (activeSnapshot) {
      void startUpload(activeSnapshot.id);
    } else {
      createSnapshot.mutate({
        path: {
          project_id: provider.project_id,
          data_provider_id: provider.id,
        },
        body: {
          project_id: provider.project_id,
          data_provider_id: provider.id,
          copy_data: true,
        },
      });
    }
  };

  const isUploading = files.some((f) => f.status === "uploading");
  const isComplete =
    files.every((f) => f.status === "success") && files.length > 0;

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Upload Files</DialogTitle>
          </DialogHeader>

          {error && <AxiosErrorBox title="Upload failed" error={error} />}
          <div
            className={cn(
              "relative mt-4 grid place-items-center rounded-lg border-2 border-dashed p-8 transition-colors",
              dragActive
                ? "border-primary/50 bg-primary/5"
                : "border-muted-foreground/25"
            )}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <input
              ref={inputRef}
              type="file"
              multiple
              className="hidden"
              onChange={handleChange}
              accept={Object.keys(mimeTypeLabels)
                .filter((type) => !type.startsWith("image/")) // NOTE(memben): no reason to allow currently / currenlty broke
                .join(",")}
            />

            <div className="grid place-items-center gap-4 text-muted-foreground">
              <Cloud className="h-10 w-10" />
              <div className="text-center">
                <Button
                  variant="secondary"
                  onClick={() => inputRef.current?.click()}
                  disabled={isUploading}
                >
                  Choose Files
                </Button>
                <p className="mt-2 text-sm">or drag and drop files here</p>
                <p className="mt-2 text-xs text-muted-foreground">
                  Supports standard document formats like PDF, Word and more
                </p>
              </div>
            </div>
          </div>

          <ScrollArea className="mt-4 max-h-[300px]">
            <div className="space-y-2">
              {files.map((file) => (
                <div
                  key={file.id}
                  className="flex items-center justify-between rounded-md border border-border bg-background p-2"
                >
                  <div className="flex items-center gap-2 min-w-0 flex-1">
                    <File className="h-4 w-4 flex-shrink-0 text-muted-foreground" />
                    <span className="text-sm truncate">{file.file.name}</span>
                  </div>
                  <div className="flex flex-shrink-0 items-center gap-2">
                    {file.status === "pending" && (
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-6 w-6 p-0"
                        onClick={() => removeFile(file.id)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    )}
                    {file.status === "uploading" && (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    )}
                    {file.status === "success" && (
                      <CheckCircle2 className="h-4 w-4 text-green-500" />
                    )}
                    {file.status === "error" && (
                      <XCircle className="h-4 w-4 text-destructive" />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {existingSources && existingSources.length > 0 && (
              <div className="border-t border-border pt-2 mt-4">
                <p className="text-xs text-muted-foreground mb-2">
                  Previously uploaded
                </p>
                <div className="space-y-1">
                  {existingSources.map((source) => (
                    <div
                      key={source.id}
                      className="flex items-center justify-between rounded-md bg-gray-50/50 p-2"
                    >
                      <div className="flex items-center gap-2 min-w-0 flex-1">
                        <File className="h-3 w-3 flex-shrink-0 text-muted-foreground" />
                        <span className="text-xs text-muted-foreground truncate">
                          {source.name}
                        </span>
                      </div>
                      <CheckCircle2 className="h-3 w-3 flex-shrink-0 text-green-500/70" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ScrollArea>

          {(isUploading || isComplete) && (
            <div className="mt-4">
              <Progress value={overallProgress} className="h-2" />
              <p className="mt-2 text-sm text-muted-foreground text-center">
                {isComplete
                  ? "Upload complete! This window will close automatically..."
                  : "Uploading files..."}
              </p>
            </div>
          )}

          <div className="mt-4 flex justify-end gap-2">
            <Button
              variant="outline"
              onClick={() => {
                onOpenChange(false);
                setFiles([]);
                setOverallProgress(0);
                setError(null);
              }}
              disabled={isUploading}
            >
              {isComplete ? "Close" : "Cancel"}
            </Button>
            {!isComplete && (
              <Button
                onClick={handleUpload}
                disabled={files.length === 0 || isUploading}
                variant="default"
              >
                {isUploading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Uploading...
                  </>
                ) : (
                  <>
                    <UploadCloud className="mr-2 h-4 w-4" />
                    Upload {files.length}{" "}
                    {files.length === 1 ? "File" : "Files"}
                  </>
                )}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {conflicts.length > 0 && (
        <DuplicateDialog
          open={!!conflicts}
          conflict={conflicts[0]}
          onCancel={cancelConflict}
          onKeepBoth={handleKeepBoth}
          onOverwrite={handleOverwrite}
        />
      )}
    </>
  );
}
