"use client";

import { InfoIcon, LucideIcon, Plus, TextIcon, X } from "lucide-react";
import React from "react";
import {
  Path,
  PathValue,
  FieldValues,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";

interface SettingsFieldProps {
  icon: LucideIcon;
  label: string;
  description: string;
  children: React.ReactNode;
  className?: string;
}

export const FieldContainer = ({
  icon: Icon,
  label,
  description,
  children,
}: SettingsFieldProps) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <Icon className="h-4 w-4 text-primary" />
        <FormLabel className="text-sm font-medium text-gray-700">
          {label}
        </FormLabel>
      </div>
      <FormDescription className="text-sm text-gray-500">
        {description}
      </FormDescription>
      {children}
    </div>
  );
};

interface InputFieldProperties<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  icon: LucideIcon;
  label: string;
  description: string;
  placeholder?: string;
  type?: "text" | "number";
  className?: string;
}

export function InputField<TFieldValues extends FieldValues>({
  form,
  name,
  icon,
  label,
  description,
  placeholder,
  type = "text",
}: InputFieldProperties<TFieldValues>) {
  const inputOptions = {
    ...(type === "number" ? { valueAsNumber: true } : {}),
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FieldContainer icon={icon} label={label} description={description}>
            <FormControl>
              <Input
                type={type}
                placeholder={placeholder}
                {...field}
                {...form.register(name, inputOptions)}
                value={field.value ?? ""}
                className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
              />
            </FormControl>
            <FormMessage className="text-xs text-red-500" />
          </FieldContainer>
        </FormItem>
      )}
    />
  );
}

export interface DescribableFormValues {
  name: string;
  description: string;
}

interface DescribableFieldsProps<TFieldValues extends DescribableFormValues> {
  form: UseFormReturn<TFieldValues>;
}

export function DescribableFields<TFieldValues extends DescribableFormValues>({
  form,
}: DescribableFieldsProps<TFieldValues>) {
  return (
    <div className="space-y-4 rounded-lg bg-gray-50/50 p-4">
      <InputField
        form={form}
        name={"name" as Path<TFieldValues>}
        icon={TextIcon}
        label="Name"
        description="Enter a meaningful name"
        placeholder="Enter a meaningful name"
      />
      <InputField
        form={form}
        name={"description" as Path<TFieldValues>}
        icon={InfoIcon}
        label="Description"
        description="Provide a brief description"
        placeholder="Optionally enter a description"
      />
    </div>
  );
}

type ListFieldPath<T> = Path<T> & string;

interface DynamicListFieldProps<TFormValues extends FieldValues, TFieldValue> {
  name: ListFieldPath<TFormValues>;
  label: string;
  description?: string;
  icon?: React.ReactNode;
  defaultValue: TFieldValue;
  renderField: (field: {
    value: TFieldValue;
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    name: string;
    ref?: React.Ref<any>;
  }) => React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  addButtonLabel?: string;
}

export const DynamicListField = <TFormValues extends FieldValues, TFieldValue>({
  name,
  label,
  description,
  icon,
  defaultValue,
  renderField,
  children,
  className = "space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4",
  addButtonLabel = "Add Item",
}: DynamicListFieldProps<TFormValues, TFieldValue>) => {
  const form = useFormContext<TFormValues>();
  const items = (form.watch(name) as TFieldValue[]) || [];

  const append = () => {
    const newValue = [...items, defaultValue] as PathValue<
      TFormValues,
      typeof name
    >;
    form.setValue(name, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const remove = (index: number) => {
    const newItems = items.filter((_: TFieldValue, i: number) => i !== index);
    const newValue = newItems as PathValue<TFormValues, typeof name>;
    form.setValue(name, newValue, { shouldValidate: true });
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-2">
            {icon}
            <FormLabel className="text-sm font-medium text-gray-700">
              {label}
            </FormLabel>
          </div>
          {description && (
            <FormDescription className="text-sm text-gray-500">
              {description}
            </FormDescription>
          )}
        </div>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={append}
          className="text-sm"
        >
          <Plus className="mr-2 h-4 w-4" />
          {addButtonLabel}
        </Button>
      </div>

      {items.map((_: TFieldValue, index: number) => (
        <div key={`${name}-${index}`} className="flex gap-2">
          <FormField
            control={form.control}
            name={`${name}.${index}` as Path<TFormValues>}
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>{renderField(field)}</FormControl>
                <FormMessage className="text-xs text-red-500" />
              </FormItem>
            )}
          />
          <Button
            type="button"
            variant="ghost"
            size="sm"
            onClick={() => remove(index)}
            className="h-8 w-8 p-0 transition-colors hover:bg-red-50 hover:text-red-500"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      ))}
      {children}
    </div>
  );
};

interface SwitchFieldProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label: string;
  description: string;
  icon: LucideIcon;
  className?: string;
}

export function SwitchField<TFieldValues extends FieldValues>({
  name,
  label,
  description,
  icon: Icon,
  className = "flex flex-row items-center justify-between rounded-lg border border-gray-200 bg-gray-50/50 p-4 shadow-sm",
}: SwitchFieldProps<TFieldValues>) {
  const form = useFormContext<TFieldValues>();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <div className="flex gap-2">
            <Icon className="mt-1 h-4 w-4 text-primary" />
            <div className="space-y-0.5">
              <FormLabel className="text-sm font-medium text-gray-700">
                {label}
              </FormLabel>
              <FormDescription className="text-sm text-gray-500">
                {description}
              </FormDescription>
            </div>
          </div>
          <FormControl>
            <Switch checked={field.value} onCheckedChange={field.onChange} />
          </FormControl>
        </FormItem>
      )}
    />
  );
}
