"use client";

import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { TestCaseRun, ComparatorToName } from "../../structs";
import TestCaseRunView from "./TestCaseRunView";
import Progress from "./TestRunProgress";
import { Sheet, SheetContent } from "../ui/sheet";

// Best TypeScript experience, especially when using `cell.getValue()` later on
// +  columnHelper.accessor('firstName', { //accessorKey
//   +    header: 'First Name',
//   +  }),
//   +  columnHelper.accessor(row => row.lastName, { //accessorFn
//   +    header: () => <span>Last Name</span>,
//   +  }),
const columnHelper = createColumnHelper<TestCaseRun>();

export const columns = [
  columnHelper.accessor((row) => row.status, {
    header: "Status",
    cell: (status) => {
      const row = status.row;
      const run_status = status.getValue();
      const run_passed = row.original.passed;

      if (run_status === "PENDING") {
        return <Progress status="pending" />;
      }
      if (run_status === "COMPLETED" && run_passed) {
        return <Progress status="passed" />;
      }
      if (run_status === "COMPLETED" && run_passed === false) {
        return <Progress status="failed" />;
      }
      // Either ERROR or run_passed is null
      return <Progress status="error" />;
    },
  }),
  columnHelper.accessor((row) => row.output, {
    header: "Output",
    cell: (output) => (
      <p className="line-clamp-4">{output.getValue()?.content}</p>
    ),
  }),
  columnHelper.accessor((row) => row.check, {
    header: "Comparator",
    cell: (check) => <div>{ComparatorToName[check.getValue().comparator]}</div>,
  }),
];

export function TestCaseRunTable({
  testCaseRuns,
  pageSize = 20,
}: {
  testCaseRuns: TestCaseRun[];
  pageSize?: number;
}) {
  const [isSlideOverOpen, setIsSlideOverOpen] = React.useState(false);
  const [selectedTestCaseRun, setSelectedTestCaseRun] =
    React.useState<TestCaseRun | null>(null);

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data: testCaseRuns,
    columns: columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize,
      },
    },
  });

  return (
    <div className="mt-2 w-full">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => {
                    const testCaseRunId = row.original.id;
                    const testCaseRun =
                      testCaseRuns.find((t) => t.id === testCaseRunId) ?? null;
                    setSelectedTestCaseRun(testCaseRun);
                    setIsSlideOverOpen(true);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No Test Suite Runs.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
      {selectedTestCaseRun && (
        <Sheet
          open={isSlideOverOpen}
          onOpenChange={() => setIsSlideOverOpen(false)}
        >
          <SheetContent className="max-h-screen overflow-y-auto">
            <TestCaseRunView testCaseRun={selectedTestCaseRun} />
          </SheetContent>
        </Sheet>
      )}
    </div>
  );
}
