import {
  ArrowUpRight,
  ChevronLeft,
  ChevronRight,
  Flag,
  MessageCircle,
} from "lucide-react";
import { Link, useParams } from "react-router-dom";

import {
  useConversation,
  useCurrentProjectBreadcrumbs,
  useTopicColor,
} from "../../hooks";
import { AxiosErrorBox } from "../Error";
import Spinner from "../Loading";
import FrameSessionCard from "./FrameSessionCard";
import Conversation from "./Conversation";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import { Topic } from "@/client";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { Button } from "../ui/button";
import { useQuery } from "@tanstack/react-query";
import { listConversationsOptions } from "@/client/@tanstack/react-query.gen";

const formatConversationDate = (date: Date) => {
  return new Intl.DateTimeFormat("default", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

const TopicTag = ({ topic }: { topic: Topic }) => {
  const colorSet = useTopicColor(topic.id);

  return (
    <TooltipProvider>
      <Tooltip delayDuration={50}>
        <TooltipTrigger>
          <div
            className="inline-flex items-center gap-1.5 rounded-full px-2.5 py-1 text-sm transition-colors hover:opacity-90"
            style={{
              backgroundColor: colorSet.ultraLight,
              color: colorSet.ultraDark,
              border: `1px solid ${colorSet.light}`,
            }}
          >
            <span
              className="inline-block h-2 w-2 rounded-full"
              style={{ backgroundColor: colorSet.main }}
            />
            <span className="font-medium">{topic.title}</span>
          </div>
        </TooltipTrigger>
        {topic.description && (
          <TooltipContent>
            <p className="max-w-xs text-sm">{topic.description}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

function NextPrevConversations({
  projectId,
  conversationId,
}: {
  projectId: number;
  conversationId: string;
}) {
  const query = useQuery({
    ...listConversationsOptions({
      path: {
        project_id: projectId,
      },
      query: {
        limit: 3,
        offset_conversation_id: conversationId,
        offset: -1,
        include_hidden: false,
      },
    }),
  });
  const arr = query.data?.data || [];
  console.log(arr, conversationId);
  const pos = arr.findIndex((c) => c.id === conversationId);
  const prev = pos + 1 < arr.length ? arr[pos + 1] : null;
  const next = pos - 1 >= 0 ? arr[pos - 1] : null;

  return (
    <div className="flex flex-row gap-2">
      <Button variant={"outline"} disabled={prev == undefined} size={"icon"}>
        <Link to={`/${projectId}/conversations/${prev?.id}`}>
          <ChevronLeft />
        </Link>
      </Button>

      <Button variant={"outline"} disabled={next == undefined} size={"icon"}>
        <Link to={`/${projectId}/conversations/${next?.id}`}>
          <ChevronRight />
        </Link>
      </Button>
    </div>
  );
}

export default function ConversationPage() {
  const { projectId, conversationId } = useParams();
  const {
    data: conversation,
    status,
    error,
  } = useConversation({
    projectId: Number(projectId),
    conversationId: conversationId as string,
  });

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: `/${projectId}/conversations`, label: "Conversations" },
    { label: conversation?.messages[0].text ?? "Conversation" },
  ];

  if (status === "pending") return <Spinner />;
  if (status === "error") return <AxiosErrorBox error={error} />;

  const topics = conversation.snapshots.flatMap((snapshot) => snapshot.topics);

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <header className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <PageHeader
          title="Conversation"
          subtitle={
            conversation.messages.length +
            " messages exchanged, started at " +
            formatConversationDate(conversation.created_at)
          }
        >
          <div className="flex gap-2">
            {topics.map((topic) => (
              <TopicTag key={topic.id} topic={topic} />
            ))}
          </div>
        </PageHeader>
        <div className="flex flex-wrap gap-4 items-center">
          {conversation.frame_session && (
            <Button
              variant="default"
              onClick={() =>
                window.open(
                  `${import.meta.env.VITE_CHAT_URL}/iframe/editor?frameId=${conversation.frame_session!.frame_id}`,
                  "_blank"
                )
              }
            >
              Chat Now
              <ArrowUpRight className="h-4 w-4" />
            </Button>
          )}
          <NextPrevConversations
            projectId={Number(projectId)}
            conversationId={conversationId!}
          />
        </div>
      </header>

      <div className="w-full mt-6">
        <div className="flex flex-col lg:flex-row gap-6">
          <main className="flex-grow">
            <Conversation conversation={conversation} />
          </main>
          {conversation.frame_session && (
            // NOTE(memben): 28px to align with Conversation component
            <div className="w-full lg:w-80 flex-shrink-0 mt-[28px]">
              <div className="lg:sticky lg:top-6 space-y-2">
                <FrameSessionCard frameSession={conversation.frame_session} />
                <Button
                  onClick={() => window.$crisp.push(["do", "chat:open"])}
                  variant={"outline"}
                  className="w-full"
                >
                  Give Feedback
                  <MessageCircle className="h-4 w-4" />
                </Button>
                <Button
                  onClick={() => window.$crisp.push(["do", "chat:open"])}
                  variant={"destructive"}
                  className=" w-full"
                >
                  Report Spam
                  <Flag className="h-4 w-4" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
