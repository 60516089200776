// taken from https://botpress.com/blog/list-of-languages-supported-by-chatgpt

import countryCodeToFlagEmoji from "country-code-to-flag-emoji";

// added localization for US, Canada, Swiss
const IETF_BCP_47_CODES = [
  "ar-AE", // Arabic, United Arab Emirates
  "ar-SA", // Arabic, Saudi Arabia
  "ar-EG", // Arabic, Egypt
  "az-AZ", // Azerbaijani, Azerbaija
  "be-BY", // Belarusian, Belarus
  "bg-BG", // Bulgarian, Bulgaria
  "bn-BD", // Bengali, Bangladesh
  "bs-BA", // Bosnian, Bosnia and Herzegovina
  "ca-ES", // Catalan, Spain
  "cmn-CN", // Mandarin, China (spoken variant)
  "cs-CZ", // Czech, Czech Republic
  "cy-GB", // Welsh, Wales
  "da-DK", // Danish, Denmark
  "de-AT", // German (Austria)
  "de-CH", // German (Switzerland)
  "de-DE", // German, Germany
  "doi-IN", // Dogri, India
  "el-GR", // Greek, Greece
  "en-AU", // English, Australia
  "en-CA", // English (Canada)
  "en-GB", // English, United Kingdom
  "en-US", // English (United States)
  "et-EE", // Estonian, Estonia
  "es-ES", // Spanish, Spaines
  "fi-FI", // Finnish, Finland
  "fr-CA", // French (Canada)
  "fr-CH", // French (Switzerland)
  "fr-FR", // French, France
  "ga-IE", // Irish, Ireland
  "gl-ES", // Galician, Spain
  "gu-IN", // Gujarati, India
  "hi-IN", // Hindi, India
  "hr-HR", // Croatian, Croatia
  "hu-HU", // Hungarian, Hungary
  "hy-AM", // Armenian, Armenia
  "id-ID", // Indonesian, Indonesia
  "it-CH", // Italian (Switzerland)
  "it-IT", // Italian, Italy
  "ja-JP", // Japanese, Japan
  "jv-ID", // Javanese, Indonesia
  "ka-GE", // Georgian, Georgia
  "kk-KZ", // Kazakh, Kazakhstan
  "kn-IN", // Kannada, India
  "kok-IN", // Konkani, India
  "ko-KR", // Korean, South Korea
  "ky-KG", // Kyrgyz, Kyrgyzstan
  "lt-LT", // Lithuanian, Lithuania
  "lv-LV", // Latvian, Latvia
  "mai-IN", // Maithili, India
  "mk-MK", // Macedonian, North Macedonia
  "ml-IN", // Malayalam, India
  "mn-MN", // Mongolian, Mongolia
  "mt-MT", // Maltese, Malta
  "ne-NP", // Nepali, Nepal
  "nl-NL", // Dutch, Netherlands
  "no-NO", // Norwegian, Norway
  "or-IN", // Oriya, India
  "pa-IN", // Punjabi, India
  "pl-PL", // Polish, Poland
  "ps-AF", // Pashto, Afghanistan
  "pt-BR", // Brazilian Portuguese, Brazil
  "pt-PT", // Portuguese, Portugal
  "ro-MD", // Moldovan (Romanian), Moldova
  "ro-RO", // Romanian, Romania
  "ru-RU", // Russian, Russia
  "sa-IN", // Sanskrit, India
  "sd-PK", // Sindhi, Pakistan
  "si-LK", // Sinhala, Sri Lanka
  "sk-SK", // Slovak, Slovakia
  "sl-SI", // Slovene (Slovenian), Slovenia
  "sq-AL", // Albanian, Albania
  "sr-ME", // Montenegrin, Montenegro
  "sr-RS", // Serbian, Serbia
  "ta-IN", // Tamil, India
  "te-IN", // Telugu, India
  "tr-TR", // Turkish, Turkey
  "uk-UA", // Ukrainian, Ukraine
  "ur-PK", // Urdu, Pakistan
  "uz-UZ", // Uzbek, Uzbekistan
  "vi-VN", // Vietnamese, Vietnam
  "yue-CN", // Cantonese (Yue), China
  "zh-CN", // Chinese (Mandarin), China
];

export interface LanguageInfo {
  code: string;
  emoji: string;
  shortLocalizedName: string;
  localizedName: string;
  nativeName: string;
}

const getLanguageNames = (locale: string): LanguageInfo => {
  const [language] = locale.split("-");

  const nativeName =
    new Intl.DisplayNames([locale], { type: "language" }).of(language) ||
    language;

  const englishFallbackName = new Intl.DisplayNames(["en"], {
    type: "language",
  }).of(language);

  const shortLocalizedName =
    new Intl.DisplayNames([navigator.language], {
      type: "language",
    }).of(language) ||
    englishFallbackName ||
    nativeName;

  const localizedName =
    new Intl.DisplayNames([navigator.language], {
      type: "language",
    }).of(locale) ||
    englishFallbackName ||
    nativeName;

  const emoji = countryCodeToFlagEmoji(locale);

  return {
    emoji,
    code: locale,
    shortLocalizedName: shortLocalizedName,
    localizedName: localizedName,
    nativeName,
  };
};

export function localizedLocaleName(locale: string) {
  const pref = navigator.languages[0];
  const formatter = new Intl.DisplayNames([pref], { type: "language" });
  return formatter.of(locale) || locale;
}

export const IETF_BCP_47: LanguageInfo[] =
  IETF_BCP_47_CODES.map(getLanguageNames);

const DEFAULT_COUNTRIES: { [key: string]: string } = {
  ar: "SA", // Arabic -> Saudi Arabia
  de: "DE", // German -> Germany
  en: "US", // English -> United States
  es: "ES", // Spanish -> Spain
  fr: "FR", // French -> France
  it: "IT", // Italian -> Italy
  pt: "PT", // Portuguese -> Portugal
  zh: "CN", // Chinese -> China
};

export function findClosestLanguageMatch(
  langCode: string
): LanguageInfo | null {
  // Normalize the input
  const normalizedCode = langCode.toLowerCase().trim();

  // If it's "und" (undetermined)
  if (normalizedCode === "und") {
    return {
      code: "und",
      emoji: "",
      shortLocalizedName: "Undetermined",
      localizedName: "Undetermined",
      nativeName: "Undetermined",
    };
  }

  // If the exact code exists, return it
  const exactMatch = IETF_BCP_47.find(
    (lang) => lang.code.toLowerCase() === normalizedCode
  );
  if (exactMatch) return exactMatch;

  // If it's a language-country code format (e.g., "en-US")
  if (normalizedCode.includes("-")) {
    const [language] = normalizedCode.split("-");
    // Find any match with the same language code
    const languageMatch = IETF_BCP_47.find((lang) =>
      lang.code.toLowerCase().startsWith(language.toLowerCase() + "-")
    );
    if (languageMatch) return languageMatch;
  }

  // If it's just a language code (e.g., "en")
  // Try to find the default country version
  if (DEFAULT_COUNTRIES[normalizedCode]) {
    const defaultMatch = IETF_BCP_47.find(
      (lang) =>
        lang.code.toLowerCase() ===
        `${normalizedCode}-${DEFAULT_COUNTRIES[normalizedCode].toLowerCase()}`
    );
    if (defaultMatch) return defaultMatch;
  }

  // Find any match that starts with this language code
  const fallbackMatch = IETF_BCP_47.find((lang) =>
    lang.code.toLowerCase().startsWith(normalizedCode + "-")
  );
  if (fallbackMatch) return fallbackMatch;

  return null;
}
