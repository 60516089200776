import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Table } from "@tanstack/react-table";
import { AlertCircle, Loader2, Trash2 } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

import {
  completeDataproviderSnapshotMutation,
  deleteSourceMutation,
  listDataproviderSnapshotsOptions,
  listSourcesOptions,
  snapshotDataproviderMutation,
} from "@/client/@tanstack/react-query.gen";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { getErrorMessages } from "@/lib/utils";

import { SourceWithDiff } from "./SourcesDataTable";

export interface TableActionsProps<T> {
  selectedRows: T[];
  table: Table<T>;
  clearSelection: () => void;
}

const TableActions = ({
  selectedRows,
  clearSelection,
}: TableActionsProps<SourceWithDiff>) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const queryClient = useQueryClient();

  const project_id = selectedRows[0].project_id;
  const data_provider_id = selectedRows[0].data_provider_id;

  // Create snapshot mutation
  const createSnapshot = useMutation({
    ...snapshotDataproviderMutation(),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: listDataproviderSnapshotsOptions({
          path: {
            project_id: project_id,
            data_provider_id: data_provider_id,
          },
        }).queryKey,
      });
    },
    onError: (error) => {
      toast.error("Failed to create snapshot", {
        description: getErrorMessages(error),
      });
      setIsDeleting(false);
    },
  });

  // Delete source mutation
  const deleteSource = useMutation({
    ...deleteSourceMutation(),
    onError: (error) => {
      toast.error("Failed to delete source", {
        description: getErrorMessages(error),
      });
    },
  });

  // Complete snapshot mutation
  const completeSnapshot = useMutation({
    ...completeDataproviderSnapshotMutation(),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: listSourcesOptions({
          path: {
            project_id: project_id,
            data_provider_id: data_provider_id,
          },
        }).queryKey,
      });
      toast.success("Sources deleted successfully");
      clearSelection();
      setIsDeleting(false);
      setProgress(0);
    },
    onError: (error) => {
      toast.error("Failed to complete snapshot", {
        description: getErrorMessages(error),
      });
      setIsDeleting(false);
    },
  });

  const handleDelete = async () => {
    setIsDeleting(true);
    setShowConfirmDialog(false);

    try {
      // Create new snapshot
      const snapshotResponse = await createSnapshot.mutateAsync({
        path: {
          project_id: project_id,
          data_provider_id: data_provider_id,
        },
        body: {
          project_id: project_id,
          data_provider_id: data_provider_id,
          copy_data: true,
        },
      });

      // Delete sources one by one
      const totalSources = selectedRows.length;
      for (let i = 0; i < selectedRows.length; i++) {
        await deleteSource.mutateAsync({
          path: {
            project_id: project_id,
            source_id: selectedRows[i].id,
          },
          query: {
            data_provider_snapshot_id: snapshotResponse.id,
          },
        });
        setProgress(((i + 1) / totalSources) * 100);
      }

      // Complete snapshot
      await completeSnapshot.mutateAsync({
        path: {
          project_id: project_id,
          data_provider_id: data_provider_id,
          data_provider_snapshot_id: snapshotResponse.id,
        },
        body: {
          data_provider_id: data_provider_id,
          id: snapshotResponse.id,
          status: "COMPLETED",
        },
      });
    } catch (error) {
      console.error("Failed to delete sources:", error);
      setIsDeleting(false);
      setProgress(0);
    }
  };

  if (selectedRows.length === 0) return null;

  return (
    <>
      <div className="rounded-lg border bg-card p-4 text-card-foreground shadow-sm">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Trash2 className="h-4 w-4 text-red-500" />
              <span className="text-sm font-medium">
                {selectedRows.length}{" "}
                {selectedRows.length === 1 ? "Source" : "Sources"} Selected
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              onClick={() => clearSelection()}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => setShowConfirmDialog(true)}
              disabled={isDeleting}
            >
              Delete Selected
            </Button>
          </div>
        </div>

        {isDeleting && (
          <div className="mt-4 space-y-2">
            <div className="flex justify-between text-sm text-muted-foreground">
              <span>Deleting sources...</span>
              <span>{Math.round(progress)}%</span>
            </div>
            <Progress value={progress} className="h-2" />
          </div>
        )}
      </div>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete {selectedRows.length}{" "}
              {selectedRows.length === 1 ? "source" : "sources"}? This action
              cannot be undone.
            </DialogDescription>
          </DialogHeader>

          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Warning</AlertTitle>
            <AlertDescription>
              Deleting sources will permanently remove them from your knowledge
              base.
            </AlertDescription>
          </Alert>

          <DialogFooter>
            <Button
              variant="ghost"
              onClick={() => setShowConfirmDialog(false)}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => void handleDelete()}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                <>
                  Delete {selectedRows.length}{" "}
                  {selectedRows.length === 1 ? "source" : "sources"}
                </>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableActions;
