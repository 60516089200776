import { zodResolver } from "@hookform/resolvers/zod";
import {
  Ban,
  BrainCircuit,
  Code2,
  Database,
  FileCode2,
  Globe,
  Hash,
  Layers,
  Link,
  Loader2,
  PanelTop,
  Save,
  Shapes,
  TextIcon,
  User,
} from "lucide-react";
import { useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DataProviderSchema } from "@/lib/schemas";

import { DynamicListField, FieldContainer, InputField } from "../SharedForm";
import ScopeSelect from "./ScopeSelect";
import FullScreenDialogContent from "../FullScreenDialogContent";

export type DataProviderValues = z.infer<typeof DataProviderSchema>;

const WebcrawlerForm = () => {
  const form = useFormContext<DataProviderValues>();

  return (
    <div className="space-y-6 rounded-lg border border-gray-200 bg-white/50 p-6">
      {/* Main URL and Scope Section */}
      <div className="grid gap-6 lg:grid-cols-2">
        <div className="space-y-6">
          <DynamicListField<DataProviderValues, string>
            name="definition.urls"
            label="Seed URLs"
            description="URLs to start the crawl with"
            icon={<Link className="h-4 w-4 text-primary" />}
            defaultValue=""
            addButtonLabel="Add Seed URL"
            renderField={(field) => (
              <Input
                {...field}
                placeholder="https://example.com"
                className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
          <ScopeSelect />
        </div>

        <div className="space-y-4 rounded-lg  border border-gray-100 bg-gray-50/50 p-4">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="definition.render_mode"
              render={({ field }) => (
                <FormItem>
                  <FieldContainer
                    icon={FileCode2}
                    label="Render Mode"
                    description="Choose how to render pages for crawling"
                  >
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select provider type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="auto">
                          <div className="flex items-center gap-2">
                            <BrainCircuit className="h-4 w-4" />
                            <span>Automatic</span>
                          </div>
                        </SelectItem>
                        <SelectItem value="js">
                          <div className="flex items-center gap-2">
                            <Code2 className="h-4 w-4" />
                            <span>JavaScript</span>
                          </div>
                        </SelectItem>
                        <SelectItem value="no-js">
                          <div className="flex items-center gap-2">
                            <PanelTop className="h-4 w-4" />
                            <span>No JavaScript</span>
                          </div>
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FieldContainer>
                </FormItem>
              )}
            />

            <div className="grid gap-4 lg:grid-cols-2">
              <InputField
                form={form}
                name="definition.limit"
                label="URL Limit"
                type="number"
                icon={Hash}
                description="Max URLs to crawl (1-20000)"
              />
              <InputField
                form={form}
                name="definition.limit_concurrency"
                label="Concurrency Limit"
                type="number"
                icon={Layers}
                description="Max concurrent crawls (1-50)"
              />
            </div>
          </div>
        </div>
      </div>

      {/* URL Patterns Section */}
      <div className="grid gap-6 lg:grid-cols-2">
        <DynamicListField<DataProviderValues, string>
          name="definition.include_globs"
          label="Include Patterns"
          description="URL patterns to include in the crawl"
          icon={<Globe className="h-4 w-4 text-primary" />}
          defaultValue=""
          addButtonLabel="Add Include Pattern"
          renderField={(field) => (
            <Input
              {...field}
              placeholder="https://example.com/path/*"
              className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
            />
          )}
        />
        <DynamicListField<DataProviderValues, string>
          name="definition.exclude_globs"
          label="Exclude Patterns"
          description="URL patterns to exclude from the crawl"
          icon={<Ban className="h-4 w-4 text-red-500" />}
          defaultValue=""
          addButtonLabel="Add Exclude Pattern"
          renderField={(field) => (
            <Input
              {...field}
              placeholder="https://example.com/private/*"
              className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
            />
          )}
        />
      </div>
    </div>
  );
};

const ProviderTypeSelect = ({ disabled = false }: { disabled?: boolean }) => {
  const form = useFormContext<DataProviderValues>();

  return (
    <FormField
      control={form.control}
      name="data_provider_type"
      render={({ field }) => (
        <FormItem>
          <FieldContainer
            icon={Database}
            label="Provider Type"
            description="Choose the type of data provider for your application"
          >
            <Select
              onValueChange={field.onChange}
              value={field.value}
              disabled={disabled}
            >
              <FormControl>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select provider type" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="webcrawler">
                  <div className="flex items-center gap-2">
                    <Globe className="h-4 w-4" />
                    <span>Web Crawler</span>
                  </div>
                </SelectItem>
                <SelectItem value="human">
                  <div className="flex items-center gap-2">
                    <Shapes className="h-4 w-4" />
                    <span>Collection</span>
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FieldContainer>
        </FormItem>
      )}
    />
  );
};
interface DataProviderDialogProps {
  initialData?: DataProviderValues;
  onSubmit: (data: DataProviderValues) => void;
  isSubmitting?: boolean;
}

export default function DataProviderForm({
  initialData,
  onSubmit,
  isSubmitting = false,
}: DataProviderDialogProps) {
  const { projectId } = useParams();

  const defaults: DataProviderValues = {
    name: "",
    project_id: Number(projectId),
    data_provider_type: "webcrawler",
    schedule: null,
    definition: {
      urls: [],
      scope: "same-domain",
      render_mode: "auto",
      limit: 50,
      limit_concurrency: 10,
      include_globs: [],
      exclude_globs: [],
    },
  };

  const form = useForm<DataProviderValues>({
    resolver: zodResolver(DataProviderSchema),
    defaultValues: initialData || defaults,
    mode: "onChange",
  });

  console.log(form.getValues());
  console.log(form.formState.errors);

  const providerType = form.watch("data_provider_type");

  // NOTE(memben): needed to allow pre-submit and pre-validation logic
  const handlePreSubmit = () => {
    const values = form.getValues();
    if (values.data_provider_type === "human") {
      form.setValue("definition", null);
      form.setValue("schedule", null);
    }
    void form.handleSubmit(onSubmit)();
  };

  return (
    <FullScreenDialogContent
      title="Data Provider Configuration"
      icon={
        providerType === "webcrawler" ? (
          <Globe className="h-5 w-5 text-primary" />
        ) : (
          <User className="h-5 w-5 text-primary" />
        )
      }
    >
      <div className="px-6">
        <Form {...form}>
          <form
            // onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-6"
          >
            <div className="grid gap-6 lg:grid-cols-2">
              <InputField
                form={form}
                name="name"
                icon={TextIcon}
                label="Name"
                description="Enter a meaningful name"
                placeholder="Enter provider name"
              />
              <ProviderTypeSelect disabled={!!initialData} />
            </div>

            {providerType === "webcrawler" && <WebcrawlerForm />}

            <div className="flex justify-end border-t border-gray-100">
              <Button
                type="button"
                size="lg"
                variant={"default"}
                className="min-w-[200px]"
                disabled={isSubmitting}
                onClick={() => handlePreSubmit()}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Saving Changes...
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    Save Configuration
                  </>
                )}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </FullScreenDialogContent>
  );
}
