import { Check, Loader2 } from "lucide-react";

const StatusIndicator = ({
  status,
  isLoading,
}: {
  status: "synced" | "syncing" | "error";
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <>
        <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
        <span className="text-sm text-gray-500">Loading...</span>
      </>
    );
  }

  switch (status) {
    case "syncing":
      return (
        <>
          <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
          <span className="text-sm text-gray-500">Saving...</span>
        </>
      );
    case "synced":
      return (
        <>
          <Check className="h-4 w-4 text-green-500" />
          <span className="text-sm text-green-500">Saved</span>
        </>
      );
    case "error":
      return <span className="text-sm text-red-500">Save failed</span>;
  }
};
export default StatusIndicator;
