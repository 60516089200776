import { AppSidebar } from "@/components/sidebar/AppSidebar";
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import { Outlet } from "react-router-dom";

export function SidebarLayout() {
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset className="w-full overflow-x-auto">
        {/* NOTE(memben): div to allow inner sticky positions to work, outer overflow-x-auto to contain SidebarInset, 1rem for Inset Margin*/}
        <div className="h-[calc(100vh-1rem)] px-4">
          <Outlet />
          <div className="h-4" />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
