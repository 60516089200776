import { zodResolver } from "@hookform/resolvers/zod";
import { PayloadAction, unwrapResult } from "@reduxjs/toolkit";
import { Loader2, Save, X, FileText } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { TestSuiteSchema } from "@/lib/schemas";
import { TestSuite } from "@/structs";

import { DescribableFields } from "../SharedForm";

type TestSuiteFormValues = z.infer<typeof TestSuiteSchema>;

export type TestSuiteSubmit = TestSuiteFormValues & {
  project_id: number;
  testsuite_id?: string;
};

interface TestSuiteFormProps {
  initialValues: TestSuiteFormValues;
  handleSubmitAsync: (values: TestSuiteSubmit) => Promise<PayloadAction<any>>;
}

export default function TestSuiteForm({
  initialValues,
  handleSubmitAsync,
}: TestSuiteFormProps) {
  const { projectId, testSuiteId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!projectId) {
    throw new Error("Logical Error: projectId is required");
  }

  const form = useForm<TestSuiteFormValues>({
    resolver: zodResolver(TestSuiteSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  const onSubmit = async (values: TestSuiteFormValues) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const actionResult = await handleSubmitAsync({
        testsuite_id: testSuiteId,
        ...values,
      });
      const result: TestSuite = unwrapResult(actionResult);
      navigate(`/${projectId}/testsuites/${result.id}`);
    } catch (err: any) {
      console.error("Error submitting test suite:", err);
      setError(err.message || "An unexpected error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Card className="w-full max-w-3xl rounded-xl border border-gray-100 bg-white/80 shadow-none">
        <CardHeader className="border-b border-gray-100 pb-4">
          <div className="flex items-center gap-2">
            <FileText className="h-5 w-5 text-primary" />
            <CardTitle className="text-lg font-semibold text-gray-800">
              {testSuiteId ? "Edit Test Suite" : "Create New Test Suite"}
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="space-y-6">
                <DescribableFields form={form} />
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <div className="flex items-center justify-end gap-3 border-t border-gray-100 pt-6">
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => navigate(-1)}
                  disabled={isSubmitting}
                  className="text-gray-600 hover:text-gray-700"
                >
                  <X className="mr-2 h-4 w-4" />
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant={"default"}
                  disabled={isSubmitting || !form.formState.isValid}
                  className="disabled:cursor-not-allowed disabled:opacity-50"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-2 h-4 w-4" />
                      {testSuiteId ? "Save Changes" : "Create Test Suite"}
                    </>
                  )}
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
