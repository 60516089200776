import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import LinkWithQuery from "./LinkWithQuery";
import {
  fetchProjects,
  selectProjects,
  selectProjectsStatus,
} from "../slices/projects";

import {
  Card,
  CardHeader,
  CardContent,
  CardDescription,
  CardTitle,
} from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { ExternalLink, FolderPlus, AlertCircle } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import NavigationHeader from "./sidebar/NavigationHeader";
import PageHeader from "./PageHeader";

const colors = [
  "#0284c7",
  "#0d9488",
  "#16a34a",
  "#1d4ed8",
  "#3182ce",
  "#7c3aed",
  "#c026d3",
  "#d97706",
  "#dc2626",
  "#dd6b20",
  "#e11d48",
];

const getColorForString = (id: number): string => colors[id % colors.length];

function ProjectsSkeleton() {
  return (
    <div className="grid grid-cols-1 gap-4 @sm:grid-cols-2 @4xl:grid-cols-3 @7xl:grid-cols-4">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
        <Card key={i} className="overflow-hidden">
          <Skeleton className="w-full h-40" />
          <CardHeader className="p-4">
            <Skeleton className="h-6 w-3/4" />
            <Skeleton className="h-4 w-full mt-2" />
          </CardHeader>
        </Card>
      ))}
    </div>
  );
}

function NoProjects() {
  return (
    <Card className="w-full max-w-md mx-auto">
      <CardContent className="pt-6 text-center">
        <FolderPlus className="mx-auto h-12 w-12 text-gray-400" />
        <CardTitle className="mt-4">No projects</CardTitle>
        <CardDescription className="mt-2">
          Make sure you have selected the correct organization.
        </CardDescription>
      </CardContent>
    </Card>
  );
}

function ErrorState() {
  return (
    <Alert variant="destructive" className="max-w-2xl mx-auto">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Error fetching projects</AlertTitle>
      <AlertDescription>
        <div className="space-y-2">
          <p>
            We couldn't fetch your projects. Please check the system status and
            try again later.
          </p>
          <div className="flex gap-4">
            <Button variant="secondary" asChild>
              <Link
                to="https://status.botbrains.io"
                className="inline-flex items-center gap-2"
              >
                System Status <ExternalLink className="h-4 w-4" />
              </Link>
            </Button>
            <Button variant="secondary" asChild>
              <Link
                to="mailto:info@botbrains.io"
                className="inline-flex items-center gap-2"
              >
                Contact Support <ExternalLink className="h-4 w-4" />
              </Link>
            </Button>
          </div>
        </div>
      </AlertDescription>
    </Alert>
  );
}

function Projects() {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const status = useAppSelector(selectProjectsStatus);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProjects());
    }
  }, [status, dispatch]);

  if (status === "loading") return <ProjectsSkeleton />;
  if (status === "failed") return <ErrorState />;
  if (projects.length === 0) return <NoProjects />;

  return (
    <div className="grid grid-cols-1 gap-4 @sm:grid-cols-2 @4xl:grid-cols-3 @7xl:grid-cols-4">
      {[...projects]
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .map((project) => (
          <Card
            key={project.id}
            className="overflow-hidden group hover:shadow-lg transition-all"
          >
            <LinkWithQuery to={`/${project.id}`}>
              <div
                className="w-full h-40 flex items-center justify-center text-white text-lg font-medium group-hover:opacity-90 transition-opacity"
                style={{ background: getColorForString(project.id) }}
              >
                {project.name}
              </div>
              <CardHeader className="p-4">
                <CardTitle className="text-lg">{project.name}</CardTitle>
                <CardDescription className="line-clamp-2">
                  {project.description}
                </CardDescription>
              </CardHeader>
            </LinkWithQuery>
          </Card>
        ))}
    </div>
  );
}

export default function ProjectsPage() {
  return (
    <>
      <NavigationHeader items={[{ label: "Projects" }]} />
      <PageHeader title="Projects" />
      {/* NOTE(memben): container queries seem to be unstable, increased selector to @w-7xl */}
      <main className="w-full mt-4 @container">
        <Projects />
      </main>
    </>
  );
}
