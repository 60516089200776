import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { Check, CheckSquare, Plus, Square, X } from "lucide-react";
import { useState, useCallback, useEffect } from "react";
import { toast } from "sonner";

import { ProjectUpdate } from "@/client";
import {
  updateProjectMutation,
  getProjectOptions,
} from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import StatusIndicator from "../StatusIndicator";

export const DEFAULT_CAMPAIGNS = [
  "Customer Service",
  "Founder",
  "Product",
  "Customer Experience",
  "Marketing",
  "CEO",
  "CTO",
  "CMO",
  "CPO",
  "NA",
  "Skip - Missing Contact",
];

// Helper function to convert array to campaign state
const arrayToCampaignState = (
  campaigns: string[] = []
): Record<string, boolean> => {
  const state: Record<string, boolean> = {};
  // Set all default campaigns to false initially
  DEFAULT_CAMPAIGNS.forEach((campaign) => {
    state[campaign] = false;
  });
  // Set provided campaigns to true
  campaigns.forEach((campaign) => {
    state[campaign] = true;
  });
  return state;
};

// Helper function to convert campaign state back to array
const campaignStateToArray = (state: Record<string, boolean>): string[] => {
  return Object.entries(state)
    .filter(([_, checked]) => checked)
    .map(([campaign]) => campaign);
};

const sortCampaigns = (
  campaigns: Record<string, boolean>
): [string, boolean][] => {
  return Object.entries(campaigns).sort((a, b) => a[0].localeCompare(b[0]));
};

export const CampaignCard = ({ projectId }: { projectId: number }) => {
  const [campaigns, setCampaigns] = useState<Record<string, boolean>>(
    arrayToCampaignState([])
  );
  const [newCampaign, setNewCampaign] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [syncStatus, setSyncStatus] = useState<"synced" | "syncing" | "error">(
    "synced"
  );
  const queryClient = useQueryClient();

  const projectQuery = getProjectOptions({
    path: { project_id: projectId },
    query: { include_internal: true },
  });

  const { data: project, isLoading: isLoadingProject } = useQuery({
    ...projectQuery,
  });

  useEffect(() => {
    if (project?.included_internal_metadata?.campaigns) {
      // Convert array to state object
      setCampaigns(
        arrayToCampaignState(
          project.included_internal_metadata?.campaigns || []
        )
      );
    }
  }, [project]);

  const updateProject = useMutation({
    ...updateProjectMutation({
      query: { merge: true },
    }),
    onSuccess: () => {
      setSyncStatus("synced");
      queryClient.invalidateQueries({ queryKey: projectQuery.queryKey });
    },
    onError: (error) => {
      console.error("Failed to save campaigns:", error);
      setSyncStatus("error");
      toast.error("Failed to save campaigns");
    },
  });

  const debouncedSave = useCallback(
    debounce((updatedCampaigns: Record<string, boolean>) => {
      if (!project) return;

      const updatedProject: ProjectUpdate = {
        ...project,
        internal_metadata: {
          // Convert state object back to array before saving
          campaigns: campaignStateToArray(updatedCampaigns),
        },
      };

      updateProject.mutate({
        path: { project_id: projectId },
        body: updatedProject,
      });
    }, 1000),
    [projectId, project]
  );

  const handleToggle = (campaign: string) => {
    const updatedCampaigns = {
      ...campaigns,
      [campaign]: !campaigns[campaign],
    };
    setCampaigns(updatedCampaigns);
    setSyncStatus("syncing");
    debouncedSave(updatedCampaigns);
  };

  const handleAddCampaign = () => {
    const trimmedCampaign = newCampaign.trim();
    if (!trimmedCampaign) return;

    // Check for duplicates (case-insensitive)
    const campaignExists = Object.keys(campaigns).some(
      (existing) => existing.toLowerCase() === trimmedCampaign.toLowerCase()
    );

    if (campaignExists) {
      toast.error("This campaign already exists");
      return;
    }

    const updatedCampaigns = {
      ...campaigns,
      [trimmedCampaign]: false,
    };
    setCampaigns(updatedCampaigns);
    setSyncStatus("syncing");
    debouncedSave(updatedCampaigns);
    setNewCampaign("");
    setIsAdding(false);
  };

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  // Sort campaigns for display
  const sortedCampaigns = sortCampaigns(campaigns);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Campaigns</CardTitle>
        <div className="flex items-center gap-2">
          <StatusIndicator status={syncStatus} isLoading={isLoadingProject} />
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {sortedCampaigns.map(([campaign, checked]) => (
            <div
              key={campaign}
              className={`flex items-center gap-2 p-2 rounded ${
                isLoadingProject
                  ? "opacity-50"
                  : "cursor-pointer hover:bg-gray-50"
              }`}
              onClick={() => !isLoadingProject && handleToggle(campaign)}
            >
              {checked ? (
                <CheckSquare className="h-5 w-5 text-primary" />
              ) : (
                <Square className="h-5 w-5 text-gray-400" />
              )}
              <span className={checked ? "line-through text-gray-500" : ""}>
                {campaign}
              </span>
            </div>
          ))}
        </div>

        {isAdding ? (
          <div className="mt-4 flex items-center gap-2">
            <Input
              value={newCampaign}
              onChange={(e) => setNewCampaign(e.target.value)}
              placeholder="Enter new campaign name..."
              className="flex-1"
              onKeyDown={(e) => {
                if (e.key === "Enter") handleAddCampaign();
                if (e.key === "Escape") {
                  setIsAdding(false);
                  setNewCampaign("");
                }
              }}
            />
            <Button
              size="icon"
              variant="ghost"
              onClick={handleAddCampaign}
              disabled={!newCampaign.trim()}
            >
              <Check className="h-4 w-4" />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              onClick={() => {
                setIsAdding(false);
                setNewCampaign("");
              }}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        ) : (
          <Button
            variant="outline"
            size="sm"
            className="mt-4"
            onClick={() => setIsAdding(true)}
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Campaign
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
