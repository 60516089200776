import { zodResolver } from "@hookform/resolvers/zod";
import { PayloadAction, unwrapResult } from "@reduxjs/toolkit";
import {
  Loader2,
  Save,
  X,
  Beaker,
  MessageCircle,
  CheckCircle2,
} from "lucide-react";
import { useState } from "react";
import { useForm, useFieldArray, UseFormReturn } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { TestCaseSchema } from "@/lib/schemas";
import { cn } from "@/lib/utils";
import { ComparatorToName } from "@/structs";

import { DescribableFields } from "../SharedForm";

function CheckTypeFields({
  form,
}: {
  form: UseFormReturn<TestCaseFormValues>;
}) {
  const comparator = form.watch("check.comparator");

  const renderCheckFields = () => {
    switch (comparator) {
      case "SEMANTICALLY_EQUIVALENT":
        return (
          <>
            <FormField
              control={form.control}
              name="check.expected"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Expected Value</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter the expected value" {...field} />
                  </FormControl>
                  <FormDescription>
                    Provide the expected value to compare against.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="check.threshold"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Threshold (%)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Enter the threshold"
                      {...field}
                      {...form.register("check.threshold", {
                        valueAsNumber: true,
                      })}
                    />
                  </FormControl>
                  <FormDescription>
                    Provide the threshold value for the comparison in %.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        );
      case "MATCHES":
      case "NOT_MATCHES":
        return (
          <FormField
            control={form.control}
            name="check.pattern"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Pattern</FormLabel>
                <FormControl>
                  <Input placeholder="Enter the regex pattern" {...field} />
                </FormControl>
                <FormDescription>
                  Provide the regex pattern to{" "}
                  {comparator === "MATCHES" ? "match" : "not match"} against.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "CLASSIFY_AS":
        return (
          <>
            <FormField
              control={form.control}
              name="check.options"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Options</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter options, separated by commas"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Provide the options to classify, separated by commas.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="check.expected"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Expected Classifications</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter expected classifications, separated by commas"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Provide the expected classifications, separated by commas.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 border-b border-gray-100 pb-4">
        <CheckCircle2 className="h-5 w-5 text-green-500" />
        <h3 className="font-semibold text-gray-800">Check Configuration</h3>
      </div>

      <FormField
        control={form.control}
        name="check.comparator"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Check Type</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a check type" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {Object.entries(ComparatorToName).map(([key, value]) => (
                  <SelectItem key={key} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormDescription>
              Select the type of check to perform.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      {renderCheckFields()}
    </div>
  );
}

function ConversationInputFields({
  form,
}: {
  form: UseFormReturn<TestCaseFormValues>;
}) {
  const { fields, append, remove } = useFieldArray({
    name: "input.messages",
    control: form.control,
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 border-b border-gray-100 pb-4">
        <MessageCircle className="h-5 w-5 text-purple-500" />
        <h3 className="font-semibold text-gray-800">Conversation History</h3>
      </div>

      <div className="space-y-4">
        {fields.map((field, index) => (
          <FormField
            control={form.control}
            key={field.id}
            name={`input.messages.${index}.content`}
            render={({ field }) => (
              <FormItem>
                <FormDescription
                  className={cn(
                    "text-sm text-gray-500",
                    index !== 0 && "sr-only"
                  )}
                >
                  The conversation history to be used for the test case.
                </FormDescription>
                <div className="flex items-center gap-2">
                  <span className="text-sm font-semibold text-gray-600">
                    {index % 2 === 0 ? "User" : "Assistant"}
                  </span>
                </div>
                <FormControl>
                  <Textarea {...field} className="min-h-[100px] resize-y" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}
      </div>

      <div className="flex flex-row gap-3">
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={() => {
            append({ role: "assistant", content: "" });
            append({ role: "user", content: "" });
          }}
          className="text-gray-600 hover:text-gray-700"
        >
          Add Message Pair
        </Button>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => remove([fields.length - 2, fields.length - 1])}
          disabled={fields.length < 2}
          className="text-gray-600 hover:text-gray-700"
        >
          Remove Message Pair
        </Button>
      </div>
    </div>
  );
}

type TestCaseFormValues = z.infer<typeof TestCaseSchema>;
export type TestCaseSubmit = TestCaseFormValues & {
  project_id: string;
  testcase_id?: string;
};

interface TestCaseFormProps {
  initialValues: TestCaseFormValues;
  handleSubmitAsync: (values: TestCaseSubmit) => Promise<PayloadAction<any>>;
}

export default function TestCaseForm({
  initialValues,
  handleSubmitAsync,
}: TestCaseFormProps) {
  const { projectId, testCaseId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!projectId) {
    throw new Error("Logical Error: projectId is required");
  }

  const form = useForm<TestCaseFormValues>({
    resolver: zodResolver(TestCaseSchema),
    defaultValues: {
      ...initialValues,
      // HACK(memben): As the input works on strings and is dynamic we need to prepare it for the form.
      // They get passed and validated by zod correctly.
      // @ts-ignore
      check: {
        comparator: initialValues.check.comparator,
        expected:
          "expected" in initialValues.check
            ? Array.isArray(initialValues.check.expected)
              ? initialValues.check.expected.join(", ")
              : initialValues.check.expected
            : "",
        threshold:
          "threshold" in initialValues.check
            ? initialValues.check.threshold * 100
            : 0,
        pattern:
          "pattern" in initialValues.check ? initialValues.check.pattern : "",
        options:
          "options" in initialValues.check
            ? initialValues.check.options.join(", ")
            : "",
      },
    },
    mode: "onChange",
  });

  const onSubmit = async (values: TestCaseFormValues) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const actionResult = await handleSubmitAsync({
        project_id: projectId,
        testcase_id: testCaseId,
        ...values,
      });
      unwrapResult(actionResult);
      navigate(`/${projectId}/testsuites/${values.testsuite_id}`);
    } catch (err: any) {
      console.error("Error submitting test case:", err);
      setError(err.message || "An unexpected error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full max-w-3xl rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <Beaker className="h-5 w-5 text-primary" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            {testCaseId ? "Edit Test Case" : "Create New Test Case"}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <DescribableFields form={form} />
            <ConversationInputFields form={form} />
            <CheckTypeFields form={form} />

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <div className="flex items-center justify-end gap-3 border-t border-gray-100 pt-6">
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => navigate(-1)}
                disabled={isSubmitting}
                className="text-gray-600 hover:text-gray-700"
              >
                <X className="mr-2 h-4 w-4" />
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                disabled={isSubmitting || !form.formState.isValid}
                className="bg-primary text-white disabled:cursor-not-allowed disabled:opacity-50"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    {testCaseId ? "Save Changes" : "Create Test Case"}
                  </>
                )}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
