import { PrismLight } from "react-syntax-highlighter";
import markup from "react-syntax-highlighter/dist/esm/languages/prism/markup";
import {
  vscDarkPlus,
  prism,
} from "react-syntax-highlighter/dist/esm/styles/prism";

PrismLight.registerLanguage("markup", markup);

export default function SyntaxHighlighter({
  language,
  code,
  style,
  wrapLines = false,
  showLineNumbers = false,
  wrapLongLines = true,
}: {
  language: string;
  code: string;
  style: "prism" | "vsc-dark-plus";
  wrapLines?: boolean;
  showLineNumbers?: boolean;
  wrapLongLines?: boolean;
}) {
  return (
    <div
      className={
        wrapLongLines ? "w-full bg-slate-50" : "w-full overflow-x-auto"
      }
    >
      <PrismLight
        language={language}
        style={style === "prism" ? prism : vscDarkPlus}
        wrapLines={wrapLines}
        showLineNumbers={showLineNumbers}
        wrapLongLines={wrapLongLines}
        customStyle={{
          background: "transparent",
          padding: "1rem",
          whiteSpace: wrapLongLines ? "pre-wrap" : "pre",
          wordBreak: wrapLongLines ? "break-word" : "normal",
        }}
      >
        {code}
      </PrismLight>
    </div>
  );
}
