import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";

import { useCurrentProjectBreadcrumbs, useTestSuites } from "../../hooks";
import Spinner from "../Loading";
import NavigationHeader from "../sidebar/NavigationHeader";
import { PlusIcon } from "lucide-react";

const TestSuitesPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { testSuites, status, errorMessage } = useTestSuites({
    project_id: Number(projectId),
  });

  useEffect(() => {
    if (testSuites.length > 0) {
      navigate(`/${projectId}/testsuites/${testSuites[0].id}`);
    }
  }, [testSuites]);

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Test Suites" },
  ];

  if (status === "idle") return <Spinner />;
  if (status === "loading") return <Spinner />;
  if (status === "failed") return <div>Error: {errorMessage}</div>;

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="flex h-[calc(100vh-101px)] w-full flex-row items-center justify-center">
        <Button
          onClick={() => navigate(`/${projectId}/testsuites/create`)}
          variant={"default"}
        >
          <PlusIcon />
          Create Your First Test Suite
        </Button>
      </div>
    </>
  );
};

export default TestSuitesPage;
