import { Button } from "@/components/ui/button";
import { useCurrentProjectBreadcrumbs } from "../../hooks";
import {
  ChartAnswerCompleteness,
  ChartBubbleLauncherFunnel,
  ChartConversationLength,
  ChartUserLanguage,
  ChartUserRating,
  ChartUserSentiment,
  MetricTimeInBot,
  ChatbotUrlUsageChart,
} from "./Charts";
import { WeeklyMessagesHeatmap, YearlyMessagesHeatmap } from "./Heatmaps";
import { TripletMetricsCard } from "./MetricCards";
import ComingSoon from "../ComingSoon";
import QueryDateRangePicker from "../QueryDateRangePicker";
import QueryDeploymentAliasPicker from "../QueryDeploymentAliasPicker";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";

export default function ProjectMetrics() {
  const breadcrumbs = [...useCurrentProjectBreadcrumbs(), { label: "Metrics" }];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Metrics" />
      <div className="mb-4">
        <div className="mt-2 flex flex-col lg:flex-row justify-between gap-4">
          <div className="w-full sm:w-[384px]">
            <QueryDeploymentAliasPicker />
          </div>
          <QueryDateRangePicker prefix={"metrics_"} />
        </div>
      </div>

      <main className="pb-24">
        <div className="space-y-6">
          <TripletMetricsCard lastDays={undefined} />
          <YearlyMessagesHeatmap />

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            <ChartAnswerCompleteness />
            <ChartUserSentiment />
            <ChartUserRating />
          </div>

          <WeeklyMessagesHeatmap />

          <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-6">
            <ChartUserLanguage />
            <ChatbotUrlUsageChart />
          </div>
          <ChartConversationLength />
        </div>

        <header>
          <h1 className="mt-10 text-xl font-bold leading-tight tracking-tight text-gray-900">
            Launcher
          </h1>
        </header>

        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-6 mt-6">
          <ChartBubbleLauncherFunnel />
        </div>

        <ComingSoon hideCover={false}>
          <div className="grid grid-cols-2 mt-6">
            <MetricTimeInBot />
          </div>
        </ComingSoon>

        <Button
          className="mt-6 rounded-lg py-2 text-white bg-emerald-600 hover:bg-emerald-700 border-0"
          onClick={() => window.$crisp.push(["do", "chat:open"])}
        >
          Request Metric
        </Button>
      </main>
    </>
  );
}
