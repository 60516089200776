import { useQuery } from "@tanstack/react-query";
import { Layers } from "lucide-react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { listFramesOptions } from "@/client/@tanstack/react-query.gen";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import ErrorBox, { AxiosErrorBox } from "../Error";
import FrameEditorSkeleton from "./FrameEditorSkeleton";
import NavigationHeader from "../sidebar/NavigationHeader";
import { useCurrentProjectBreadcrumbs } from "@/hooks";

const FrameEditorSelector = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const {
    data: frames,
    error,
    isFetching,
  } = useQuery({
    ...listFramesOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });

  // Handle single frame navigation using useEffect
  useEffect(() => {
    const framesList = frames?.data || [];
    if (framesList.length === 1) {
      const frameId = String(framesList[0].id);
      navigate(`/${projectId}/frames/${frameId}`);
    }
  }, [frames, projectId, navigate]);

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Integrations", to: `/${projectId}/frames` },
  ];

  if (isFetching) return <FrameEditorSkeleton />;
  if (error) {
    return (
      <>
        <NavigationHeader items={breadcrumbs} />
        <AxiosErrorBox error={error} />
      </>
    );
  }

  const framesList = frames?.data || [];

  if (framesList.length === 0) {
    return (
      <>
        <NavigationHeader items={breadcrumbs} />
        <ErrorBox
          title="No frames found"
          bullets={[
            "No frames are available in this project. Please contact support.",
          ]}
        />
      </>
    );
  }

  // Don't render select if there's only one frame
  if (framesList.length === 1) {
    return <FrameEditorSkeleton />;
  }

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="min-h-screen space-y-6 p-6">
        <Card className="mx-auto w-full max-w-3xl">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Layers className="h-5 w-5" />
              Select Frame
            </CardTitle>
            <CardDescription>
              Choose which frame you want to configure
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Select
              onValueChange={(frameId) => {
                navigate(`/${projectId}/frames/${frameId}`);
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a frame" />
              </SelectTrigger>
              <SelectContent>
                {framesList.map((frame) => (
                  <SelectItem key={frame.id} value={String(frame.id)}>
                    {frame.name || `Frame ${frame.id}`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default FrameEditorSelector;
