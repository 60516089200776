import { client } from "./client/services.gen";

import "@tanstack/react-query";
import qs from "qs";

export const configureApiClient = async () => {
  client.setConfig({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    // NOTE(liamvdv): Default behaviour of axios is to expand arrays in query params in the
    //                express-form of `key[]=value1&key[]=value2`. This is not compatible with
    //                FastAPI, which uses `key=value1&key=value2`. This setting changes the
    //                behaviour to the latter.
    // NOTE(liamvdv): We also cannot use new URLSearchParams() because it uses the format
    //                `key=value1,value2`.
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });

  // Add interceptor for automatic token handling
  client.instance.interceptors.request.use(async (config) => {
    const platformToken = await (window as any).Clerk.session.getToken({
      template: "api_botbrains_io",
    });
    config.headers.Authorization = `Bearer ${platformToken}`;
    return config;
  });
};
