import { LucideIcon, Plus } from "lucide-react";

import { Button } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/card";

interface ProviderGroupProps {
  title: string;
  icon: LucideIcon;
  count: number;
  primaryAction?: {
    label: string;
    onClick: () => void;
  };
  children: React.ReactNode;
}

export default function ProviderGroup({
  title,
  icon: Icon,
  count,
  primaryAction,
  children,
}: ProviderGroupProps) {
  return (
    <Card className="overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between bg-gray-50 py-4">
        <div className="flex items-center space-x-3">
          <Icon className="h-5 w-5 text-primary" />
          <h2 className="font-medium">{title}</h2>
          <span className="rounded-full bg-gray-200 px-2 py-0.5 text-sm text-gray-600">
            {count}
          </span>
        </div>
        {primaryAction && (
          <Button
            size="sm"
            onClick={primaryAction.onClick}
            className="flex items-center space-x-1"
          >
            <Plus className="h-4 w-4" />
            <span>{primaryAction.label}</span>
          </Button>
        )}
      </CardHeader>
      <CardContent className="p-0">{children}</CardContent>
    </Card>
  );
}
