import { Globe, Info } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { DataProviderValues } from "./DataProviderForm";
import { FieldContainer } from "../SharedForm";

// Types remain the same
type ScopeExample = {
  url: string;
  allowed: boolean;
};

type ScopeDefinition = {
  label: string;
  description: string;
  baseUrl: string;
  examples: ScopeExample[];
};

type ScopeExamples = {
  "same-domain": ScopeDefinition;
  "same-hostname": ScopeDefinition;
  "same-origin": ScopeDefinition;
};

const ScopeExample = ({ url, allowed }: { url: string; allowed: boolean }) => (
  <div className="flex items-center gap-2 py-0.5">
    {allowed ? (
      <span className="text-green-600">✓</span>
    ) : (
      <span className="text-red-500">✗</span>
    )}
    <code className="font-mono text-sm text-gray-600">{url}</code>
  </div>
);

const scopeExamples: ScopeExamples = {
  "same-domain": {
    label: "Same Domain",
    description: "Crawls URLs on the same root domain",
    baseUrl: "https://docs.example.com",
    examples: [
      { url: "https://docs.example.com/api", allowed: true },
      { url: "https://blog.example.com", allowed: true },
      { url: "https://other-site.com", allowed: false },
    ],
  },
  "same-hostname": {
    label: "Same Hostname",
    description: "Only crawls URLs with exactly matching hostname",
    baseUrl: "https://docs.example.com",
    examples: [
      { url: "https://docs.example.com/api", allowed: true },
      { url: "https://docs.example.com:8080/api", allowed: true },
      { url: "https://blog.example.com", allowed: false },
    ],
  },
  "same-origin": {
    label: "Same Origin",
    description: "Strictest: matches hostname and protocol exactly",
    baseUrl: "https://docs.example.com",
    examples: [
      { url: "https://docs.example.com/api", allowed: true },
      { url: "http://docs.example.com/api", allowed: false },
      { url: "https://docs.example.com:8080/api", allowed: false },
    ],
  },
};

const ScopeInfo = () => (
  <Dialog>
    <DialogTrigger asChild>
      <button className="p-2 hover:bg-gray-100 rounded-full">
        <Info className="h-4 w-4 text-gray-500" />
      </button>
    </DialogTrigger>
    <DialogContent className="max-w-xl">
      <div className="grid gap-6">
        {Object.entries(scopeExamples).map(
          ([key, { label, description, baseUrl, examples }]) => (
            <div key={key} className="space-y-2">
              <div className="flex items-center gap-2">
                <Globe className="h-4 w-4 text-gray-500" />
                <h3 className="font-medium">{label}</h3>
              </div>
              <p className="text-sm text-gray-500">{description}</p>
              <div className="bg-gray-50 rounded-md p-2 space-y-1">
                <div className="text-xs text-gray-500">
                  Base URL: <code className="font-mono">{baseUrl}</code>
                </div>
                {examples.map((example, i) => (
                  <ScopeExample key={i} {...example} />
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </DialogContent>
  </Dialog>
);

export const ScopeSelect = () => {
  const form = useFormContext<DataProviderValues>();

  return (
    <FormField
      control={form.control}
      name="definition.scope"
      render={({ field }) => (
        <FormItem>
          <FieldContainer
            icon={Globe}
            label="Crawl Scope"
            description="Define the scope of web crawling for your application"
          >
            <div className="flex items-center gap-2">
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {scopeExamples[field.value as keyof ScopeExamples]
                        ?.label || "Select scope"}
                    </SelectValue>
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.entries(scopeExamples).map(([value, { label }]) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <ScopeInfo />
            </div>
            <FormMessage />
          </FieldContainer>
        </FormItem>
      )}
    />
  );
};

export default ScopeSelect;
