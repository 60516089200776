import {
  Moon,
  Sun,
  Palette,
  PaintBucket,
  Layout,
  Type,
  Plus,
} from "lucide-react";
import { useFormContext } from "react-hook-form";

import { FrameTheme } from "@/client";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormDescription } from "@/components/ui/form";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";

import { FrameSettingsValues } from "./FrameEditorPage";
import { ColorField } from "../ColorField";
import { useFrameEditor } from "./FrameEditorContext";

const ColorSection = ({
  icon: Icon,
  title,
  description,
  children,
}: {
  icon: any;
  title: string;
  description: string;
  children: React.ReactNode;
}) => (
  <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4">
    <div className="flex items-center gap-2">
      <Icon className="h-4 w-4 text-primary" />
      <h3 className="text-sm font-medium text-gray-700">{title}</h3>
    </div>
    <FormDescription className="text-sm text-gray-500">
      {description}
    </FormDescription>
    <div className="space-y-4">{children}</div>
  </div>
);

export const ThemeEditor = ({ type }: { type: "light" | "dark" }) => {
  const { watch, setValue } = useFormContext<FrameSettingsValues>();
  const themes = watch("themes") || [];

  const themeIndex = themes.findIndex(
    (theme: FrameTheme) => theme.name === type
  );

  const handleAddTheme = () => {
    const newTheme = {
      ...themes[0], // assuming 2 themes max, duplicate the counterpart
      name: type,
    };
    setValue("themes", [...themes, newTheme], { shouldDirty: true });
  };

  if (themeIndex === -1) {
    return (
      <div className="flex min-h-[200px] flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-200 bg-gray-50 p-6">
        <div className="mb-4 text-center">
          <PaintBucket className="mx-auto mb-2 h-8 w-8 text-gray-400" />
          <h3 className="mb-1 text-sm font-medium text-gray-900">
            No {type} theme configured
          </h3>
          <p className="text-sm text-gray-500">
            Add a {type} theme to customize colors
          </p>
        </div>
        <Button
          type="button"
          onClick={handleAddTheme}
          variant="default"
          className="inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        >
          <Plus className="h-4 w-4" />
          Add {type} theme
        </Button>
      </div>
    );
  }

  return (
    <Card className="rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardContent className="space-y-6 p-6">
        <ColorSection
          icon={Palette}
          title="Brand Color"
          description="Define your primary brand color."
        >
          <ColorField label="Brand Color" name={`themes.${themeIndex}.brand`} />
        </ColorSection>

        <ColorSection
          icon={Layout}
          title="Interface Colors"
          description="Customize the appearance of main interface elements"
        >
          <ColorField
            label="Header Background"
            name={`themes.${themeIndex}.header_bg`}
          />
          <ColorField
            label="Chat Background"
            name={`themes.${themeIndex}.chat`}
          />
        </ColorSection>

        <ColorSection
          icon={Type}
          title="Typography Colors"
          description="Set the colors for different types of text"
        >
          <ColorField
            label="Chat Text"
            name={`themes.${themeIndex}.chat_text`}
          />
          <ColorField
            label="Secondary Text"
            name={`themes.${themeIndex}.chat_text_light`}
          />
        </ColorSection>
      </CardContent>
    </Card>
  );
};

export const ThemeSettings = () => {
  const { currentTheme, setCurrentTheme } = useFrameEditor();

  const handleThemeChange = (value: string) => {
    setCurrentTheme(value as "light" | "dark");
  };

  return (
    <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <PaintBucket className="h-5 w-5 text-primary" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            Theme Settings
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-6">
        <Tabs value={currentTheme} onValueChange={handleThemeChange}>
          <TabsList className="mb-6 bg-gray-100/50 p-1">
            <TabsTrigger
              value="light"
              className="rounded-md transition-all data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm"
            >
              <div className="flex items-center gap-2">
                <Sun className="h-4 w-4" />
                <span>Light Mode</span>
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="dark"
              className="rounded-md transition-all data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm"
            >
              <div className="flex items-center gap-2">
                <Moon className="h-4 w-4" />
                <span>Dark Mode</span>
              </div>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="light" className="mt-0">
            <ThemeEditor type="light" />
          </TabsContent>

          <TabsContent value="dark" className="mt-0">
            <ThemeEditor type="dark" />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};
