import { X, Maximize2 } from "lucide-react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { localizedLocaleName } from "@/ietf-bcp-47";

const LANGUAGE_CODES = [
  "en",
  "de",
  "ru",
  "fr",
  "it",
  "es",
  "pl",
  "uk",
  "ro",
  "nl",
  "hr",
  "tr",
  "el",
  "hu",
  "sv",
  "cs",
  "pt",
  "ca",
  "sr",
  "sq",
  "da",
  "fi",
  "no",
  "sk",
];

const MemoizedIframe = React.memo(
  ({ frameId, locale }: { frameId: string; locale: string }) => {
    // Using a key that combines frameId and locale ensures unique instances
    const iframeKey = `${frameId}-${locale}`;
    const apiBase = import.meta.env.VITE_API_BASE_URL;

    return (
      <iframe
        key={iframeKey}
        src={`https://chat.botbrains.io/loader?frameId=${frameId}&locale=${locale}&mode=fullscreen&apiBase=${apiBase}`}
        className="rounded pointer-events-none h-full w-full md:h-[704px] md:w-[400px]"
        style={{
          border: "none",
          WebkitUserSelect: "none",
        }}
        allow="fullscreen; clipboard-write; clipboard-read"
        frameBorder="0"
      />
    );
  }
);

MemoizedIframe.displayName = "MemoizedIframe";

const options = LANGUAGE_CODES.map((code) => ({
  code,
  name: localizedLocaleName(code),
}));

const isValidUUID = (uuid: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

function Gallery({
  selectedLanguages,
  frameId,
  toggleLanguage,
}: {
  selectedLanguages: string[];
  frameId: string;
  toggleLanguage: (code: string) => void;
}) {
  const apiBase = import.meta.env.VITE_API_BASE_URL;
  return (
    <div className="overflow-x-scroll flex flex-row gap-2 mx-2 mt-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
      {selectedLanguages.map((locale) => (
        <div className="border rounded-lg" key={`${frameId}-${locale}`}>
          <div className="inline">
            <div className="flex justify-between items-center mb-2 p-4">
              <h2>{localizedLocaleName(locale)}</h2>
              <div className="flex gap-2">
                <a
                  href={`https://chat.botbrains.io/loader?frameId=${frameId}&locale=${locale}&mode=launcher&apiBase=${apiBase}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-gray-700"
                  title="Open in new tab"
                >
                  <Maximize2 size={20} />
                </a>
                <button
                  onClick={() => toggleLanguage(locale)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={20} />
                </button>
              </div>
            </div>

            <MemoizedIframe frameId={frameId} locale={locale} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default function ShowLocalized() {
  const [searchParams, setSearchParams] = useSearchParams();
  const frameId = searchParams.get("frameId") || "";
  const selectedLanguagesParam = searchParams.get("locales") || "";
  const selectedLanguages = selectedLanguagesParam.split(".").filter(Boolean);

  window.$crisp.push(["do", "chat:hide"]);

  const toggleLanguage = (code: string) => {
    const newSelectedLanguages = selectedLanguages.includes(code)
      ? selectedLanguages.filter((lang) => lang !== code)
      : [...selectedLanguages, code];

    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("locales", newSelectedLanguages.join("."));
      return newParams;
    });
  };

  const selectAll = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("locales", LANGUAGE_CODES.join("."));
      return newParams;
    });
  };

  const deselectAll = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("locales", "");
      return newParams;
    });
  };

  const showEditor = searchParams.has("edit");

  if (!showEditor) {
    return (
      <Gallery
        selectedLanguages={selectedLanguages}
        frameId={frameId}
        toggleLanguage={toggleLanguage}
      />
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-6xl">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold mb-4">Language Selection</h1>
          <div className="flex gap-2 mb-4">
            <Button onClick={selectAll} variant="outline" className="w-32">
              Select All
            </Button>
            <Button onClick={deselectAll} variant="outline" className="w-32">
              Deselect All
            </Button>
          </div>
        </div>

        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Frame ID"
            value={frameId}
            onChange={(e) => {
              const value = e.target.value;
              setSearchParams((prev) => {
                const newParams = new URLSearchParams(prev);
                newParams.set("frameId", value);
                return newParams;
              });
            }}
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 ${
              !isValidUUID(frameId) && frameId !== "" ? "border-red-500" : ""
            }`}
          />
        </div>

        {!isValidUUID(frameId) ? (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>Invalid Frame Id</AlertDescription>
          </Alert>
        ) : (
          <>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-4">
              {options.map(({ code, name }) => (
                <button
                  key={code}
                  onClick={() => toggleLanguage(code)}
                  className={`p-2 rounded-lg flex items-center justify-between ${
                    selectedLanguages.includes(code)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                >
                  <span>{name}</span>
                  {selectedLanguages.includes(code) && (
                    <X size={16} className="ml-2" />
                  )}
                </button>
              ))}
            </div>

            <Gallery
              selectedLanguages={selectedLanguages}
              frameId={frameId}
              toggleLanguage={toggleLanguage}
            />
          </>
        )}
      </div>
    </div>
  );
}
