import { useCurrentProjectBreadcrumbs } from "@/hooks";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import TopicsDashboard from "./TopicsDashboard";

export default function TopicsPage() {
  const breadcrumbs = [...useCurrentProjectBreadcrumbs(), { label: "Topics" }];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader
        title="Topics"
        subtitle="TopicAI detects topics every Monday morning, and conversations are assigned daily based on Monday's model. A conversation's topic is final if it started before the last Monday."
      />
      <div className="w-full h-[calc(100vh-150px)]">
        <TopicsDashboard />
      </div>
    </>
  );
}
