import {
  useConversationsMetadata,
  useCurrentProject,
  useCurrentProjectBreadcrumbs,
} from "../../hooks";
import Spinner from "../Loading";

import { formatDistanceToNowStrict } from "date-fns";
import { MessageSquare } from "lucide-react";
import { useParams, useSearchParams } from "react-router-dom";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

import Empty from "../Empty";
import { AxiosErrorBox } from "../Error";
import LinkWithQuery from "../LinkWithQuery";
import QueryDateRangePicker from "../QueryDateRangePicker";
import NavigationHeader from "../sidebar/NavigationHeader";
import { stringArrayHandler, useQueryState } from "@/hooks/useQueryState";
import { MultiSelect } from "../multi-select";
import { useQuery } from "@tanstack/react-query";
import { listTopicsOptions } from "@/client/@tanstack/react-query.gen";
import PageHeader from "../PageHeader";

interface ConversationsMetadataListProps {
  projectId: number;
  since: Date;
  until: Date;
  aliases: string[];
  topics: string[];
}

export function ConversationsMetadataListV2({
  projectId,
  since,
  until,
  aliases,
  topics,
}: ConversationsMetadataListProps) {
  const { data, isPending, error } = useConversationsMetadata({
    projectId,
    since,
    until,
    aliases,
    topics,
  });

  if (isPending) {
    return (
      <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-3">
        {[...Array(12)].map((_, i) => (
          <Skeleton key={i} className="h-40" />
        ))}
      </div>
    );
  }

  if (error) {
    return <AxiosErrorBox error={error} />;
  }

  // sorted desc by api
  const conversations = data.data;

  if (conversations.length === 0) {
    return (
      <div className="mt-12">
        <Empty message="No conversations found." />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3 ">
      {conversations.map((conversation) => (
        <LinkWithQuery
          to={`/${projectId}/conversations/${conversation.id}`}
          key={conversation.id}
          className="no-underline"
        >
          <Card className="h-full transition-all hover:shadow-md">
            <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
              <div className="flex items-center space-x-2 text-sm text-muted-foreground">
                <time>
                  {formatDistanceToNowStrict(
                    new Date(conversation.first_message_time),
                    { addSuffix: true }
                  )}
                </time>
                <span>•</span>
                <div className="flex items-center space-x-1">
                  <MessageSquare className="h-4 w-4" />
                  <span>
                    {
                      conversation.messages.filter((m) => m.role === "user")
                        .length
                    }
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-2">
              <p className="line-clamp-2 text-sm font-medium">
                {conversation.messages[0].text}
              </p>
              <p className="line-clamp-2 text-sm text-muted-foreground">
                {conversation.messages[1].text}
              </p>
            </CardContent>
          </Card>
        </LinkWithQuery>
      ))}
    </div>
  );
}

export default function ConversationsListPage() {
  const { project } = useCurrentProject();
  const [searchParams] = useSearchParams();
  const [aliases] = useQueryState<string[]>("alias", [], stringArrayHandler);
  const since = searchParams.get("since");
  const until = searchParams.get("until");

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Conversations" },
  ];

  const [topics, setTopics] = useQueryState<string[]>(
    "topic",
    [], // empty evalutes to all topics
    stringArrayHandler
  );

  const TopicSelection = () => {
    const { projectId } = useParams();
    const { data, isPending, error } = useQuery({
      ...listTopicsOptions({
        path: {
          project_id: Number(projectId),
        },
      }),
    });

    if (isPending) {
      return <Skeleton />;
    }
    if (error) {
      console.error(error);
      return null;
    }

    return (
      <MultiSelect
        placeholder="All Topics"
        defaultValue={topics}
        onValueChange={setTopics}
        options={data.data.map((topic) => ({
          label: topic.title,
          value: topic.id,
        }))}
        maxCount={1}
        disabled={data.data.length === 0}
      />
    );
  };

  if (project === undefined) return <Spinner />;
  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Conversations" />
      <main>
        <div className="mt-4">
          <div className="flex flex-col justify-between gap-4 sm:flex-row">
            {/* <div className="lg:w-96">
              <QueryDeploymentAliasPicker disabled={isClusterVisualized} />
            </div> */}
            <div className="max-w-xl">
              <TopicSelection />
            </div>
            <QueryDateRangePicker />
          </div>
        </div>

        {since && until && (
          <ConversationsMetadataListV2
            projectId={project.id}
            since={new Date(since)}
            until={new Date(until)}
            aliases={aliases}
            topics={topics}
          />
        )}
      </main>
    </>
  );
}
