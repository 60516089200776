import { useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch, useCurrentProjectBreadcrumbs } from "@/hooks";
import { createTestSuite } from "@/slices/evaluations";

import TestSuiteForm, { TestSuiteSubmit } from "./TestSuiteForm";
import NavigationHeader from "../sidebar/NavigationHeader";

function TestSuiteCreatePage() {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const defaultValues = {
    project_id: Number(projectId),
    name: searchParams.get("name") ?? "",
    description: searchParams.get("description") ?? "",
  };

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: "./..", label: "Test Suites" },
    { label: "Create " },
  ];

  const handleCreateTestSuite = async (values: TestSuiteSubmit) =>
    await dispatch(createTestSuite(values));
  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="w-full flex justify-center">
        <TestSuiteForm
          initialValues={defaultValues}
          handleSubmitAsync={handleCreateTestSuite}
        />
      </div>
    </>
  );
}

export default TestSuiteCreatePage;
