import { formatDistanceToNowStrict } from "date-fns";
import { useState, useEffect } from "react";

const LiveTimeDistance = ({
  date,
  addSuffix = true,
}: {
  date: Date;
  addSuffix?: boolean;
}) => {
  const [formattedDistance, setFormattedDistance] = useState(
    formatDistanceToNowStrict(date, { addSuffix })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setFormattedDistance(formatDistanceToNowStrict(date, { addSuffix }));
    }, 1000);

    return () => clearInterval(timer);
  }, [date, addSuffix]);

  return <>{formattedDistance}</>;
};

export default LiveTimeDistance;
