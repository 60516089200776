import { Loader2, XCircle, AlertTriangle, CheckCircle2 } from "lucide-react";

interface ProgressProps {
  status: "pending" | "failed" | "passed" | "error";
}

export default function Progress({ status }: ProgressProps) {
  const getStatusContent = () => {
    switch (status) {
      case "pending":
        return {
          icon: <Loader2 className="h-5 w-5 text-amber-500 animate-spin" />,
          text: "Running",
          className: "text-amber-700",
        };
      case "failed":
        return {
          icon: <XCircle className="h-5 w-5 text-red-500" />,
          text: "Failed",
          className: "text-red-700",
        };
      case "error":
        return {
          icon: <AlertTriangle className="h-5 w-5 text-red-500" />,
          text: "Error",
          className: "text-red-700",
        };
      case "passed":
        return {
          icon: <CheckCircle2 className="h-5 w-5 text-emerald-500" />,
          text: "Passed",
          className: "text-emerald-700",
        };
      default:
        return null;
    }
  };

  const content = getStatusContent();
  if (!content) return null;

  return (
    <div className="flex items-center gap-2">
      {content.icon}
      <span className={`text-sm font-medium ${content.className}`}>
        {content.text}
      </span>
    </div>
  );
}
