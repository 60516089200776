import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { BarChart } from "../tremor/BarChart";
import { DonutChart } from "../tremor/DonutChart";
import {
  ArrowRight,
  ArrowUpRightIcon,
  TrendingUp,
  TrendingDown,
  SendIcon,
} from "lucide-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { createTopicColorMap } from "@/colors";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import { endOfWeek, format, getWeek, startOfWeek, subWeeks } from "date-fns";
import { UTCDateMini } from "@date-fns/utc";
import { useMetric } from "@/hooks";
import { useQuery } from "@tanstack/react-query";

import { listTopicsOptions } from "@/client/@tanstack/react-query.gen";
import Spinner from "../Loading";

interface MetricRow extends Array<any> {
  0: string; // StartDate
  1: string; // EndDate
  2: string; // Period
  3: string; // TopicId
  4: string; // TopicTitle
  5: number; // ConversationCount
  6: number; // PercentageOfPeriod
  7: number; // RollingAvg
  8: number; // PeriodOverPeriodGrowth
}

type Topic = {
  title: string;
  description: string;
  project_id: number;
  id: string;
  color?: string;
  created_at: Date;
  trend?: {
    type: "new" | "up" | "down" | "inactive" | "stable";
    value?: number;
    avg?: number;
  };
};

function generateTopicTrends(
  topics: Topic[],
  metricData: MetricRow[]
): Topic[] {
  // Get the most recent completed period's data (first group in sorted data)
  const previousWeekMonday = subWeeks(
    startOfWeek(new UTCDateMini(), { weekStartsOn: 1 }),
    1
  );
  const latestPeriodData = metricData.filter(
    (row) => row[0] === format(previousWeekMonday, "yyyy-MM-dd")
  );

  return topics.map((topic) => {
    const topicMetric = latestPeriodData.find((row) => row[3] === topic.id);
    const [, , , , , , , rollAvg, growthPoP] = topicMetric || [
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      0,
      0,
    ];

    const createdAt = new UTCDateMini(topic.created_at);
    const isSignificantGrowth = Math.abs(growthPoP) > 5;
    let trend: Topic["trend"];
    if (previousWeekMonday.getTime() <= createdAt.getTime()) {
      trend = { type: "new" };
    } else if (growthPoP === 0 && rollAvg === 0) {
      trend = { type: "inactive" };
    } else if (!isSignificantGrowth) {
      trend = { type: "stable", avg: rollAvg };
    } else {
      trend = {
        type: growthPoP > 0 ? "up" : "down",
        value: Math.abs(growthPoP),
      };
    }
    return {
      ...topic,
      trend,
    };
  });
}

type ChartDataPoint = {
  date: string;
  [key: string]: string | number;
};

const TrendBadge = ({ topic }: { topic: Topic }) => {
  if (!topic.trend) return null;

  switch (topic.trend.type) {
    case "new":
      return (
        <Badge
          variant="secondary"
          className="w-fit bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 whitespace-nowrap"
        >
          ✨ New
        </Badge>
      );
    case "up":
      return (
        <Badge
          variant="secondary"
          className="w-fit flex items-center gap-1 bg-gradient-to-r from-green-500 to-emerald-500 text-white hover:from-green-600 hover:to-emerald-600 whitespace-nowrap"
        >
          <TrendingUp className="h-3 w-3" />+{topic.trend.value}% Conversations
        </Badge>
      );
    case "down":
      return (
        <Badge
          variant="destructive"
          className="w-fit flex items-center gap-1 bg-gradient-to-r from-red-500 to-rose-500 text-white hover:from-red-600 hover:to-rose-600 whitespace-nowrap"
        >
          <TrendingDown className="h-3 w-3" />-{topic.trend.value}%
          Conversations
        </Badge>
      );
    case "inactive":
      return (
        <Badge
          variant="secondary"
          className="w-fit bg-gradient-to-r from-gray-500 to-gray-600 text-white hover:from-gray-600 hover:to-gray-700 whitespace-nowrap"
        >
          No Recent Mentions
        </Badge>
      );
    case "stable":
      return (
        <Badge variant="outline" className="w-fit whitespace-nowrap">
          Stable Ø {topic.trend.avg || 0} Conversations
        </Badge>
      );
  }
};

const TopicCard = ({
  topic,
  conversations,
}: {
  topic: Topic;
  conversations: number;
}) => {
  return (
    <Link to={`/${topic.project_id}/conversations?topic=${topic.id}`}>
      <Card className="cursor-pointer hover:bg-gray-50 transition-colors duration-200 h-full">
        <CardContent className="p-4 flex flex-col h-full">
          <div className="space-y-2 flex-grow">
            <div className="flex gap-2">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                className="mt-1.5 ml-1"
              >
                <circle cx="6" cy="6" r="6" fill={topic.color} />
              </svg>

              <h3 className="font-semibold flex items-center">{topic.title}</h3>
            </div>
            <TrendBadge topic={topic} />
            <p className="text-sm text-gray-600">{topic.description}</p>
          </div>

          <div className="flex justify-between items-center space-x-2 mt-2">
            <span className="text-sm font-medium text-gray-600">
              {conversations.toLocaleString()} conversations
            </span>
            <ArrowRight className="h-4 w-4 text-gray-500" />
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

function getCalendarWeekRange(weeksAgo: number): [Date, Date] {
  const now = new UTCDateMini();
  const currentMonday = startOfWeek(now, { weekStartsOn: 1 });
  const pastMonday = startOfWeek(subWeeks(now, weeksAgo), { weekStartsOn: 1 });

  return [pastMonday, currentMonday];
}

function transformMetricData(data: any[], relative: boolean): ChartDataPoint[] {
  // Group by StartDate
  const groupedByDate = new Map<string, ChartDataPoint>();

  data.forEach((row) => {
    const [startDate, endDate, period, , topicTitle, count, perc] = row;

    if (!groupedByDate.has(startDate)) {
      groupedByDate.set(startDate, {
        date: startDate,
        endDate: endDate,
        period: period,
      });
    }

    const point = groupedByDate.get(startDate)!;
    point[topicTitle] = relative ? perc : count;
  });

  return Array.from(groupedByDate.values()).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
}

const TopicsDashboard = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [timeRange, setTimeRange] = useState("3");
  const [chartType, setChartType] = useState("stacked");
  const weeksInRange = parseInt(timeRange) * 4;

  const [since, until] = getCalendarWeekRange(weeksInRange);
  const metricQuery = {
    metricName: "ConversationTopic",
    since: since.toISOString(),
    until: until.toISOString(),
    interval: "7d",
    project: [Number(projectId)],
  };
  const { metric, status } = useMetric(metricQuery);
  console.log(metric);
  const absoluteChartData =
    status == "succeeded" ? transformMetricData(metric.data, false) : [];
  const relativeChartData =
    status == "succeeded" ? transformMetricData(metric.data, true) : [];

  const { data } = useQuery({
    ...listTopicsOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });
  const topics = data?.data || [];
  const getTopicColor = createTopicColorMap(topics.map((topic) => topic.id));
  const topicsWithColor = topics.map((topic) => ({
    ...topic,
    color: getTopicColor(topic.id).main,
  }));

  // Data
  const fullTopics =
    status == "succeeded" && metric?.data?.length > 0
      ? generateTopicTrends(topicsWithColor, metric?.data)
      : [];

  const absoluteDonutData = fullTopics.map((topic) => ({
    name: topic.title,
    amount: absoluteChartData.reduce(
      (acc, week) => acc + ((week[topic.title] as number | undefined) || 0),
      0
    ),
  }));
  const total = absoluteDonutData.reduce((sum, item) => sum + item.amount, 0);
  const relativeDonutData = absoluteDonutData
    .map((item) => ({
      ...item,
      amount: (item.amount / total) * 100,
    }))
    .sort((a, b) => b.amount - a.amount);

  const topicConversationsHref = (topic: Topic) => {
    const end = endOfWeek(until, { weekStartsOn: 1 });
    const url = `/${topic.project_id}/conversations?since=${since.toISOString()}&until=${end.toISOString()}&topic=${topic.id}`;
    return url;
  };

  if (status === "loading") {
    return <Spinner />;
  }

  if (status === "succeeded" && fullTopics.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center mx-auto mt-4 h-[82vh] border-2 border-dashed border-gray-300 rounded-lg bg-gray-50">
        <h2 className="text-xl px-4 text-center font-semibold text-gray-700">
          TopicAI is not enabled for this project.
        </h2>
        <Button
          variant="default"
          onClick={() => window.$crisp.push(["do", "chat:open"])}
          className="mt-4"
        >
          <SendIcon className="h-4 w-4" />
          Contact Support
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-4 mt-4">
      <div className="flex flex-col sm:flex-row sm:justify-between gap-4">
        <div>
          <Button
            variant="outline"
            onClick={() => navigate("./../topics-visualization")}
            className="bg-gradient-to-r from-blue-500/10 to-indigo-500/10 hover:from-blue-500/20 hover:to-indigo-500/20 border-blue-200 hover:border-indigo-300 transition-all duration-300 shadow-md hover:shadow-blue-200/50 group"
          >
            <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent font-medium group-hover:from-blue-500 group-hover:to-indigo-500 transition-all duration-300">
              ✨ Open 3D Visualization
            </span>
            <ArrowUpRightIcon className="h-4 w-4 ml-2 text-blue-500 group-hover:text-indigo-500 transition-colors duration-300" />
          </Button>
        </div>
        <div className="flex flex-row items-center sm:items-center justify-between sm:justify-normal gap-4">
          <div className="flex items-center gap-3 text-sm bg-gray-50 px-3 py-2 rounded-md border">
            <div>
              <span className="font-medium">
                {since.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}
              </span>
              <span className="hidden sm:inline text-xs text-gray-400 ml-1">
                (CW{getWeek(since)})
              </span>
            </div>
            <span className="text-gray-400">→</span>
            <div>
              <span className="font-medium">
                {until.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}
              </span>
              <span className="hidden sm:inline text-xs text-gray-400 ml-1">
                (CW{getWeek(until)})
              </span>
            </div>
          </div>
          <Select value={timeRange} onValueChange={setTimeRange}>
            <SelectTrigger className="w-32">
              <SelectValue placeholder="Select range" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">1 month</SelectItem>
              <SelectItem value="3">3 months</SelectItem>
              <SelectItem value="6">6 months</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div>
          <Card>
            <CardHeader>
              <CardTitle>Topic Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex justify-center items-center">
                <DonutChart
                  variant="pie"
                  className="lg:h-80 h-52"
                  data={relativeDonutData}
                  category="name"
                  value="amount"
                  colorMap={
                    new Map(
                      topicsWithColor.map((topic) => [topic.title, topic.color])
                    )
                  }
                  valueFormatter={(number: number) =>
                    // `${Intl.NumberFormat("us").format(number)} conversations`
                    `${number.toFixed(2)}%`
                  }
                />
              </div>
            </CardContent>
          </Card>
        </div>
        <Card className="lg:col-span-3">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>Topic Trends in Conversations</CardTitle>
              <div className="flex items-center gap-2">
                <Switch
                  checked={chartType === "percent"}
                  onCheckedChange={(checked) =>
                    setChartType(checked ? "percent" : "stacked")
                  }
                />
                <Label htmlFor="relative-switch">Show relative values</Label>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <BarChart
              className="h-72"
              data={
                chartType == "percent" ? relativeChartData : absoluteChartData
              }
              index="period"
              categories={topics.map((topic) => topic.title)}
              colorMap={
                new Map(
                  topicsWithColor.map((topic) => [topic.title, topic.color])
                )
              }
              type={chartType as "stacked" | "percent"}
              showLegend={false}
              valueFormatter={(number: number) =>
                chartType === "percent" ? `${number}%` : String(number)
              }
            />
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 pb-4">
        {fullTopics.map((topic) => (
          <Link key={topic.id} to={topicConversationsHref(topic)}>
            <TopicCard
              topic={topic}
              conversations={
                absoluteDonutData.find((p) => p.name === topic.title)?.amount ||
                0
              }
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TopicsDashboard;
