import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

const FrameEditorSkeleton = () => {
  return (
    <div className="flex min-h-screen flex-col lg:flex-row">
      {/* Left panel */}
      <div className="w-full lg:w-3/5">
        <main className="mx-1 max-w-3xl sm:mx-auto">
          <div className="p-6 space-y-6">
            {/* Breadcrumb skeleton */}
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-4" />
              <Skeleton className="h-4 w-24" />
            </div>

            {/* Tabs skeleton */}
            <div className="flex justify-center">
              <div className="rounded-lg bg-gray-100/50 p-1 flex gap-2">
                {[...Array(5)].map((_, i) => (
                  <Skeleton key={i} className="h-9 w-24" />
                ))}
              </div>
            </div>

            {/* Content skeleton */}
            <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
              <div className="border-b border-gray-100 p-4">
                <div className="flex items-center gap-2">
                  <Skeleton className="h-5 w-5" />
                  <Skeleton className="h-5 w-32" />
                </div>
              </div>
              <div className="p-6 space-y-6">
                {/* Form field skeletons */}
                {[...Array(4)].map((_, i) => (
                  <div key={i} className="space-y-2">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-9 w-full" />
                  </div>
                ))}

                {/* Color picker field skeleton */}
                <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4">
                  <div className="flex items-center gap-2">
                    <Skeleton className="h-4 w-4" />
                    <Skeleton className="h-4 w-24" />
                  </div>
                  <Skeleton className="h-9 w-full" />
                </div>

                {/* Switch field skeleton */}
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <Skeleton className="h-4 w-32" />
                    <Skeleton className="h-3 w-48" />
                  </div>
                  <Skeleton className="h-6 w-11" />
                </div>
              </div>
            </Card>
          </div>

          {/* Footer buttons skeleton */}
          <div className="flex w-full justify-end gap-3 border-t border-gray-100 p-6">
            <Skeleton className="h-9 w-24" />
            <Skeleton className="h-9 w-32" />
          </div>
        </main>
      </div>

      {/* Right preview panel skeleton */}
      <div className="w-full bg-gray-50/50 p-2 lg:w-2/5">
        <div className="sticky top-[101px] h-[calc(100vh-101px)]">
          {/* Preview toolbar skeleton */}
          <div className="absolute left-2 top-0 z-10 space-y-4">
            <div className="flex items-center space-x-4">
              <Skeleton className="h-9 w-20" />
              <Skeleton className="h-9 w-9" />
              <Skeleton className="h-9 w-9" />
            </div>
          </div>
          {/* Preview content skeleton */}
          <Skeleton className="h-full w-full" />
        </div>
      </div>
    </div>
  );
};

export default FrameEditorSkeleton;
