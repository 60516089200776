import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Loader2, AlertCircle, ExternalLink } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";

import { ProjectUpdate } from "@/client";
import {
  getProjectOptions,
  updateProjectMutation,
} from "@/client/@tanstack/react-query.gen";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";

import { MemberSelect } from "./MemberSelect";
import { DealStatusSelector, NotesCard, ReviewToggle } from "./sales/sales";
import { CampaignCard } from "./sales/CampaignCard";

import { ProjectPreviewUrlButton } from "./PreviewUrl";
import NavigationHeader from "./sidebar/NavigationHeader";

const JsonEditor = ({
  value,
  onChange,
  disabled,
}: {
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [internalValue, setInternalValue] = useState("");

  useEffect(() => {
    try {
      setInternalValue(JSON.stringify(value, null, 2));
    } catch (e) {
      console.error("Failed to stringify JSON:", e);
    }
  }, [value]);

  const handleChange = (newValue: string) => {
    setInternalValue(newValue);
    try {
      const parsed = JSON.parse(newValue);
      setError(null);
      onChange(parsed);
    } catch (e) {
      setError("Invalid JSON format");
    }
  };

  return (
    <div className="space-y-2">
      <Textarea
        value={internalValue}
        onChange={(e) => handleChange(e.target.value)}
        className="font-mono text-sm min-h-[400px]"
        placeholder="Enter JSON data..."
        disabled={disabled}
      />
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

interface TopNavProps {
  projectId: number;
  employeesUrl: string;
  supportUrl: string;
}

export const TopNav = ({
  projectId,
  employeesUrl,
  supportUrl,
}: TopNavProps) => {
  const links = [
    { label: "Employees", url: employeesUrl },
    { label: "Support Staff & Leadership", url: supportUrl },
  ];

  return (
    <div className="flex gap-4 mb-6">
      <ProjectPreviewUrlButton
        projectId={projectId}
        variant="ghost"
        size="lg"
        className="text-gray-600 hover:text-blue-600 hover:bg-blue-50"
      >
        Preview URL
      </ProjectPreviewUrlButton>

      {links.map(({ label, url }) => (
        <Button
          key={label}
          variant="ghost"
          size="lg"
          asChild
          className="text-gray-600 hover:text-blue-600 hover:bg-blue-50"
        >
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2"
          >
            {label}
            <ExternalLink className="h-4 w-4" />
          </a>
        </Button>
      ))}
    </div>
  );
};

export function Assignee({
  projectId,
  disabledPlaceholder = false,
}: {
  projectId: number;
  disabledPlaceholder?: boolean;
}) {
  const [assigneeId, setAssigneeId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const projectQuery = getProjectOptions({
    path: { project_id: projectId },
    query: { include_internal: true },
  });

  const { data: project, isLoading: isLoadingProject } = useQuery({
    ...projectQuery,
    enabled: !queryClient.getQueryData(projectQuery.queryKey),
  });

  useEffect(() => {
    if (project?.included_internal_metadata?.assignee !== undefined) {
      setAssigneeId(
        (project.included_internal_metadata?.assignee as string) || null
      );
    }
  }, [project]);

  const updateProject = useMutation({
    ...updateProjectMutation({
      query: { merge: true },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectQuery.queryKey });
    },
    onError: (error) => {
      console.error("Failed to update assignee:", error);
      toast.error("Failed to update assignee");
    },
  });

  const handleAssigneeChange = (newAssigneeId: string | null | undefined) => {
    if (!project || isLoadingProject) return;

    setAssigneeId(newAssigneeId ?? null);

    const updatedProject: ProjectUpdate = {
      ...project,
      internal_metadata: {
        assignee: newAssigneeId,
      },
    };

    updateProject.mutate({
      path: { project_id: projectId },
      body: updatedProject,
    });
  };

  return (
    <MemberSelect
      value={assigneeId}
      onValueChange={handleAssigneeChange}
      placeholder="Select assignee..."
      disableDefault={disabledPlaceholder}
    />
  );
}

const NextPrevNavigation = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const currentId = parseInt(projectId || "0");
  const path = location.pathname.split("/").slice(2).join("/"); // Get everything after projectId

  const goToPrev = () => {
    if (currentId > 1) {
      navigate(`/${currentId - 1}/${path}`);
    }
  };

  const goToNext = () => {
    navigate(`/${currentId + 1}/${path}`);
  };

  return (
    <div className="flex gap-2">
      <Button
        variant="outline"
        onClick={goToPrev}
        disabled={currentId <= 1}
        size="sm"
      >
        <ChevronLeft className="h-4 w-4 mr-1" />
        Prev
      </Button>
      <Button variant="outline" onClick={goToNext} size="sm">
        Next
        <ChevronRight className="h-4 w-4 ml-1" />
      </Button>
    </div>
  );
};

export default function ProjectSalesPage() {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [internalMetadata, setInternalMetadata] = useState<any>(null);
  const projectQuery = getProjectOptions({
    path: { project_id: Number(projectId) },
    query: { include_internal: true },
  });
  const projectQueryKey = projectQuery.queryKey;
  console.log(projectQueryKey);

  const {
    data: project,
    isLoading,
    isError,
  } = useQuery({
    ...projectQuery,
    retry: 1,
  });

  // Update state based on fetched data
  useEffect(() => {
    if (project?.included_internal_metadata) {
      setInternalMetadata(project.included_internal_metadata);
    }
  }, [project]);

  // Update project mutation
  const updateProject = useMutation({
    ...updateProjectMutation(),
    onSuccess: () => {
      toast.success("Project updated successfully");
      queryClient.invalidateQueries({
        queryKey: projectQueryKey,
      });
    },
    onError: () => {
      toast.error("Failed to update project");
    },
  });

  const handleUpdateMetadata = () => {
    if (!project || !internalMetadata) return;

    const updatedProject: ProjectUpdate = {
      ...project,
      internal_metadata: internalMetadata,
    };

    updateProject.mutate({
      path: { project_id: Number(projectId) },
      body: updatedProject,
    });
  };

  if (isError) {
    return (
      <div>
        <p>Project does note exist / is deleted.</p>
        <NextPrevNavigation />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  // URL construction
  let employeesUrl = "https://app.apollo.io/#/people?interval=ALL";
  const domain = project?.website_url
    ? new URL(project.website_url).hostname
    : "";
  if (domain) employeesUrl += `&q_organization_domains=${domain}`;

  // Support staff URL construction
  const params = {
    "person_titles[]": [
      "customer service",
      "customer support",
      "customer success",
    ],
    // "person_seniorities[]": [
    //   "manager",
    //   "lead",
    //   "head",
    //   "senior",
    //   "directory",
    //   "vp",
    // ],
    email_status: "verified",
  };
  const paramString = new URLSearchParams(
    Object.entries(params).flatMap(([key, value]) =>
      Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
    )
  ).toString();
  const supportAndLeadership = employeesUrl + "&" + paramString;

  return (
    <>
      <NavigationHeader
        items={[
          { href: "/sales", label: "Sales Dashboard" },
          {
            label: "Project Dashboard",
          },
        ]}
      />

      <div className="container mx-auto px-8 max-w-7xl">
        <h1 className="text-3xl font-bold mb-8">Sales Dashboard</h1>
        <div className="flex flex-col lg:flex-row gap-4 items-start justify-between w-full">
          <div className="w-full lg:w-auto">
            <TopNav
              projectId={Number(projectId)}
              employeesUrl={employeesUrl}
              supportUrl={supportAndLeadership}
            />
          </div>

          <div className="w-full sm:w-64">
            <DealStatusSelector projectId={Number(projectId)} />
          </div>
          <div className="w-full sm:w-64 mb-4">
            <Assignee projectId={Number(projectId)} />
          </div>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            <ReviewToggle projectId={Number(projectId)} />
            <NotesCard projectId={Number(projectId)} />
            <CampaignCard projectId={Number(projectId)} />
          </div>
          <NextPrevNavigation />
          <Card>
            <CardHeader>
              <CardTitle>Raw Data</CardTitle>
            </CardHeader>
            <CardContent>
              <JsonEditor
                value={internalMetadata}
                onChange={setInternalMetadata}
                disabled={updateProject.isPending}
              />

              <div className="mt-4 flex justify-end">
                <Button
                  onClick={handleUpdateMetadata}
                  disabled={updateProject.isPending}
                  variant="outline"
                >
                  {updateProject.isPending ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Updating Metadata...
                    </>
                  ) : (
                    "Update Metadata"
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
