import { useUser } from "@clerk/clerk-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AlertCircle,
  Bell,
  Loader2,
  Save,
  Settings2,
  Trash2,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import {
  deleteProjectMutation,
  getProjectOptions,
  updateProjectMutation,
} from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import useFlagEnabled from "@/features";
import { ProjectSchema } from "@/lib/schemas";

import Spinner from "./Loading";

// import { useToast } from "@/components/ui/use-toast";

import { DescribableFields } from "./SharedForm";
import { Alert, AlertDescription } from "./ui/alert";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import NavigationHeader from "./sidebar/NavigationHeader";
import { useCurrentProjectBreadcrumbs, useCurrentProjectV2 } from "@/hooks";
import PageHeader from "./PageHeader";

type ProjectFormValues = z.infer<typeof ProjectSchema>;

function ProjectSettingsForm() {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  const { data: project, isLoading } = useQuery({
    ...getProjectOptions({
      path: { project_id: Number(projectId!) },
    }),
    enabled: !!projectId,
  });

  const updateProject = useMutation({
    ...updateProjectMutation(),
    onSuccess: () => {
      toast("Settings updated", {
        description: "Project settings have been successfully updated.",
      });
      void queryClient.invalidateQueries({
        queryKey: ["project", projectId],
      });
    },
    onError: () => {
      toast.error("Error", {
        description: "Failed to update project settings.",
      });
    },
  });

  const form = useForm<ProjectFormValues>({
    resolver: zodResolver(ProjectSchema),
    mode: "onChange",
    defaultValues: {
      support_email: "",
    },
  });

  useEffect(() => {
    if (project) {
      form.reset(project);
    }
  }, [project, form]);

  if (isLoading) return <Spinner />;

  const onSubmit = (data: ProjectFormValues) => {
    updateProject.mutate({
      body: {
        id: Number(projectId!),
        ...data,
      },
      path: { project_id: Number(projectId!) },
    });
  };

  return (
    <Card className="mb-6">
      <CardHeader>
        <div className="flex items-center gap-2">
          <Settings2 className="h-5 w-5 text-primary" />
          <CardTitle>Project Settings</CardTitle>
        </div>
        <CardDescription>
          Configure your project's core settings and support contact information
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <DescribableFields form={form} />
            <FormField
              control={form.control}
              name="support_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Support Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="support@example.com"
                      {...field}
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center justify-start">
              <Button
                type="submit"
                variant={"default"}
                className="w-full sm:w-auto"
                disabled={updateProject.isPending}
              >
                {updateProject.isPending ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <Save className="mr-2 h-4 w-4" />
                )}
                {updateProject.isPending ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}

function PersonalProjectSettings() {
  const { user } = useUser();
  const { projectId } = useParams();

  if (projectId === undefined) {
    throw new Error("project_id is undefined");
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-2">
          <Bell className="h-5 w-5 text-primary" />
          <CardTitle>Personal Settings</CardTitle>
        </div>
        <CardDescription>
          Customize your personal preferences and notification settings for this
          project
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                <Label>Weekly Digest</Label>
                <p className="text-sm text-gray-500">
                  Receive a weekly digest of your project's activity and
                  insights
                </p>
              </div>
              <Switch
                checked={
                  (user?.unsafeMetadata?.[projectId] as any)?.notifications
                    ?.weekly_digest_email ?? true
                }
                onCheckedChange={(checked) => {
                  user?.update({
                    unsafeMetadata: {
                      ...user.unsafeMetadata,
                      [projectId]: {
                        ...(user.unsafeMetadata[projectId] ?? {}),
                        notifications: {
                          ...((user.unsafeMetadata[projectId] as any)
                            ?.notifications ?? {}),
                          weekly_digest_email: checked,
                        },
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function DeleteProjectSection() {
  const { projectId } = useParams();
  const { data: project } = useCurrentProjectV2();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const deleteProject = useMutation({
    ...deleteProjectMutation(),
    onSuccess: () => {
      toast.success("Project deleted successfully");
      navigate("/");
    },
    onError: () => {
      toast.error("Failed to delete project");
    },
  });

  const handleDelete = () => {
    if (confirmText.toLowerCase() === "delete") {
      deleteProject.mutate({
        path: { project_id: Number(projectId) },
      });
    }
  };

  return (
    <Card className="border-destructive/50">
      <CardHeader>
        <div className="flex items-center gap-2">
          <Trash2 className="h-5 w-5 text-destructive" />
          <CardTitle>Danger Zone</CardTitle>
        </div>
        <CardDescription>
          Permanently delete this project and all of its data
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          {/* NOTE(liamvdv): actually soft delete, but painful manually */}
          <AlertDescription>
            This action cannot be undone. This will permanently delete the
            project and all associated data.
          </AlertDescription>
        </Alert>
        <Button
          variant="destructive"
          onClick={() => setShowDialog(true)}
          className="w-full sm:w-auto"
          disabled={deleteProject.isPending}
        >
          {deleteProject.isPending ? (
            <div className="flex items-center gap-2">
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
              Deleting...
            </div>
          ) : (
            <>
              <Trash2 className="mr-2 h-4 w-4" />
              Delete Project
            </>
          )}
        </Button>
      </CardContent>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Project</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete{" "}
              <span className="font-semibold">{project?.name ?? ""}</span> and
              all associated data.
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <p className="mb-4 text-sm text-muted-foreground">
              Please type{" "}
              <span className="font-semibold text-destructive">delete</span> to
              confirm
            </p>
            <Input
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              placeholder="Type 'delete' to confirm"
              className="max-w-sm"
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={confirmText.toLowerCase() !== "delete"}
            >
              Delete Project
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

export default function ProjectSettingsPage() {
  const flagDeleteProject = useFlagEnabled("delete-project");
  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Settings" },
  ];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="space-y-6">
        <PageHeader title="Settings" subtitle="Manage your project settings" />
        <ProjectSettingsForm />
        <PersonalProjectSettings />
        {flagDeleteProject && <DeleteProjectSection />}
      </div>
    </>
  );
}
