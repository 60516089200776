import { OrganizationSwitcher, useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import Spinner from "./Loading";
import AdminToggle from "./AdminUi";

function isAllowedHostname(url: string) {
  const allowedDashboardHostnames = [
    "staging.dashboard.botbrains.io",
    "dashboard.botbrains.io",
    "localhost",
  ];
  try {
    return allowedDashboardHostnames.includes(new URL(url).hostname);
  } catch (e) {
    return false;
  }
}

// DashboardToken component
// This component is used to generate a token for the dashboard.
// It can be used to do an auth flow with the dashboard (?dashboard_url=...&is_redirect)
// The hostnames are restricted to the `allowedDashboardHostnames`.

export default function DashboardToken() {
  const [searchParams] = useSearchParams();
  let dashboard_url = searchParams.get("dashboard_url") || "";
  if (!isAllowedHostname(dashboard_url))
    dashboard_url = import.meta.env.VITE_DASHBOARD_URL;
  const isRedirect = searchParams.get("is_redirect") != null;

  const [token, setToken] = useState<string>("");
  const [copyStatus, setCopyStatus] = useState("");
  const { user } = useUser();
  const isAdmin = user?.publicMetadata?.sys_admin;

  useEffect(() => {
    const getCredentials = async () => {
      const platformToken = await (window as any).Clerk.session.getToken({
        template: "api_botbrains_io",
      });
      setToken(platformToken);
    };
    getCredentials();
  });

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(token);
      setCopyStatus("Copied!");
    } catch (err) {
      setCopyStatus("Failed to copy!");
    }
  };

  if (!isAdmin) return <></>;
  if (token === "") return <Spinner />;

  if (isRedirect) {
    window.location.href = `${dashboard_url}?token=${token}`;
    return <Spinner />;
  }
  return (
    <div className="mx-1 max-w-7xl sm:mx-auto">
      <div className="flex flex-col items-center justify-center">
        <div className="mb-6 mt-32 flex items-center justify-center gap-4">
          <span className="text-md mb-1">You are currently in</span>
          <OrganizationSwitcher hidePersonal={true} />
        </div>
        <div className="max-w-2xl text-xs" onClick={copyToClipboard}>
          <p className="text-2xl">{copyStatus}</p>
          <p className="break-words rounded bg-gray-100 p-4">{token}</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${dashboard_url}?token=${token}`}
          >
            <button className="mt-4 w-full rounded bg-emerald-500 p-2   text-white hover:bg-emerald-600">
              Open Dashboard
            </button>
          </a>
          <button className="mt-4 w-full rounded border border-emerald-500 p-2 text-emerald-600 hover:bg-emerald-500 hover:text-white">
            Copy Token
          </button>
        </div>
        <Link to={`/`} className="mt-7 text-sm text-gray-500">
          Back to Projects
        </Link>
      </div>
      <AdminToggle />
    </div>
  );
}
