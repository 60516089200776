export default function Footer() {
  return (
    <footer>
      <div className="mx-auto px-2 md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-4 md:order-2">
          <iframe
            src="https://status.botbrains.io/badge?theme=light"
            width="190"
            height="35"
          ></iframe>
        </div>
        <div className="md:order-1">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024 botBrains UG (haftungsbeschränkt). All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
