import { useQuery } from "@tanstack/react-query";
import {
  CircleDot,
  CircleSlash,
  Loader2,
  MinusCircle,
  PlusCircle,
} from "lucide-react";
import { useMemo } from "react";

import {
  getDataproviderOptions,
  listSourcesOptions,
} from "@/client/@tanstack/react-query.gen";
import { SourceRead } from "@/client/types.gen";
import { cn } from "@/lib/utils";

import { DiffStatus, SourcesDataTable } from "../sources/SourcesDataTable";
import { AxiosErrorBox } from "../Error";

function compareSourceMetadata(
  source1: SourceRead,
  source2: SourceRead
): boolean {
  return (
    source1.name === source2.name &&
    source1.file_type === source2.file_type &&
    source1.text_bytesize === source2.text_bytesize
  );
}

const priority: Record<DiffStatus, number> = {
  changed: 0,
  added: 1,
  removed: 2,
  unchanged: 3,
};

function calculateSourceDiff(
  currentSources: SourceRead[] | undefined,
  previousSources: SourceRead[] | undefined
) {
  if (!currentSources || !previousSources) {
    return {
      diffs: [],
      stats: { added: 0, removed: 0, changed: 0, unchanged: 0 },
    };
  }

  const currentSourceMap = new Map(
    currentSources.map((source) => [source.resource_id, source])
  );
  const previousSourceMap = new Map(
    previousSources.map((source) => [source.resource_id, source])
  );

  const allResourceIds = new Set([
    ...currentSourceMap.keys(),
    ...previousSourceMap.keys(),
  ]);

  const diffs = Array.from(allResourceIds)
    .map((resourceId) => {
      const current = currentSourceMap.get(resourceId);
      const previous = previousSourceMap.get(resourceId);

      if (current && !previous) {
        return { ...current, status: "added" as const };
      }
      if (!current && previous) {
        return { ...previous, status: "removed" as const };
      }
      if (current && previous) {
        if (!compareSourceMetadata(current, previous)) {
          return {
            ...current,
            status: "changed" as const,
            changes: {
              from: previous,
            },
          };
        }
        return { ...current, status: "unchanged" as const };
      }
      throw new Error("Unreachable");
    })
    .sort((a, b) => priority[a.status] - priority[b.status]);

  const stats = {
    added: diffs.filter((d) => d.status === "added").length,
    removed: diffs.filter((d) => d.status === "removed").length,
    changed: diffs.filter((d) => d.status === "changed").length,
    unchanged: diffs.filter((d) => d.status === "unchanged").length,
  };

  return { diffs, stats };
}

export function SnapshotDiff({
  projectId,
  providerId,
  currentSnapshotId,
  previousSnapshotId,
}: {
  projectId: number;
  providerId: string;
  currentSnapshotId: string;
  previousSnapshotId: string;
}) {
  const {
    data: provider,
    isPending: providerIsPending,
    error,
  } = useQuery({
    ...getDataproviderOptions({
      path: {
        project_id: projectId,
        data_provider_id: providerId,
      },
    }),
  });

  if (providerIsPending) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (error) {
    return <AxiosErrorBox error={error} />;
  }

  const { data: currentSourcesData } = useQuery({
    ...listSourcesOptions({
      path: { project_id: projectId, data_provider_id: providerId },
      query: { data_provider_snapshot_id: currentSnapshotId },
    }),
  });

  const { data: previousSourcesData } = useQuery({
    ...listSourcesOptions({
      path: { project_id: projectId, data_provider_id: providerId },
      query: { data_provider_snapshot_id: previousSnapshotId },
    }),
  });

  const { diffs, stats } = useMemo(
    () =>
      calculateSourceDiff(
        currentSourcesData?.sources,
        previousSourcesData?.sources
      ),
    [currentSourcesData, previousSourcesData]
  );

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-4 justify-between items-center">
        <div className="flex flex-wrap gap-3 text-sm">
          {stats.added > 0 && (
            <span className="flex items-center gap-2">
              <PlusCircle className="h-4 w-4 text-green-500" />
              {stats.added} added
            </span>
          )}
          {stats.removed > 0 && (
            <span className="flex items-center gap-2">
              <MinusCircle className="h-4 w-4 text-red-500" />
              {stats.removed} removed
            </span>
          )}
          {stats.changed > 0 && (
            <span className="flex items-center gap-2">
              <CircleDot className="h-4 w-4 text-amber-500" />
              {stats.changed} changed
            </span>
          )}
          {stats.unchanged > 0 && (
            <span className="flex items-center gap-2">
              <CircleSlash className="h-4 w-4 text-gray-400" />
              {stats.unchanged} unchanged
            </span>
          )}
        </div>
      </div>
      <SourcesDataTable
        data={diffs}
        columnVisibility={{
          select: false,
          status: true,
          resource_id: provider.data_provider_type === "webcrawler",
        }}
        rowClassName={(row) =>
          cn(
            row.status === "added" && "bg-green-50 hover:bg-green-100",
            row.status === "removed" && "bg-red-50 hover:bg-red-100",
            row.status === "changed" && "bg-amber-50 hover:bg-amber-100"
          )
        }
      />
    </div>
  );
}
