import { UTCDate } from "@date-fns/utc";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { ArrowUpRight } from "lucide-react";
import { Link } from "react-router-dom";

import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

import { useCurrentProject } from "../hooks";
import { TripletMetricsCard } from "./charts/MetricCards";
import { ConversationsMetadataListV2 } from "./conversations/ConversationsListPage";
import NavigationHeader from "./sidebar/NavigationHeader";
import PageHeader from "./PageHeader";
import Footer from "./Footer";

export default function ProjectDashboard() {
  const { project } = useCurrentProject();

  if (!project) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    );
  }

  const since = startOfDay(subDays(new Date(), 7)).toISOString();
  const until = endOfDay(new UTCDate()).toISOString();

  return (
    <>
      <div className="min-h-screen">
        <NavigationHeader
          items={[{ href: "/", label: "Projects" }, { label: project.name }]}
        />
        <div className="mb-4 space-y-4">
          <div className="flex items-center justify-between">
            <PageHeader title={project.name} subtitle={project.description} />
            <button
              className="inline-flex items-center gap-2 rounded-lg bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90"
              // @ts-ignore
              onClick={() => window.$crisp.push(["do", "chat:open"])}
            >
              Give Feedback
              <ArrowUpRight className="h-4 w-4" />
            </button>
          </div>
        </div>
        <TripletMetricsCard lastDays={30} />
        <Card className="my-8">
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle>Recent Conversations</CardTitle>
            <Link
              to="./conversations"
              className="inline-flex items-center text-sm text-primary hover:underline"
            >
              View All
              <ArrowUpRight className="ml-1 h-4 w-4" />
            </Link>
          </CardHeader>
          <CardContent>
            <ConversationsMetadataListV2
              projectId={project.id}
              since={new Date(since)}
              until={new Date(until)}
              aliases={[]}
              topics={[]}
            />
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
}
