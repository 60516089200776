// NOTE(memben): inspired by https://sashamaps.net/docs/resources/20-colors/, refined with claude (e.g https://claude.ai/chat/a6fc05cc-81c6-459b-9ee5-dc137d135290)

export type ColorSet = {
  main: string;
  light: string;
  dark: string;
  ultraLight: string;
  ultraDark: string;
};

export const PLACE_HOLDER: ColorSet = {
  main: "#E0E0E0",
  light: "#F0F0F0",
  dark: "#D0D0D0",
  ultraLight: "#F8F8F8",
  ultraDark: "#C0C0C0",
};

const MODERN_COLORS: Record<string, ColorSet> = {
  red: {
    main: "#FF3B4B",
    light: "#FF8F99",
    dark: "#CC2F3C",
    ultraLight: "#FFE5E7",
    ultraDark: "#802024",
  },
  green: {
    main: "#33CC55",
    light: "#7FE696",
    dark: "#29A344",
    ultraLight: "#E8F9ED",
    ultraDark: "#1A6629",
  },
  yellow: {
    main: "#FFD700",
    light: "#FFE659",
    dark: "#CCB100",
    ultraLight: "#FFF9E5",
    ultraDark: "#806C00",
  },
  blue: {
    main: "#4169E1",
    light: "#8CA3ED",
    dark: "#3454B4",
    ultraLight: "#EDF1FC",
    ultraDark: "#213470",
  },
  orange: {
    main: "#FF7B29",
    light: "#FFA872",
    dark: "#CC6221",
    ultraLight: "#FFF0E8",
    ultraDark: "#803D15",
  },
  purple: {
    main: "#9933CC",
    light: "#BF7FE6",
    dark: "#7A29A3",
    ultraLight: "#F5E8FA",
    ultraDark: "#4C1966",
  },
  cyan: {
    main: "#00CCD4",
    light: "#59E3E9",
    dark: "#00A3AA",
    ultraLight: "#E5FAFB",
    ultraDark: "#006669",
  },
  magenta: {
    main: "#FF2B9D",
    light: "#FF75C1",
    dark: "#CC227E",
    ultraLight: "#FFE5F4",
    ultraDark: "#80154E",
  },
  lime: {
    main: "#AADD00",
    light: "#C8E959",
    dark: "#88B100",
    ultraLight: "#F7FCE5",
    ultraDark: "#557000",
  },
  pink: {
    main: "#FF8FA3",
    light: "#FFB8C4",
    dark: "#CC7282",
    ultraLight: "#FFF2F4",
    ultraDark: "#804751",
  },
  teal: {
    main: "#00A5A5",
    light: "#59CFCF",
    dark: "#008484",
    ultraLight: "#E5F7F7",
    ultraDark: "#005252",
  },
  lavender: {
    main: "#D4AAFF",
    light: "#E5CCFF",
    dark: "#AA88CC",
    ultraLight: "#F9F5FF",
    ultraDark: "#695480",
  },
  brown: {
    main: "#8B4513",
    light: "#B37A4D",
    dark: "#6F370F",
    ultraLight: "#F2E5DC",
    ultraDark: "#45220A",
  },
  beige: {
    main: "#FFF4BD",
    light: "#FFF7D1",
    dark: "#CCC397",
    ultraLight: "#FFFCF5",
    ultraDark: "#80795E",
  },
  maroon: {
    main: "#CC0000",
    light: "#FF4D4D",
    dark: "#A30000",
    ultraLight: "#FFE5E5",
    ultraDark: "#660000",
  },
  mint: {
    main: "#80FFB8",
    light: "#B3FFCF",
    dark: "#66CC93",
    ultraLight: "#F0FFF6",
    ultraDark: "#408F5C",
  },
  olive: {
    main: "#6B8E23",
    light: "#97B55F",
    dark: "#56721C",
    ultraLight: "#EEF2E6",
    ultraDark: "#364711",
  },
  apricot: {
    main: "#FFAB76",
    light: "#FFC7A3",
    dark: "#CC895E",
    ultraLight: "#FFF4ED",
    ultraDark: "#80553B",
  },
  navy: {
    main: "#1A237E",
    light: "#535AA5",
    dark: "#151C65",
    ultraLight: "#E6E7F0",
    ultraDark: "#0D113F",
  },
  grey: {
    main: "#607D8B",
    light: "#8FA3AE",
    dark: "#4D646F",
    ultraLight: "#EEF1F3",
    ultraDark: "#303F45",
  },
};

const hslToHex = (h: number, s: number, l: number): string => {
  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
};

const generateColorSet = (
  totalClasses: number,
  index: number,
  baseSaturation: number = 80,
  baseLightness: number = 55
): ColorSet => {
  const hue = (index * (360 / totalClasses)) % 360;

  return {
    main: hslToHex(hue, baseSaturation, baseLightness),
    light: hslToHex(hue, baseSaturation * 0.9, baseLightness * 1.25),
    dark: hslToHex(hue, baseSaturation * 1.1, baseLightness * 0.8),
    ultraLight: hslToHex(hue, baseSaturation * 0.5, baseLightness * 1.4),
    ultraDark: hslToHex(hue, baseSaturation * 1.2, baseLightness * 0.6),
  };
};

export function getDistinctClassColor(
  totalClasses: number,
  index: number
): ColorSet {
  if (totalClasses <= Object.keys(MODERN_COLORS).length) {
    const colorKeys = Object.keys(MODERN_COLORS);
    return MODERN_COLORS[colorKeys[index]];
  }
  return generateColorSet(totalClasses, index);
}

// NOTE(memben) topic ids are uuidv7 making them stable through time
export const createTopicColorMap = (topics: string[]) => {
  const sortedTopics = topics.sort();
  return (topic: string) => {
    const index = sortedTopics.indexOf(topic);
    return getDistinctClassColor(sortedTopics.length, index);
  };
};
