import { useUser } from "@clerk/clerk-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { unwrapResult } from "@reduxjs/toolkit";
import { Loader2, PlayCircle, X, TestTubeDiagonal } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TestSuiteRunSchema } from "@/lib/schemas";
import { createTestSuiteRun } from "@/slices/evaluations";

import {
  useAliases,
  useAppDispatch,
  useCurrentProjectBreadcrumbs,
  useTestSuites,
} from "../../hooks";
import { AxiosErrorBox } from "../Error";
import Spinner from "../Loading";
import { DescribableFields } from "../SharedForm";
import NavigationHeader from "../sidebar/NavigationHeader";

type TestSuiteRunFormValues = z.infer<typeof TestSuiteRunSchema>;

function TestSuiteRunForm() {
  const { projectId, testSuiteId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useUser();
  const isAdmin = (user?.publicMetadata?.sys_admin as boolean) ?? false;

  if (!projectId) throw new Error("Project ID is required");

  const {
    data: aliasList,
    status,
    error,
  } = useAliases({
    projectId: Number(projectId),
  });

  const form = useForm<TestSuiteRunFormValues>({
    resolver: zodResolver(TestSuiteRunSchema),
    mode: "onChange",
    defaultValues: {
      project_id: Number(projectId),
      testsuite_id: testSuiteId,
      name: "",
      description: "",
      deployment_id: undefined,
    },
  });

  const aliases = useMemo(() => {
    const productionAliases =
      aliasList?.aliases.filter((a) => a.type === "MUTABLE") ?? [];
    const previewAliases = isAdmin
      ? (aliasList?.aliases
          .filter((a) => a.type === "IMMUTABLE")
          .filter(
            (a) =>
              !productionAliases.some(
                (p) => p.deployment_id === a.deployment_id
              )
          ) ?? [])
      : [];

    return [...productionAliases, ...previewAliases].sort((a, b) => {
      if (a.type === b.type) {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      }
      return a.type === "MUTABLE" ? -1 : 1;
    });
  }, [aliasList, isAdmin]);

  useEffect(() => {
    if (aliases.length > 0) {
      form.setValue("deployment_id", aliases[0].deployment_id, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [aliases]);

  if (status === "error") return <AxiosErrorBox error={error} />;
  if (status === "pending" || aliasList === undefined) return <Spinner />;

  if (aliases.length === 0) {
    return (
      <Alert className="mx-auto max-w-xl">
        <AlertDescription>
          No production aliases found. You need to create one before continuing.
        </AlertDescription>
      </Alert>
    );
  }

  const onSubmit = async (values: TestSuiteRunFormValues) => {
    setIsLoading(true);
    setSubmitError(null);

    try {
      const actionResult = await dispatch(createTestSuiteRun(values));
      unwrapResult(actionResult);
      navigate(`/${projectId}/testsuites/${testSuiteId}/runs`);
    } catch (err: any) {
      console.error("Error creating test suite run:", err);
      setSubmitError(err.message || "An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card className="w-full max-w-3xl rounded-xl border border-gray-100 bg-white/80 shadow-lg">
        <CardHeader className="border-b border-gray-100 pb-4">
          <div className="flex items-center gap-2">
            <TestTubeDiagonal className="h-5 w-5 text-primary" />
            <CardTitle className="text-lg font-semibold text-gray-800">
              Configure Test Suite Run
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <DescribableFields form={form} />

              <FormField
                control={form.control}
                name="deployment_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium text-gray-700">
                      Chatbot Alias
                    </FormLabel>
                    <Select
                      onValueChange={(value) => field.onChange(Number(value))}
                      value={field.value ? String(field.value) : undefined}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full border-gray-200 bg-white">
                          <SelectValue placeholder="Select a Chatbot Alias" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {aliases.map((alias) => (
                          <SelectItem
                            key={alias.id + alias.description}
                            value={String(alias.deployment_id)}
                            className="cursor-pointer hover:bg-gray-50"
                          >
                            {alias.description}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription className="text-sm text-gray-500">
                      Select a chatbot alias to run the test suite against.
                    </FormDescription>
                  </FormItem>
                )}
              />

              {submitError && (
                <Alert variant="destructive">
                  <AlertDescription>{submitError}</AlertDescription>
                </Alert>
              )}

              <div className="flex items-center justify-end gap-3 border-t border-gray-100 pt-6">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => navigate(-1)}
                  disabled={isLoading}
                  className="text-gray-600 hover:text-gray-700"
                >
                  <X className="mr-2 h-4 w-4" />
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant={"default"}
                  disabled={isLoading || !form.formState.isValid}
                  className="disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Starting...
                    </>
                  ) : (
                    <>
                      <PlayCircle className="mr-2 h-4 w-4" />
                      Start Test Run
                    </>
                  )}
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}

export default function TestSuiteRunCreatePage() {
  const { projectId, testSuiteId } = useParams();
  const { testSuites } = useTestSuites({ project_id: Number(projectId) });
  const testSuite = testSuites.find((t) => t.id === testSuiteId);

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: "./../../..", label: "Test Suites" },
    { href: "./../..", label: testSuite?.name ?? "Test Suite" },
    { href: "./..", label: "Runs" },
    { label: "Create" },
  ];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="w-full flex justify-center">
        <TestSuiteRunForm />
      </div>
    </>
  );
}
