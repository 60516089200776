import posthog from "posthog-js";

export function setupAnalytics() {
  // choose domain based over environment based, because we sometimes run production locally.
  const isDeployed = window.location.hostname.endsWith("platform.botbrains.io");
  if (!isDeployed) {
    console.log(
      "botbrains.analytics: skip setupRum because not deployed on platform.botbrains.io"
    );
    return;
  }
  if (import.meta.env.VITE_POSTHOG_API_KEY) {
    posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
      api_host: "https://eu.posthog.com",
    });
  }
}
