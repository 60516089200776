import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { deleteTestSuiteRun } from "@/slices/evaluations";

import {
  useAppDispatch,
  useCurrentProjectBreadcrumbs,
  usePollPendingTestCaseRuns,
  useTestCaseRunsByTestSuiteRun,
  useTestSuiteRunsByTestSuite,
  useTestSuites,
} from "../../hooks";
import Spinner from "../Loading";
import { TestCaseRunTable } from "./TestCaseRunTable";
import WithTestSuiteSidebar from "./WithTestSuiteSidebar";
import NavigationHeader from "../sidebar/NavigationHeader";

function TestSuiteRunDropdownMenu({
  projectId,
  testSuiteId,
  testSuiteRunId,
}: {
  projectId: number;
  testSuiteId: string;
  testSuiteRunId: string;
  testSuiteRunName: string;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function dispatchDelete() {
    setIsDeleting(true);
    try {
      const actionResult = await dispatch(
        deleteTestSuiteRun({
          project_id: projectId,
          testsuite_run_id: testSuiteRunId,
        })
      );
      unwrapResult(actionResult);
      navigate(`/${projectId}/testsuites/${testSuiteId}`);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsDeleting(false);
    }
  }

  if (isDeleting) {
    return <Spinner />;
  }

  if (errorMessage) {
    return <p className="text-red-500">{errorMessage}</p>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <EllipsisVerticalIcon className="h-6 w-6 "></EllipsisVerticalIcon>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Test Suite Run</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            if (window.confirm("Do you want to continue")) {
              dispatchDelete();
            }
          }}
        >
          {/* <ConfirmDialog
            buttonLabel="Delete"
            dialogTitle={`Warning! You are about to delete Test Suite ${testSuiteName}`}
            dialogDescription="You cannot undo this action. Are you sure you want to continue?"
          /> */}
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TestSuiteRunPage() {
  const { projectId, testSuiteId, testSuiteRunId } = useParams();
  if (projectId == undefined)
    throw new Error("Logical Error: projectId is undefined");
  if (testSuiteId == undefined)
    throw new Error("Logical Error: testSuiteId is undefined");
  if (testSuiteRunId == undefined)
    throw new Error("Logical Error: testSuiteRunId is undefined");
  const {
    testSuiteRuns,
    status: testSuiteRunsStatus,
    errorMessage: testSuiteRunErrorMessage,
  } = useTestSuiteRunsByTestSuite({
    project_id: Number(projectId),
    testSuiteId,
  });
  const testSuiteRun = testSuiteRuns.find((tsr) => tsr.id == testSuiteRunId);

  const { testSuites } = useTestSuites({
    project_id: Number(projectId),
  });

  const testSuite = testSuites.find((ts) => ts.id == testSuiteId);

  const {
    testCaseRuns,
    status: testCaseRunsStatus,
    errorMessage: testCaseRunsErrorMessage,
  } = useTestCaseRunsByTestSuiteRun({
    project_id: Number(projectId),
    testsuite_run_id: testSuiteRunId,
  });

  usePollPendingTestCaseRuns({
    project_id: Number(projectId),
    testsuite_run_id: testSuiteRunId,
  });

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: "./../../..", label: "Test Suites" },
    { href: "./../..", label: testSuite?.name ?? "Test Suite" },
    { href: "./..", label: "Test Suite Runs" },
    { label: testSuiteRun?.name ?? "Test Suite Run" },
  ];

  if (testCaseRunsStatus == "failed") {
    return <p>Error: {testCaseRunsErrorMessage}</p>;
  }
  if (testSuiteRunsStatus == "failed") {
    return <p>Error: {testSuiteRunErrorMessage}</p>;
  }

  if (testSuiteRunsStatus == "loading" || testCaseRunsStatus == "loading")
    return <Spinner />;

  if (testSuiteRun == undefined) return <p>Test Suite Run not found</p>;

  return (
    <WithTestSuiteSidebar
      projectId={Number(projectId)}
      selectedTestSuiteId={testSuiteId}
    >
      <NavigationHeader items={breadcrumbs} />
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          {testSuiteRun.name}
        </h1>
        <TestSuiteRunDropdownMenu
          projectId={Number(projectId)}
          testSuiteId={testSuiteId}
          testSuiteRunId={testSuiteRunId}
          testSuiteRunName={testSuiteRun.name}
        />
      </div>
      <p className="mt-2 min-h-[1rem]">{testSuiteRun.description}</p>
      <TestCaseRunTable testCaseRuns={testCaseRuns} />
    </WithTestSuiteSidebar>
  );
}

export default TestSuiteRunPage;
