import { UTCDateMini } from "@date-fns/utc";
import {
  addDays,
  differenceInDays,
  isEqual,
  startOfDay,
  subDays,
} from "date-fns";
import { MessageSquare, MessagesSquare, Users2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";

import { Card, CardContent } from "@/components/ui/card";
import { useMetricComparison } from "@/hooks/charts";

export const MetricCard = ({
  title,
  value,
  icon: Icon,
  trend,
  loading = false,
  timeframe,
}: {
  title: string;
  value: string;
  icon: any;
  trend?: number;
  loading?: boolean;
  timeframe?: string;
}) => (
  <Card className="relative overflow-hidden">
    <CardContent className="p-6">
      <div className="flex items-center justify-between">
        <div className="space-y-2">
          <div className="space-y-1">
            <p className="text-sm font-medium text-muted-foreground">{title}</p>
          </div>
          <div className="flex items-center gap-2">
            {loading ? (
              <div className="h-8 w-24 animate-pulse rounded bg-gray-200" />
            ) : (
              <>
                <p className="text-2xl font-bold">{value}</p>
                {trend !== undefined && trend != 0 && (
                  <span
                    className={`text-xs px-2 py-1 rounded-full ${
                      trend > 0
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    }`}
                  >
                    {trend > 0 ? "+" : ""}
                    {trend.toFixed(1)}%
                  </span>
                )}
              </>
            )}
          </div>
          {timeframe && (
            <p className="text-xs text-muted-foreground/60">{timeframe}</p>
          )}
        </div>
        <div className="h-12 w-12 rounded-full bg-emerald-50 flex items-center justify-center">
          <Icon className="h-6 w-6 text-emerald-600" />
        </div>
      </div>
    </CardContent>
  </Card>
);

export const useMetricSearchParams = () => {
  const [searchParams] = useSearchParams();
  const since = searchParams.get("metrics_since")
    ? startOfDay(new UTCDateMini(searchParams.get("metrics_since")!))
    : undefined;
  let until = searchParams.get("metrics_until")
    ? startOfDay(new UTCDateMini(searchParams.get("metrics_until")!))
    : undefined;

  if (since != undefined && until != undefined && isEqual(since, until))
    until = addDays(since, 1);

  return since && until
    ? {
        since,
        until,
      }
    : rangeLastDays(30);
};

const rangeLastDays = (lastDays: number) => {
  const startOfToday = startOfDay(new UTCDateMini());
  return {
    since: subDays(startOfToday, lastDays),
    until: addDays(startOfToday, 1),
  };
};

// since = subDays(new UTCDateMini(), 30); until = 'undefined' -> compute lastDays
export function TripletMetricsCard({
  lastDays,
}: {
  lastDays: number | undefined;
}) {
  let { since, until } = useMetricSearchParams();
  let label = "";
  const startOfToday = startOfDay(new UTCDateMini());
  if (lastDays !== undefined) {
    const r = rangeLastDays(lastDays);
    since = r.since;
    until = r.until;
  }
  const isTrendEnabled = until >= startOfToday;

  const spanDays = lastDays ?? differenceInDays(until, since);
  if (isTrendEnabled) {
    label = `Last ${spanDays} day${spanDays > 1 ? "s" : ""} vs previous`;
  }

  // Fetch data using our modified hooks
  const messages = useMetricComparison(
    "MessageCount",
    "MessageCount",
    since.toISOString(),
    until.toISOString()
  );
  const conversations = useMetricComparison(
    "ConversationLength",
    "Count",
    since.toISOString(),
    until.toISOString()
  );
  const users = useMetricComparison(
    "UniqueActiveUserCount",
    "Count",
    since.toISOString(),
    until.toISOString()
  );

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
      <MetricCard
        title="Messages"
        value={messages.current.toLocaleString()}
        icon={MessageSquare}
        trend={isTrendEnabled ? messages.trend : undefined}
        timeframe={label}
        loading={messages.status !== "succeeded"}
      />
      <MetricCard
        title="Conversations"
        value={conversations.current.toLocaleString()}
        icon={MessagesSquare}
        trend={isTrendEnabled ? conversations.trend : undefined}
        timeframe={label}
        loading={conversations.status !== "succeeded"}
      />
      <MetricCard
        title="Unique Users"
        value={users.current.toLocaleString()}
        icon={Users2}
        trend={isTrendEnabled ? users.trend : undefined}
        timeframe={label}
        loading={users.status !== "succeeded"}
      />
    </div>
  );
}
