import { useEffect } from "react";
import { BlockerFunction, useBlocker } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

const NavigationBlockerDialog = ({ isDirty }: { isDirty: boolean }) => {
  const doBlock: BlockerFunction = ({ currentLocation, nextLocation }) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname;
  };
  const blocker = useBlocker(doBlock);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        // NOTE(memben): I think we can remove, but leaving for now
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  return (
    <Dialog open={blocker.state === "blocked"} onOpenChange={blocker.reset}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>You have unsaved changes</DialogTitle>
          <DialogDescription>
            Are you sure you want to leave? Your unsaved changes may be lost.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex gap-2">
          <Button variant="outline" onClick={blocker.reset}>
            Cancel
          </Button>
          <Button variant="default" onClick={blocker.proceed}>
            Leave page
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NavigationBlockerDialog;
