import { NavProject } from "@/components/sidebar/NavProject";
import { ProjectSwitcher } from "@/components/sidebar/ProjectSwitcher";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar";
import { NavSecondary } from "./NavSecondary";
import { NavOrganization } from "./NavOrganization";
import { useParams } from "react-router-dom";
import { ScrollShadow } from "../scroll-shadow";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { projectId } = useParams();
  return (
    <Sidebar variant="inset" collapsible="icon" {...props}>
      <SidebarHeader>
        <ProjectSwitcher />
      </SidebarHeader>
      <SidebarContent>
        {projectId === undefined ? (
          <SidebarGroup className="flex h-full items-center justify-center ">
            <SidebarGroupLabel className="px-12 py-9 border-2 border-dashed cursor-not-allowed">
              No Project Selected
            </SidebarGroupLabel>
          </SidebarGroup>
        ) : (
          <ScrollShadow className="rounded-lg h-full">
            <NavProject />
          </ScrollShadow>
        )}
        <NavSecondary />
      </SidebarContent>
      <SidebarFooter>
        <NavOrganization />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
