import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

import { Label } from "./ui/label";
import { toast } from "sonner";
import { useState } from "react";
import { Download } from "lucide-react";
import { Switch } from "./ui/switch";

interface CsvConfig {
  seperator: "," | "|";
  isExcel: boolean;
}

interface ExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  count: number;
  config: CsvConfig;
  setConfig: (config: CsvConfig) => void;
  headers?: { key: string; name: string }[];
}

function downloadFile(name: string, content: string, type: string) {
  const blob = new Blob([content], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

function escapeCSVField(field: any, seperator: string): string {
  if (!["|", ","].includes(seperator))
    throw new Error("Invalid seperator, must be one of: |, ,");
  const value = String(field ?? ""); // Convert to string, handle null/undefined

  // If the field contains separators, quotes, or newlines, wrap it in quotes and escape existing quotes
  if (
    value.includes(seperator) ||
    value.includes('"') ||
    value.includes("\n")
  ) {
    return `"${value.replace(/"/g, '""').replace(/\n/g, "\\n")}"`;
  }

  return value;
}

const renderRow = (config: CsvConfig, values: string[]) =>
  values
    .map((value) => escapeCSVField(value, config.seperator))
    .join(config.seperator);

export default function ExportButton({
  label = "Export CSV",
  enabled,
  rows,
  columns,
  onSuccess,
  transformRows,
}: {
  label?: string;
  enabled: boolean;
  rows: any[];
  columns: { key: string; name: string }[];
  onSuccess?: () => void;
  transformRows?: (rows: any[]) => Promise<any[]>;
}) {
  const BOM = "\uFEFF";
  const [config, setConfig] = useState<CsvConfig>({
    seperator: "|",
    isExcel: false,
  });

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const handleExport = () => {
    const fn = async () => {
      try {
        setIsExportDialogOpen(false);
        const transform =
          transformRows || ((rows: any[]) => Promise.resolve(rows));
        const transformedRows = await transform(rows);
        console.log("Exporting rows:", transformRows);

        const textHeader = renderRow(
          config,
          columns?.map((header) => header.name)
        );
        const textRows = transformedRows.map((row) => {
          const values = columns?.map((header) => row[header.key]);
          return renderRow(config, values);
        });
        let textContent = [textHeader, ...textRows].join("\n");
        if (config.isExcel) textContent = BOM + textContent;
        downloadFile(
          `reviewed_companies_export_${new Date().toISOString()}.csv`,
          textContent,
          "text/csv"
        );
        return textRows;
      } catch (error) {
        console.error("Export failed:", error);
        onSuccess?.();
      }
    };
    toast.promise(fn(), {
      loading: "Exporting rows...",
      success: (data) => {
        const total = data?.length ?? 0;
        return `Export succeeded. Exported ${total} rows${total !== 1 ? "s" : ""}.`;
      },
      error: "Export failed. Please try again later.",
    });
  };

  return (
    <div>
      <Button
        variant="outline"
        size="sm"
        onClick={() => setIsExportDialogOpen(true)}
        disabled={!enabled || isExportDialogOpen}
      >
        <Download className="mr-2 h-4 w-4" />
        {label}
      </Button>

      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={() => setIsExportDialogOpen(false)}
        onConfirm={handleExport}
        count={rows.length}
        config={config}
        setConfig={setConfig}
        headers={columns}
      />
    </div>
  );
}

function ExportDialog({
  isOpen,
  onClose,
  onConfirm,
  count,
  config,
  setConfig,
  headers,
}: ExportDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-4xl">
        <DialogHeader>
          <DialogTitle>Export Selected Rows</DialogTitle>
          <DialogDescription>
            You are about to export {count} row{count !== 1 ? "s" : ""} to CSV
            with the following headers:
            <div className="mt-2 text-sm bg-gray-100 p-2 rounded font-mono">
              {renderRow(config, headers?.map((header) => header.name) ?? [])}
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-6 py-4">
          <div className="grid gap-2">
            <Label htmlFor="separator">Separator</Label>
            <p className="text-sm text-gray-500">
              Choose how your data columns will be separated. Use pipe for
              complex data that may contain commas.
            </p>
            <Select
              value={config.seperator}
              onValueChange={(value: "," | "|") =>
                setConfig({ ...config, seperator: value })
              }
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select separator" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="|">Pipe Seperated Values (|)</SelectItem>
                <SelectItem value=",">Comma Seperated Values (,)</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="grid gap-2">
            <Label htmlFor="excel">Excel Mode</Label>
            <p className="text-sm text-gray-500">
              Only enable this for use with Microsoft Excel. Other tools may get
              confused by special characters added for Excel compatibility.
            </p>
            <div className="flex items-center space-x-2">
              <Switch
                id="excel"
                checked={config.isExcel}
                onCheckedChange={(checked) =>
                  setConfig({ ...config, isExcel: checked })
                }
              />
              <Label htmlFor="excel" className="text-sm font-normal">
                {config.isExcel ? "Enabled" : "Disabled"}
              </Label>
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Export</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
