import { Table } from "@tanstack/react-table";
import { X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import { mimeTypeLabels } from "../dataproviders/FileType";
import { statusOptions } from "./SourcesDataTable";
import { DataTableFacetedFilter } from "../tables/data-table-faceted-filter";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  showStatus: boolean;
  onSearch: (value: string) => void;
}

export function DataTableToolbar<TData>({
  table,
  showStatus,
  onSearch,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
      <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:space-x-2">
        <Input
          placeholder="Search..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) => onSearch(event.target.value)}
          className="h-8 w-full sm:w-[250px] lg:w-[350px]"
        />
        <div className="flex flex-wrap gap-2">
          {showStatus && table.getColumn("status") && (
            <DataTableFacetedFilter
              column={table.getColumn("status")}
              title="Status"
              options={statusOptions.filter((option) =>
                table
                  .getPreFilteredRowModel()
                  .rows.some((row) => row.getValue("status") === option.value)
              )}
            />
          )}
          {table.getColumn("file_type") && (
            <DataTableFacetedFilter
              column={table.getColumn("file_type")}
              title="File Type"
              options={Object.entries(mimeTypeLabels)
                .filter(([value]) =>
                  table
                    .getPreFilteredRowModel()
                    .rows.some((row) => row.getValue("file_type") === value)
                )
                .map(([value, label]) => ({
                  label,
                  value,
                }))}
            />
          )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <X className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
