import { HTMLBeautifyOptions, html as htmlBeautify } from "js-beautify";
import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { PrismLight } from "react-syntax-highlighter";
import markdown from "react-syntax-highlighter/dist/esm/languages/prism/markdown";
import markup from "react-syntax-highlighter/dist/esm/languages/prism/markup";
import { prism as lightTheme } from "react-syntax-highlighter/dist/esm/styles/prism";

import { SourceWithContent } from "@/client";
import { Card } from "@/components/ui/card";

PrismLight.registerLanguage("markup", markup);
PrismLight.registerLanguage("markdown", markdown);

const DEFAULT_OPTIONS: HTMLBeautifyOptions = {
  indent_size: 1,
  indent_char: " ",
  max_preserve_newlines: 2,
  preserve_newlines: true,
  wrap_line_length: 80,
  unformatted: [],
  content_unformatted: ["pre", "textarea"],
  indent_inner_html: true,
  wrap_attributes: "force-expand-multiline",
};

function formatHTML(html: string): string {
  return htmlBeautify(html, DEFAULT_OPTIONS);
}

interface DiffViewerProps {
  oldSource: SourceWithContent | undefined;
  newSource: SourceWithContent | undefined;
}

interface TextDiffViewerProps {
  oldText: string;
  newText: string;
  fileType?: string;
  splitView?: boolean;
  hideSecondLineNumbers?: boolean;
  showDiffOnly?: boolean;
  format?: boolean;
}

const getLanguageFromFileType = (fileType?: string): string => {
  if (!fileType) return "markup";

  const extension = fileType.toLowerCase().replace(".", "");
  switch (extension) {
    case "md":
    case "markdown":
      return "markdown";
    case "html":
    case "xml":
    case "svg":
    case "markup":
      return "markup";
    default:
      return "markup";
  }
};

const highlightSyntax = (str: string, language: string) => {
  return (
    <PrismLight
      language={language}
      style={lightTheme}
      wrapLongLines={true}
      PreTag="span"
      customStyle={{
        background: "transparent",
        padding: 0,
        margin: 0,
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      {str}
    </PrismLight>
  );
};

export const TextDiffViewer = ({
  oldText,
  newText,
  fileType,
  splitView = true,
  hideSecondLineNumbers = false,
  showDiffOnly = true,
  format = true,
}: TextDiffViewerProps) => {
  const formattedOldText = React.useMemo(() => {
    return format ? formatHTML(oldText) : oldText;
  }, [oldText, fileType]);

  const formattedNewText = React.useMemo(() => {
    return format ? formatHTML(newText) : newText;
  }, [newText, fileType]);

  const language = React.useMemo(() => {
    return getLanguageFromFileType(fileType);
  }, [fileType]);

  const customStyles = {
    variables: {
      light: {
        diffViewerBackground: "#ffffff",
        addedBackground: "#e6ffec",
        removedBackground: "#ffebe9",
        wordAddedBackground: "#abf2bc",
        wordRemovedBackground: "#ffd7d5",
        addedGutterBackground: "#ccffd8",
        removedGutterBackground: "#ffdce0",
        gutterBackground: "#f6f8fa",
        codeFoldBackground: "#f1f8ff",
      },
    },
    diffContainer: {
      overflowX: "auto",
      maxWidth: "100%",
    },
    contentText: {
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
    gutter: {},
  };

  if (hideSecondLineNumbers) {
    customStyles.gutter = {
      "& + &": {
        display: "none",
      },
    };
  }

  return (
    <div className="w-full grid grid-cols-1">
      <div className="relative overflow-hidden border rounded-lg">
        <div className="overflow-x-auto">
          <ReactDiffViewer
            oldValue={formattedOldText}
            newValue={formattedNewText}
            splitView={splitView}
            compareMethod={DiffMethod.LINES}
            renderContent={(str) => highlightSyntax(str, language)}
            styles={customStyles}
            useDarkTheme={false}
            showDiffOnly={showDiffOnly}
          />
        </div>
      </div>
    </div>
  );
};

export function SourceDiffView({ oldSource, newSource }: DiffViewerProps) {
  if (!oldSource?.content?.text || !newSource?.content?.text) {
    return (
      <Card className="p-4">
        <p className="text-sm text-muted-foreground">
          Unable to display diff: missing content
        </p>
      </Card>
    );
  }

  return (
    <div className="w-full h-full flex flex-col mt-8">
      <div className="flex-1 min-h-0">
        <TextDiffViewer
          oldText={oldSource.content.text}
          newText={newSource.content.text}
          fileType={newSource.file_type}
        />
      </div>
    </div>
  );
}
