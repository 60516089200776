import { useOrganization } from "@clerk/clerk-react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "./ui/button";

interface MemberSelectProps {
  // Now accepts undefined to represent default state
  value: string | null | undefined;
  onValueChange: (value: string | null | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  disableDefault?: boolean;
}

export const MemberSelect = ({
  value,
  onValueChange,
  placeholder = "Assign to...",
  disabled = false,
  disableDefault = false,
}: MemberSelectProps) => {
  const { memberships, isLoaded } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 100,
    },
  });

  if (!isLoaded) {
    return <Skeleton className="h-10 w-full" />;
  }

  const formatMemberName = (membership: any) => {
    const { firstName, lastName } = membership.publicUserData;
    return `${firstName} ${lastName}`;
  };

  // Convert the three-state value to a select value
  const getSelectValue = (value: string | null | undefined) => {
    if (!disableDefault && value === undefined) return "default";
    if (value == null) return "unassigned";
    return value;
  };

  // Convert select value back to three-state value
  const handleValueChange = (newValue: string) => {
    if (newValue === "default") {
      onValueChange(undefined);
    } else if (newValue === "unassigned") {
      onValueChange(null);
    } else {
      onValueChange(newValue);
    }
  };

  // Helper to render the current selection display
  const renderSelection = () => {
    if (value === undefined) {
      return placeholder;
    }

    if (value === null) {
      return <span className="text-gray-500">No assignee</span>;
    }

    if (memberships?.data) {
      const member = memberships.data.find(
        (m) => m.publicUserData.userId === value
      );
      if (!member) return placeholder;

      return (
        <div className="flex items-center gap-2">
          <Avatar className="h-6 w-6">
            <AvatarImage src={member.publicUserData.imageUrl} />
            <AvatarFallback>
              {`${member.publicUserData.firstName?.[0] || ""}${
                member.publicUserData.lastName?.[0] || ""
              }`}
            </AvatarFallback>
          </Avatar>
          <span>{formatMemberName(member)}</span>
        </div>
      );
    }

    return placeholder;
  };

  return (
    <Select
      value={getSelectValue(value)}
      onValueChange={handleValueChange}
      disabled={disabled}
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder={placeholder}>{renderSelection()}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <ScrollArea className="h-[300px]">
          {disableDefault && (
            <SelectItem value="default">
              <span className="text-gray-500">{placeholder}</span>
            </SelectItem>
          )}
          <SelectItem value="unassigned">
            <span className="text-gray-500">No assignee</span>
          </SelectItem>
          {memberships?.data?.map((membership) => (
            <SelectItem
              key={membership.publicUserData.userId}
              value={membership.publicUserData.userId!}
              className="cursor-pointer"
            >
              <div className="flex items-center gap-2">
                <Avatar className="h-6 w-6">
                  <AvatarImage src={membership.publicUserData.imageUrl} />
                  <AvatarFallback>
                    {`${membership.publicUserData.firstName?.[0] || ""}${
                      membership.publicUserData.lastName?.[0] || ""
                    }`}
                  </AvatarFallback>
                </Avatar>
                <div className="flex flex-col">
                  <span>{formatMemberName(membership)}</span>
                  <span className="text-xs text-gray-500">
                    {membership.publicUserData.identifier}
                  </span>
                </div>
              </div>
            </SelectItem>
          ))}
          {memberships?.hasNextPage && (
            <Button
              onClick={() => memberships.fetchNext()}
              className="w-full"
              size="sm"
              variant={"default"}
            >
              Load more members
            </Button>
          )}
        </ScrollArea>
      </SelectContent>
    </Select>
  );
};

export default MemberSelect;
