import { useUser } from "@clerk/clerk-react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Settings2 } from "lucide-react";

export default function AdminToggle() {
  const { user } = useUser();

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-2">
          <Settings2 className="h-5 w-5 text-primary" />
          <CardTitle>Admin Settings</CardTitle>
        </div>
        <CardDescription>
          Toggle advanced administrative features
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <Label>Admin UI</Label>
            <p className="text-sm text-gray-500">
              Enable advanced administrative features and UI elements
            </p>
          </div>
          <Switch
            checked={(user?.unsafeMetadata?.adminUiVisible as boolean) || false}
            onCheckedChange={(checked) => {
              user?.update({
                unsafeMetadata: {
                  ...user.unsafeMetadata,
                  adminUiVisible: checked,
                },
              });
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}
