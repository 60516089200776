import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
} from "@/components/ui/sidebar";
import SidebarNavItem from "./SidebarNavItem";
import { useLocalNav } from "./hooks";

export function NavProject() {
  const localNav = useLocalNav();

  return (
    <>
      <SidebarGroup>
        <SidebarGroupLabel>Insights</SidebarGroupLabel>
        <SidebarMenu>
          {localNav.insights.map((item) => (
            <SidebarNavItem key={item.name} {...item} />
          ))}
        </SidebarMenu>
      </SidebarGroup>
      <SidebarGroup>
        <SidebarGroupLabel>Chatbot</SidebarGroupLabel>
        <SidebarMenu>
          {localNav.chatbot.map((item) => (
            <SidebarNavItem key={item.name} {...item} />
          ))}
        </SidebarMenu>
      </SidebarGroup>
      <SidebarGroup>
        <SidebarGroupLabel>Project</SidebarGroupLabel>
        <SidebarMenu>
          {localNav.misc.map((item) => (
            <SidebarNavItem key={item.name} {...item} />
          ))}
        </SidebarMenu>
      </SidebarGroup>
    </>
  );
}
