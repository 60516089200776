import { useParams } from "react-router-dom";

import {
  useAppDispatch,
  useCurrentProjectBreadcrumbs,
  useTestSuites,
} from "@/hooks";
import { TestSuiteSchema } from "@/lib/schemas";
import { updateTestSuite } from "@/slices/evaluations";

import TestSuiteForm, { TestSuiteSubmit } from "./TestSuiteForm";
import Spinner from "../Loading";
import NavigationHeader from "../sidebar/NavigationHeader";

function TestSuiteEditorPage() {
  const { projectId, testSuiteId } = useParams();
  const dispatch = useAppDispatch();

  const { testSuites, status, errorMessage } = useTestSuites({
    project_id: Number(projectId),
  });
  const testSuite = testSuites.find((testSuite) => testSuite.id == testSuiteId);

  if (testSuite == undefined) return <p>Test Suite not found</p>;

  const initialValues = TestSuiteSchema.parse(testSuite);

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: "./../..", label: "Test Suites" },
    { href: "./..", label: testSuite?.name ?? "Test Suite" },
    { label: "Edit" },
  ];

  if (status == "loading") return <Spinner />;
  if (status == "failed")
    return <p className="text-red-500">Error: {errorMessage}</p>;

  const handleCreateTestSuite = async (values: TestSuiteSubmit) =>
    await dispatch(updateTestSuite(values));
  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="w-full flex justify-center">
        <TestSuiteForm
          initialValues={initialValues}
          handleSubmitAsync={handleCreateTestSuite}
        />
      </div>
    </>
  );
}

export default TestSuiteEditorPage;
