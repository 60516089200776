import { SourceWithContent } from "@/client";
import { Card } from "../ui/card";
import { TextDiffViewer } from "./SourceViewDiff";
import { Check, Copy } from "lucide-react";
import { Button } from "../ui/button";
import { useState } from "react";

export const SourceContent = ({ source }: { source: SourceWithContent }) => {
  const [copied, setCopied] = useState(false);
  const textContent = source.content?.text;

  if (textContent === undefined) {
    console.error(
      "We need to fetch the file content from the server using text_uri"
    );
    return null;
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(textContent);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        className="absolute right-4 top-2 shadow-lg z-10"
        onClick={() => {
          void handleCopy();
        }}
      >
        {copied ? (
          <>
            <Check className="h-4 w-4 mr-2" />
            Copied
          </>
        ) : (
          <>
            <Copy className="h-4 w-4 mr-2" />
            Copy
          </>
        )}
      </Button>
      <Card className="w-full max-w-4xl mx-auto my-4 overflow-hidden">
        <TextDiffViewer
          oldText={textContent}
          newText={textContent}
          fileType={source.file_type}
          splitView={false}
          hideSecondLineNumbers={true}
          showDiffOnly={false}
          format={false}
        />
      </Card>
    </>
  );
};
