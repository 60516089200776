import { formatDistanceToNowStrict } from "date-fns";
import { Loader2, FolderClock } from "lucide-react";

import { DataProviderSnapshot } from "@/client";
import { Button } from "@/components/ui/button";
import { usePollPendingSnapshots } from "@/hooks";
import { nullableStringHandler, useQueryState } from "@/hooks/useQueryState";
import { cn } from "@/lib/utils";

import { SnapshotDiff } from "./SnapshotDiff";
import { SnapshotStatus } from "./SnapshotStatus";
import SourcesList, { EmptySourcesState } from "../sources/SourcesList";

const formatDuration = (start: Date, end: Date) => {
  const durationMs = end.getTime() - start.getTime();
  const seconds = Math.floor(durationMs / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

interface TimelineEntryProps {
  snapshot: DataProviderSnapshot;
  previousSnapshotId: string | undefined;
  isSelected: boolean;
  onSelect: () => void;
  projectId: number;
  providerId: string;
}

const TimelineEntry = ({
  snapshot,
  previousSnapshotId,
  isSelected,
  onSelect,
  projectId,
  providerId,
}: TimelineEntryProps) => {
  const dateFormatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center gap-3">
        <Button
          variant="outline"
          size="icon"
          className={cn(
            "h-6 w-6 rounded-full flex-shrink-0",
            isSelected && "ring-2 ring-blue-500"
          )}
          onClick={onSelect}
        >
          <FolderClock className="h-3 w-3" />
        </Button>

        <div className="flex items-center gap-2 text-xs text-gray-500">
          <span>{dateFormatter.format(new Date(snapshot.start_time))}</span>
          <span>·</span>
          <span>
            {formatDistanceToNowStrict(snapshot.start_time, {
              addSuffix: true,
            })}
          </span>
        </div>
      </div>

      <div
        className={cn(
          "ml-9 mt-2 flex-1 rounded-lg border bg-card p-3 cursor-pointer transition-colors",
          isSelected && "ring-2 ring-blue-500",
          !isSelected && "hover:bg-gray-50"
        )}
        onClick={onSelect}
      >
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <SnapshotStatus status={snapshot.status} />
          </div>
          {snapshot.end_time && (
            <span className="text-xs text-gray-500">
              in{" "}
              {formatDuration(
                new Date(snapshot.start_time),
                new Date(snapshot.end_time)
              )}
            </span>
          )}
        </div>

        {isSelected && (
          <div
            className="mt-3 border-t pt-3 cursor-auto"
            onClick={(e) => e.stopPropagation()}
          >
            {previousSnapshotId ? (
              <SnapshotDiff
                projectId={projectId}
                providerId={providerId}
                currentSnapshotId={snapshot.id}
                previousSnapshotId={previousSnapshotId}
              />
            ) : (
              <SourcesList
                projectId={projectId}
                providerId={providerId}
                snapshotId={snapshot.id}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export function SnapshotTimeline({
  projectId,
  providerId,
}: {
  projectId: number;
  providerId: string;
}) {
  const { snapshots, status } = usePollPendingSnapshots({
    projectId,
    dataProviderId: providerId,
  });

  const [selectedSnapshotId, setSelectedSnapshotId] = useQueryState<
    string | null
  >("selectedSnapshotId", null, nullableStringHandler);

  if (status === "pending") {
    return (
      <div className="flex justify-center py-6">
        <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
      </div>
    );
  }

  if (!snapshots?.length) {
    return <EmptySourcesState providerId={providerId} />;
  }

  return (
    <div className="space-y-6">
      <div className="relative">
        <div className="absolute left-3 top-0 bottom-0 w-px bg-gray-200" />
        <div className="space-y-6">
          {snapshots.map((snapshot, idx) => {
            const previousSnapshot = snapshots[idx + 1]?.id;
            const isSelected = selectedSnapshotId === snapshot.id;

            return (
              <TimelineEntry
                key={snapshot.id}
                snapshot={snapshot}
                previousSnapshotId={previousSnapshot}
                isSelected={isSelected}
                onSelect={() => {
                  if (isSelected) {
                    setSelectedSnapshotId(null);
                  } else {
                    setSelectedSnapshotId(snapshot.id);
                  }
                }}
                projectId={projectId}
                providerId={providerId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
