import { useQuery } from "@tanstack/react-query";
import {
  Loader2,
  GitCompare,
  FileText,
  BookOpen,
  Pickaxe,
  BookOpenText,
} from "lucide-react";
import { useParams } from "react-router-dom";

import { getSourceOptions } from "@/client/@tanstack/react-query.gen";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { createEnumHandler, useQueryState } from "@/hooks/useQueryState";

import { mimeTypeLabels } from "../dataproviders/FileType";
import SourceMarkdown from "./SourceMarkdown";
import { formatFileSize } from "./SourcesDataTable";
import { SourceDiffView } from "./SourceViewDiff";
import { SourceView } from "./SourceView";
import { Feature } from "@/features";
import { SourceContent } from "./SourceContent";

interface SourceViewDialogProps {
  sourceId: number | null;
  previousSourceId?: number | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function SourceViewDialog({
  sourceId,
  previousSourceId,
  open,
  onOpenChange,
}: SourceViewDialogProps) {
  const [activeTab, setActiveTab] = useQueryState(
    "tab",
    "source",
    createEnumHandler(["markdown", "markdown-raw", "content", "source", "diff"])
  );

  const { projectId } = useParams();

  const { data: source, isLoading: isLoadingCurrent } = useQuery({
    ...getSourceOptions({
      path: {
        project_id: Number(projectId),
        source_id: sourceId!,
      },
    }),
    enabled: !!sourceId && open,
  });

  const { data: previousSource, isLoading: isLoadingPrevious } = useQuery({
    ...getSourceOptions({
      path: {
        project_id: Number(projectId),
        source_id: previousSourceId!,
      },
    }),
    enabled: !!previousSourceId && open,
  });

  const isLoading = isLoadingCurrent || (previousSourceId && isLoadingPrevious);
  const showDiff = previousSourceId;

  // const statusColors: Record<DiffStatus, string> = {
  //   added: "bg-green-100 text-green-800",
  //   removed: "bg-red-100 text-red-800",
  //   changed: "bg-yellow-100 text-yellow-800",
  //   unchanged: "bg-gray-100 text-gray-800",
  // };

  if (!source) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-7xl h-[90vh]"
        // https://github.com/shadcn-ui/ui/discussions/2307
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <DialogTitle className="flex items-center gap-2">
                <FileText className="h-5 w-5" />
                {source.name}
              </DialogTitle>
              <div className="flex items-center gap-2">
                <Badge variant="secondary">
                  {mimeTypeLabels[source.file_type]}
                </Badge>
                {/* {source.status && (
                  <Badge className={statusColors[source.status]}>
                    {source.status}
                  </Badge>
                )} */}
                <Badge variant="outline">
                  {formatFileSize(source.file_bytesize)}
                </Badge>
              </div>
            </div>
          </div>
        </DialogHeader>
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
          </div>
        ) : (
          <div className="h-full overflow-hidden">
            <Tabs
              value={activeTab}
              onValueChange={(v) => setActiveTab(v)}
              className="w-full"
            >
              <ScrollArea className="h-[calc(90vh-6rem)] relative">
                <div className="absolute left-1/2 transform -translate-x-1/2 top-2 z-50">
                  <div>
                    <Feature flag="source-view-tabs">
                      <TabsList>
                        <TabsTrigger value="markdown" className="gap-2">
                          <BookOpenText className="h-4 w-4" />
                          Markdown
                        </TabsTrigger>
                        <TabsTrigger value="markdown-raw" className="gap-2">
                          <BookOpen className="h-4 w-4" />
                          Raw
                        </TabsTrigger>
                        <TabsTrigger value="content" className="gap-2">
                          <Pickaxe className="h-4 w-4" />
                          Extracted
                        </TabsTrigger>
                        <TabsTrigger value="source" className="gap-2">
                          <FileText className="h-4 w-4" />
                          Source
                        </TabsTrigger>
                        {showDiff && (
                          <TabsTrigger value="diff" className="gap-2">
                            <GitCompare className="h-4 w-4" />
                            Changes
                          </TabsTrigger>
                        )}
                      </TabsList>
                    </Feature>
                  </div>
                </div>
                <div className="mt-16">
                  <TabsContent value="markdown" className="m-2">
                    <SourceMarkdown source={source} />
                  </TabsContent>
                  <TabsContent value="markdown-raw" className="m-2">
                    <SourceMarkdown source={source} raw />
                  </TabsContent>
                  <TabsContent value="content" className="m-2">
                    <SourceContent source={source} />
                  </TabsContent>
                  <TabsContent value="source" className="m-2">
                    <SourceView source={source} />
                  </TabsContent>
                  <TabsContent value="diff" className="m-2">
                    <SourceDiffView
                      oldSource={previousSource}
                      newSource={source}
                    />
                  </TabsContent>
                </div>
              </ScrollArea>
            </Tabs>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
