import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Settings2,
  Palette,
  Rocket,
  Save,
  X,
  LucideMessageSquare,
  Loader2,
  Code,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import {
  getAliasOptions,
  getFrameOptions,
  updateFrameMutation,
} from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { toast } from "sonner";
import { createEnumHandler, useQueryState } from "@/hooks/useQueryState";
import { FrameSettingsSchema } from "@/lib/schemas";
import { getErrorMessages } from "@/lib/utils";

import { FrameEditorProvider } from "./FrameEditorContext";
import { GeneralSettings } from "./GeneralSettings";
import IntegrationTab from "./IntegrationTab";
import LauncherSettings from "./LauncherSettings";
import LocalizationSettings from "./LocalizationSettings";
import { ThemeSettings } from "./ThemesSettings";
import { AxiosErrorBox } from "../Error";
import { PreviewPanel } from "./PreviewPanel";
import NavigationBlockerDialog from "../NavigationBlockerDialog";
import FrameEditorSelector from "./FrameSelectorPage";
import NavigationHeader from "../sidebar/NavigationHeader";
import { useCurrentProjectBreadcrumbs } from "@/hooks";

export type FrameSettingsValues = z.infer<typeof FrameSettingsSchema>;

const TabIcon = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center gap-2">{children}</div>
);

const FrameSettingsEditor = () => {
  const { projectId, frameId } = useParams();

  const [tab, setTab] = useQueryState(
    "tab",
    "localization",
    createEnumHandler(["general", "theme", "localization", "launcher", "code"])
  );

  const { data: frame, isLoading: isFrameLoading } = useQuery({
    ...getFrameOptions({
      path: {
        project_id: Number(projectId),
        frame_id: frameId!,
      },
    }),
  });

  const { data: deploymentAlias, error: aliasError } = useQuery({
    ...getAliasOptions({
      path: {
        project_id: Number(projectId),
        alias_id: frame?.alias_id ?? "",
      },
    }),
    enabled: !!frame,
  });

  useEffect(() => {
    window.$crisp.push(["do", "chat:hide"]);
    return () => {
      window.$crisp.push(["do", "chat:show"]);
    };
  }, []);

  const queryClient = useQueryClient();

  const updateFrame = useMutation({
    ...updateFrameMutation(),
    onSuccess: () => {
      toast("Settings updated", {
        description: "Applying changes may take a moment.",
      });
      void queryClient.invalidateQueries({
        queryKey: getFrameOptions({
          path: {
            project_id: Number(projectId),
            frame_id: frameId!,
          },
        }).queryKey,
      });
    },
    onError: (error) => {
      toast.error("Updating settings failed", {
        description: getErrorMessages(error).join(", "),
      });
    },
  });

  const form = useForm<FrameSettingsValues>({
    resolver: zodResolver(FrameSettingsSchema),
    mode: "onChange",
    defaultValues: undefined,
  });

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (frame?.settings && !isInitialized) {
      form.reset(structuredClone(frame.settings));
      setIsInitialized(true);
    }
  }, [frame, form, isInitialized]);

  function onSubmit(values: FrameSettingsValues) {
    if (!frame) {
      console.error("No frame data available");
      return;
    }

    updateFrame.mutate({
      body: {
        name: frame.name,
        description: frame.description,
        alias_id: frame.alias_id,
        user_pool_id: frame.user_pool_id,
        settings: values,
      },
      path: {
        project_id: Number(projectId),
        frame_id: frame.id,
      },
    });
    // NOTE(memben): Reset to mark as not dirty
    form.reset(values, { keepValues: true });
  }

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: `./..`, label: "Integrations" },
    { label: frame?.name ?? "Integration" },
  ];

  if (aliasError) {
    return <AxiosErrorBox error={aliasError} />;
  }

  if (isFrameLoading || !frame) return <FrameEditorSelector />;

  if (form.formState === undefined) return <FrameEditorSelector />;

  const isPreviewInteractive =
    deploymentAlias?.access_control_list.gate.challenge === "none";

  const isSubmitting = updateFrame.isPending;
  const isFormValid = form.formState.isValid;
  const isSaveDisabled = isSubmitting || !isFormValid;

  const tabsTriggerClassName =
    "rounded-md px-2 md:px-4 py-2 transition-all data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm text-xs md:text-md";
  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <FrameEditorProvider frame={frame}>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full lg:w-1/2">
            <main className="mx-1 max-w-3xl sm:mx-auto">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-6"
                >
                  <Tabs value={tab} onValueChange={setTab}>
                    <div className="flex justify-center">
                      <TabsList className="rounded-lg bg-gray-100/50 p-1">
                        <TabsTrigger
                          value="general"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Settings2 className="hidden h-4 w-4 md:block" />
                            <span>General</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="theme"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Palette className="hidden h-4 w-4 md:block" />
                            <span>Theme</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="localization"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <LucideMessageSquare className="hidden h-4 w-4 md:block" />
                            <span>Messages</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="launcher"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Rocket className="hidden h-4 w-4 md:block" />
                            <span>Launcher</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="code"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Code className="hidden h-4 w-4 md:block" />
                            <span>Code</span>
                          </TabIcon>
                        </TabsTrigger>
                      </TabsList>
                    </div>

                    <div className="mt-6">
                      <TabsContent
                        value="general"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <GeneralSettings />
                      </TabsContent>
                      <TabsContent
                        value="theme"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <ThemeSettings />
                      </TabsContent>
                      <TabsContent
                        value="localization"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <LocalizationSettings />
                      </TabsContent>
                      <TabsContent
                        value="launcher"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <LauncherSettings />
                      </TabsContent>
                      <TabsContent
                        value="code"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <IntegrationTab frame={frame} />
                      </TabsContent>
                    </div>
                  </Tabs>
                  <div className="flex w-full justify-end gap-3 border-t border-gray-100 p-6">
                    <Button
                      variant="outline"
                      type="reset"
                      size="sm"
                      className="text-gray-600 hover:text-gray-700"
                      onClick={() =>
                        form.reset(structuredClone(frame.settings))
                      }
                      disabled={isSubmitting}
                    >
                      <X className="mr-2 h-4 w-4" />
                      Cancel
                    </Button>
                    <TooltipProvider>
                      <Tooltip delayDuration={200}>
                        <TooltipTrigger asChild>
                          <Button
                            type="submit"
                            size="sm"
                            variant="default"
                            className="disabled:cursor-not-allowed disabled:opacity-50"
                            disabled={isSaveDisabled}
                          >
                            {isSubmitting ? (
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            ) : (
                              <Save className="mr-2 h-4 w-4" />
                            )}
                            {isSubmitting ? "Saving..." : "Save Changes"}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {isFormValid ? (
                            <div className="text-sm">
                              Applying changes may take a moment.
                            </div>
                          ) : (
                            <div className="text-sm text-red-500">
                              Please fix form errors before saving
                            </div>
                          )}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </form>
              </Form>
            </main>
          </div>
          <PreviewPanel
            frameId={isPreviewInteractive ? frame.id : undefined}
            frameSettings={form.getValues()}
            isFormValid={isFormValid}
          />
        </div>
        <NavigationBlockerDialog isDirty={form?.formState.isDirty} />
      </FrameEditorProvider>
    </>
  );
};

export default FrameSettingsEditor;
