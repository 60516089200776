import React from "react";
import { Skeleton } from "./ui/skeleton";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export default function PageHeader({
  title,
  subtitle,
  children,
}: PageHeaderProps) {
  return (
    <header>
      <div className="flex items-center justify-between space-x-2">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          {title}
        </h1>
        {children}
      </div>
      {subtitle && (
        <span className="text-base text-muted-foreground">{subtitle}</span>
      )}
    </header>
  );
}

interface PageHeaderSkeletonProps {
  hasSubtitle?: boolean;
  hasChildren?: boolean;
}

export function PageHeaderSkeleton({
  hasSubtitle = false,
  hasChildren = false,
}: PageHeaderSkeletonProps) {
  return (
    <header>
      <div className="flex items-center justify-between space-x-2">
        <Skeleton className="h-9 w-[200px]" />
        {hasChildren && <Skeleton className="h-9 w-[100px]" />}
      </div>
      {hasSubtitle && <Skeleton className="mt-1 h-5 w-[300px]" />}
    </header>
  );
}
