import { useUser } from "@clerk/clerk-react";

export default function useFlagEnabled(_: string): boolean {
  const { isLoaded, user } = useUser();
  const isAdmin = !!(
    isLoaded &&
    user?.publicMetadata?.sys_admin &&
    user?.unsafeMetadata?.adminUiVisible
  );
  // we can later differentiate different org owners, super admin, ...
  return isAdmin;
}

export function Feature({
  flag,
  children,
}: {
  flag: string;
  children: React.ReactNode;
}) {
  const isEnabled = useFlagEnabled(flag);
  return isEnabled ? <>{children}</> : <></>;
}
