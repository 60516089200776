import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Edit2, Trash2, MoreVertical } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

import {
  deleteDataproviderMutation,
  getDataproviderOptions,
  listDataprovidersOptions,
  updateDataproviderMutation,
} from "@/client/@tanstack/react-query.gen";
import { DataProviderRead } from "@/client/types.gen";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import DataProviderForm, { DataProviderValues } from "./DataProviderForm";
import { Button } from "../ui/button";
import useFlagEnabled from "@/features";

interface ProviderDropdownProps {
  provider: DataProviderRead;
  onDelete?: () => void;
}

export function ProviderDropdown({
  provider,
  onDelete = () => {},
}: ProviderDropdownProps) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const queryClient = useQueryClient();

  const flagDelete = useFlagEnabled("delete-data-provider");
  const flagEdit = useFlagEnabled("edit-data-provider");

  const updateDataProvider = useMutation({
    ...updateDataproviderMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "Data provider updated successfully",
      });
      void queryClient.invalidateQueries({
        queryKey: listDataprovidersOptions({
          path: { project_id: provider.project_id },
        }).queryKey,
      });
      void queryClient.invalidateQueries({
        queryKey: getDataproviderOptions({
          path: {
            project_id: provider.project_id,
            data_provider_id: provider.id,
          },
        }).queryKey,
      });
      setShowEditDialog(false);
      setShowDropdownMenu(false);
    },
  });

  const deleteProvider = useMutation({
    ...deleteDataproviderMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "Provider deleted successfully",
      });
      void queryClient.invalidateQueries({
        queryKey: listDataprovidersOptions({
          path: { project_id: provider.project_id },
        }).queryKey,
      });
      setShowDropdownMenu(false);
      onDelete();
    },
  });

  const handleSubmit = (values: DataProviderValues) => {
    updateDataProvider.mutate({
      path: {
        project_id: provider.project_id,
        data_provider_id: provider.id,
      },
      body: { ...values, id: provider.id },
    });
  };

  return (
    <>
      <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
        {/* NOTE(memben): combination of showDropdownMenu and model={false} fixed it, https://github.com/radix-ui/primitives/issues/1836 */}
        <DropdownMenu
          open={showDropdownMenu}
          onOpenChange={setShowDropdownMenu}
          modal={false}
        >
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="text-gray-500" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onSelect={(e) => {
                e.preventDefault();
                setShowEditDialog(true);
              }}
              disabled={!flagEdit}
            >
              <Edit2 className="mr-2 h-4 w-4" />
              Edit
            </DropdownMenuItem>

            <DropdownMenuItem
              className="text-red-600 focus:text-red-600"
              onSelect={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this provider?"
                  )
                ) {
                  deleteProvider.mutate({
                    path: {
                      project_id: provider.project_id,
                      data_provider_id: provider.id,
                    },
                  });
                }
              }}
              disabled={!flagDelete}
            >
              <Trash2 className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        {/* NOTE/TODO(memben): scrolling inside the container does not work :(, gave up for now -> use the sidebar */}
        <DialogContent>
          <DataProviderForm
            // @ts-expect-error TODO(memben): fix pydantic Crawler parsing / passing between frontend - backend - crawler, unify format
            initialData={provider}
            onSubmit={handleSubmit}
            isSubmitting={updateDataProvider.isPending}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
