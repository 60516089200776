import { FrameSettings_Input } from "@/client";

export function urlSafeEncode(input: object): string {
  const jsonString = JSON.stringify(input);
  // Convert to base64 using btoa
  const base64 = btoa(encodeURIComponent(jsonString));
  // URL-safe
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

export interface LauncherState {
  isShow: boolean;
  isOpen: boolean;
  lastOpen: number;
  openedOnce: boolean;
  isPreviewVisible: boolean;
}

export interface StateJson {
  frameSettings: FrameSettings_Input;
  launcherState: LauncherState;
  theme?: "auto" | "light" | "dark";
  locale?: string;
  apiBase?: string;
}
