import { useParams } from "react-router-dom";
import { useProductionAliases } from "@/hooks";
import { MultiSelect } from "@/components/multi-select";
import { useQueryState, stringArrayHandler } from "@/hooks/useQueryState";
interface QueryDeploymentAliasPickerProps {
  disabled?: boolean;
}

function QueryDeploymentAliasPicker({
  disabled = false,
}: QueryDeploymentAliasPickerProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const { productionAliases, status } = useProductionAliases({
    project_id: projectId!,
  });

  const [aliasIds, setAliasIds] = useQueryState<string[]>(
    "alias",
    [], // empty evalutes to all aliases
    stringArrayHandler
  );

  const aliasOptions = productionAliases.map((alias) => ({
    label: `via ${alias.description}`,
    value: alias.id,
  }));

  return (
    <div>
      <MultiSelect
        options={aliasOptions}
        onValueChange={setAliasIds}
        defaultValue={aliasIds}
        placeholder="Across All Aliases"
        className="w-full"
        disabled={
          status !== "succeeded" || productionAliases.length === 0 || disabled
        }
        modalPopover={false}
      />
    </div>
  );
}

export default QueryDeploymentAliasPicker;
