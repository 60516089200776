import { UTCDateMini } from "@date-fns/utc";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  subDays,
  subMonths,
  addDays,
  differenceInDays,
} from "date-fns";

import { useMetric } from "../hooks";

export const useMetricComparison = (
  metricName: string,
  valueKey: string,
  since: string,
  until: string
) => {
  const current = {
    metricName,
    interval: "1d",
    since: since,
    until: until,
  };

  const days = differenceInDays(new Date(until), new Date(since));

  const previous = {
    metricName,
    interval: "1d",
    since: startOfDay(subDays(since, days)).toISOString(),
    until: startOfDay(since).toISOString(),
  };

  const { metric: currentMetric, status: currentStatus } = useMetric(current);
  const { metric: previousMetric, status: previousStatus } =
    useMetric(previous);

  if (currentStatus !== "succeeded" || previousStatus !== "succeeded") {
    return { current: 0, previous: 0, trend: 0, status: "loading" };
  }

  // Special handling for UniquePageVisitorCount which has a different data structure
  if (metricName === "UniquePageVisitorCount") {
    const currentValue: number = currentMetric.result.values.reduce(
      (acc: number, v: number) => acc + v,
      0
    );
    const previousValue: number = previousMetric.result.values.reduce(
      (acc: number, v: number) => acc + v,
      0
    );

    const trend =
      previousValue === 0
        ? currentValue === 0
          ? 0
          : 100
        : ((currentValue - previousValue) / previousValue) * 100;

    return {
      current: currentValue,
      previous: previousValue,
      trend,
      status: "succeeded",
    };
  }

  // Standard handling for other metrics
  const currentRows = getRowsFromMetric(currentMetric);
  const previousRows = getRowsFromMetric(previousMetric);

  const currentValue = currentRows.reduce(
    (sum: number, row: Record<string, number | string>) =>
      sum + ((row[valueKey] as number) || 0),
    0
  );
  const previousValue = previousRows.reduce(
    (sum: number, row: Record<string, number | string>) =>
      sum + ((row[valueKey] as number) || 0),
    0
  );

  const trend =
    previousValue === 0
      ? currentValue === 0
        ? 0
        : 100
      : ((currentValue - previousValue) / previousValue) * 100;

  return {
    current: currentValue,
    previous: previousValue,
    trend,
    status: "succeeded",
  };
};

// Helper functions
export const getRowsFromMetric = (metric: any) => {
  return metric.data.map(
    (row: string | number[]) =>
      metric.columns.reduce((o: any, k: string, i: number) => {
        return { ...o, [k]: row[i] };
      }, {}) as Record<string, number | string>
  );
};

// Hook for Today's Message Count
export function useMessageCountToday() {
  const query = {
    metricName: "MessageCount",
    interval: "1d",
    since: startOfDay(new UTCDateMini()).toISOString(),
    until: addDays(startOfDay(new UTCDateMini()), 1).toISOString(),
  };

  const { metric, status } = useMetric(query);

  if (status !== "succeeded") {
    return { count: 0, status };
  }

  const rows = getRowsFromMetric(metric);
  const todayCount = rows.reduce(
    (sum: number, { MessageCount }: { MessageCount: number }) =>
      sum + (MessageCount || 0),
    0
  );

  return { count: todayCount, status };
}

// Hook for Total Conversations Count
export function useConversationCount(lastDays: number = 90) {
  const query = {
    metricName: "ConversationLength",
    interval: "1d",
    since: startOfDay(subDays(new UTCDateMini(), lastDays)).toISOString(),
    until: endOfDay(new UTCDateMini()).toISOString(),
  };

  const { metric, status } = useMetric(query);

  if (status !== "succeeded") {
    return { count: 0, status };
  }

  const rows = getRowsFromMetric(metric);
  const currentMonth = startOfMonth(new UTCDateMini());

  const monthlyCount = rows
    .filter(
      ({ StartTimestamp }: { StartTimestamp: string }) =>
        new UTCDateMini(StartTimestamp) >= currentMonth
    )
    .reduce(
      (sum: number, { Count }: { Count: number }) => sum + (Count || 0),
      0
    );

  return { count: monthlyCount, status };
}

// Hook for Month-to-Date Message Count
export function useMessageCountMTD() {
  const query = {
    metricName: "MessageCount",
    interval: "1d",
    since: startOfMonth(subMonths(new UTCDateMini(), 1)).toISOString(),
    until: endOfDay(new UTCDateMini()).toISOString(),
  };

  const { metric, status } = useMetric(query);

  if (status !== "succeeded") {
    return { count: 0, previousMonth: 0, percentageChange: 0, status };
  }

  const rows = getRowsFromMetric(metric);
  const currentMonth = startOfMonth(new UTCDateMini());
  const lastMonth = startOfMonth(subMonths(new UTCDateMini(), 1));

  const currentMTD = rows
    .filter(
      ({ StartTimestamp }: { StartTimestamp: string }) =>
        new UTCDateMini(StartTimestamp) >= currentMonth
    )
    .reduce(
      (sum: number, { MessageCount }: { MessageCount: number }) =>
        sum + (MessageCount || 0),
      0
    );

  const previousMTD = rows
    .filter(({ StartTimestamp }: { StartTimestamp: string }) => {
      const date = new UTCDateMini(StartTimestamp);
      return date >= lastMonth && date < currentMonth;
    })
    .reduce(
      (sum: number, { MessageCount }: { MessageCount: number }) =>
        sum + (MessageCount || 0),
      0
    );

  const percentageChange =
    previousMTD === 0 ? 100 : ((currentMTD - previousMTD) / previousMTD) * 100;

  return {
    count: currentMTD,
    previousMonth: previousMTD,
    percentageChange,
    status,
  };
}
