import { getSourceFile } from "@/client-custom/services";
import { getSourceFileQueryKey } from "@/client/@tanstack/react-query.gen";
import { GetSourceFileData } from "@/client/types.gen";
import { Options } from "@hey-api/client-axios";
import { queryOptions } from "@tanstack/react-query";

export const getSourceFileOptions = (options: Options<GetSourceFileData>) => {
  return queryOptions({
    queryFn: async ({ queryKey }) => {
      const { data } = await getSourceFile({
        ...options,
        ...queryKey[0],
        throwOnError: true,
      });
      return data;
    },
    queryKey: getSourceFileQueryKey(options),
  });
};
