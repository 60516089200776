import { CubeTransparentIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Card } from "@tremor/react";

import {
  Check,
  CheckOutput,
  ClassifyAsCheck,
  ClassifyAsCheckOutput,
  ComparatorToName,
  Conversation,
  MatchesCheck,
  NotMatchesCheck,
  SemanticallyEquivalentCheck,
  SemnanticallyEquivalentCheckOutput,
} from "@/structs";

function SemanticallyEquivalentView({
  check,
  checkOutput,
}: {
  check: SemanticallyEquivalentCheck;
  checkOutput?: SemnanticallyEquivalentCheckOutput;
}) {
  return (
    <Card className="mt-2 font-sans">
      <h3 className="text-xl font-semibold ">
        {ComparatorToName[check.comparator]} Check
      </h3>
      <div className="mt-2 flex-1 overflow-y-auto bg-white">
        <div className="mb-2">
          <span className="font-bold">Expect</span>
          <p className="prose whitespace-pre-line text-sm font-medium">
            {check.expected}
          </p>
        </div>
        <div className="flex flex-row">
          {checkOutput && (
            <div className="w-1/2">
              <span className="font-bold">Output Similarity</span>
              {checkOutput && (
                <p className="prose">
                  {(checkOutput.similarity * 100).toFixed(2)}%
                </p>
              )}
            </div>
          )}
          <div className="w-1/2">
            <span className="font-bold">Threshold</span>
            <p className="prose">{check.threshold * 100}%</p>
          </div>
        </div>
      </div>
    </Card>
  );
}

function MatchesView({
  check,
  isPassed,
}: {
  check: MatchesCheck | NotMatchesCheck;
  isPassed: boolean | null | undefined;
}) {
  return (
    <Card className="mt-2 font-sans">
      <h3 className="text-xl font-semibold">
        {ComparatorToName[check.comparator]} Check
      </h3>
      <div className="space-y-2 bg-white text-sm">
        <div>
          <span className="block font-bold">
            {check.comparator === "MATCHES" ? "Pattern" : "Not Pattern"}
          </span>
          <pre className="line-clamp-6 rounded bg-gray-200 p-2">
            <code>{check.pattern}</code>
          </pre>
        </div>
        {/* NOTE(liamvdv): undefined means that we are using the MatchesView preview (without Decision). It does not represent a decision but is purely behaviour of this component. */}
        {typeof isPassed !== "undefined" && (
          <div>
            <span className="block font-bold">Decision</span>
            {isPassed == null ? "Outstanding" : isPassed ? "Passed" : "Failed"}
          </div>
        )}
      </div>
    </Card>
  );
}

function ClassifyAsView({
  check,
  checkOutput,
}: {
  check: ClassifyAsCheck;
  checkOutput?: ClassifyAsCheckOutput;
}) {
  const badgeColors = [
    { bg: "bg-yellow-50", ring: "ring-yellow-600/20" },
    { bg: "bg-blue-50", ring: "ring-blue-700/10" },
    { bg: "bg-indigo-50", ring: "ring-indigo-700/10" },
    { bg: "bg-purple-50", ring: "ring-purple-700/10" },
    { bg: "bg-pink-50", ring: "ring-pink-700/10" },
    { bg: "bg-green-50", ring: "ring-green-600/20" },
    { bg: "bg-red-50", ring: "ring-red-600/10" },
  ];
  const coloring = Object.fromEntries(
    check.options.map((option, index) => {
      const at = index % badgeColors.length;
      const c = badgeColors[at];
      return [option, `${c.bg} ${c.ring}`];
    })
  );

  return (
    <div>
      <Card className="mt-2 font-sans">
        <h3 className="text-xl font-semibold ">
          {ComparatorToName[check.comparator]} Check
        </h3>
        <div className="space-y-2">
          <div>
            <span className="font-bold">Options</span>
            <ul className="flex gap-2">
              {check.options.map((option, index) => (
                <li key={index}>
                  <span
                    className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium ring-1 ring-inset ${coloring[option]}`}
                  >
                    {option}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <span className="font-bold">Expect</span>
            <ul className="flex gap-2">
              {check.expected.map((option, index) => (
                <li key={index}>
                  <span
                    className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium ring-1 ring-inset ${coloring[option]}`}
                  >
                    {option}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          {checkOutput && (
            <div>
              <span className="font-bold">Output Categories</span>
              <ul className="flex gap-2">
                {check.expected.map((option, index) => (
                  <li key={index}>
                    <span
                      className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium ring-1 ring-inset ${coloring[option]}`}
                    >
                      {option}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export function CheckView({
  check,
  checkOutput,
  isPassed,
}: {
  check: Check;
  checkOutput?: CheckOutput;
  isPassed: boolean | null | undefined;
}) {
  return (
    <>
      {check.comparator === "SEMANTICALLY_EQUIVALENT" && (
        <SemanticallyEquivalentView
          check={check}
          checkOutput={checkOutput as SemnanticallyEquivalentCheckOutput}
        />
      )}
      {check.comparator === "MATCHES" && (
        <MatchesView check={check} isPassed={isPassed} />
      )}
      {check.comparator === "NOT_MATCHES" && (
        <MatchesView check={check} isPassed={isPassed} />
      )}
      {check.comparator === "CLASSIFY_AS" && (
        <ClassifyAsView
          check={check}
          checkOutput={checkOutput as ClassifyAsCheckOutput}
        />
      )}
    </>
  );
}

// NOTE(memben): partly duplicate to Converation
export function ConversationView({
  conversation,
  classNameLast,
}: {
  conversation: Conversation;
  classNameLast?: string;
}) {
  return (
    <>
      <h3 className="mt-4 text-xl font-semibold">Conversation</h3>
      <Card className="mt-2 p-0">
        <div className="flex-1 overflow-y-auto rounded-lg bg-white text-sm">
          {conversation.messages.map((message, index) => (
            <div key={index}>
              {message.role === "user" && (
                <div className="flex flex-row px-4 py-8 sm:px-6">
                  <UserCircleIcon className="mr-2 h-10 w-10 flex-shrink-0 text-primary" />
                  <p className="flex items-center">{message.content}</p>
                </div>
              )}
              {message.role === "assistant" && (
                <div
                  className={`flex bg-slate-100 px-4 py-8  dark:bg-slate-900 sm:px-6 ${index === conversation.messages.length - 1 ? classNameLast : ""}`}
                >
                  <CubeTransparentIcon className="mr-2 flex h-10 w-10 flex-shrink-0  rounded-full text-blue-600" />
                  <p className="flex items-center">{message.content}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </Card>
    </>
  );
}
