import { AlertCircle, Check, Download, FileDown } from "lucide-react";
import { useEffect, useState } from "react";
import { MimeType, SourceWithContent } from "@/client";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useQuery } from "@tanstack/react-query";
import { TextDiffViewer } from "./SourceViewDiff";
import { getSourceFileOptions } from "@/client-custom/@tanstack/react-query";

const isTextType = (mimeType: MimeType): boolean => {
  return mimeType.startsWith("text/");
};
const isImageType = (mimeType: MimeType): boolean => {
  return mimeType.startsWith("image/");
};
const isPdfType = (mimeType: MimeType): boolean => {
  return mimeType === "application/pdf";
};

export const SourceView = ({ source }: { source: SourceWithContent }) => {
  const [downloaded, setDownloaded] = useState(false);
  const [textContent, setTextContent] = useState<string | null>(null);
  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  const {
    data: fileBlob,
    isPending,
    isError,
  } = useQuery({
    ...getSourceFileOptions({
      path: {
        project_id: source.project_id,
        source_id: source.id,
      },
    }),
  });

  useEffect(() => {
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objectUrl]);

  useEffect(() => {
    if (!fileBlob) return;

    if (isTextType(source.file_type)) {
      void fileBlob.text().then(setTextContent);
    } else if (isImageType(source.file_type) || isPdfType(source.file_type)) {
      const url = URL.createObjectURL(fileBlob);
      setObjectUrl(url);
    }
  }, [fileBlob, source.file_type]);

  const handleDownload = () => {
    if (fileBlob) {
      const url = URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = source.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setDownloaded(true);
      setTimeout(() => setDownloaded(false), 2000);
    }
  };

  const LoadingState = () => (
    <Card className="w-full max-w-4xl mx-auto p-8 my-4">
      <div className="space-y-6">
        <Skeleton className="h-4 w-3/4" />
        <Skeleton className="h-4 w-4/5" />
        <Skeleton className="h-4 w-2/3" />
        <Skeleton className="h-4 w-3/4" />
      </div>
    </Card>
  );

  const DownloadPrompt = ({ onClick }: { onClick: () => void }) => (
    <Card
      className="w-full max-w-4xl mx-auto p-12 my-4 cursor-pointer hover:bg-gray-50 transition-colors"
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center text-gray-500 space-y-4">
        <div className="rounded-full bg-gray-100 p-4">
          <FileDown className="h-8 w-8" />
        </div>
        <div className="text-center">
          <p className="text-lg font-medium">{source.name}</p>
          <p className="text-sm text-gray-400 mt-2">
            Preview not available for this file type
          </p>
          <p className="text-sm text-gray-400">Click to download</p>
        </div>
      </div>
    </Card>
  );

  const ErrorState = () => (
    <Card className="w-full max-w-4xl mx-auto p-8 my-4">
      <div className="flex flex-col items-center justify-center text-gray-500 space-y-4">
        <div className="rounded-full bg-red-100 p-4">
          <AlertCircle className="h-8 w-8 text-red-500" />
        </div>
        <div className="text-center">
          <p className="text-lg font-medium">Error Loading File</p>
          <p className="text-sm text-gray-400 mt-2">
            Unable to load the file content
          </p>
        </div>
      </div>
    </Card>
  );

  const renderContent = () => {
    if (isPending) {
      return <LoadingState />;
    }

    if (isError) {
      return <ErrorState />;
    }

    if (isTextType(source.file_type) && textContent) {
      return (
        <Card className="w-full max-w-4xl mx-auto my-4 overflow-hidden">
          <TextDiffViewer
            oldText={textContent}
            newText={textContent}
            fileType={source.file_type}
            splitView={false}
            hideSecondLineNumbers={true}
            showDiffOnly={false}
          />
        </Card>
      );
    }

    if (isImageType(source.file_type) && objectUrl) {
      return (
        <Card className="w-full max-w-4xl mx-auto p-4 my-4">
          <div className="flex justify-center">
            <img
              src={objectUrl}
              alt={source.name}
              className="max-w-full max-h-[800px] object-contain"
            />
          </div>
        </Card>
      );
    }

    if (isPdfType(source.file_type) && objectUrl) {
      return (
        <Card className="w-full max-w-4xl mx-auto my-4">
          <div className="w-full h-[800px]">
            <object
              data={objectUrl}
              type="application/pdf"
              className="w-full h-full"
            >
              <DownloadPrompt onClick={handleDownload} />
            </object>
          </div>
        </Card>
      );
    }

    return <DownloadPrompt onClick={handleDownload} />;
  };

  return (
    <div>
      <div className="w-full h-full flex flex-col mt-8 relative">
        <div className="flex-1 min-h-0">{renderContent()}</div>
      </div>
      <Button
        size="sm"
        variant="secondary"
        className="absolute right-4 top-2 shadow-lg z-10"
        onClick={handleDownload}
        disabled={isPending || !fileBlob}
      >
        {downloaded ? (
          <>
            <Check className="h-4 w-4 mr-2" />
            Downloaded
          </>
        ) : (
          <>
            <Download className="h-4 w-4 mr-2" />
            Download
          </>
        )}
      </Button>
    </div>
  );
};
