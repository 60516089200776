import React from "react";

import {
  DialogHeader,
  DialogTitle,
  ScrollableDialogContent,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";

interface FullScreenDialogProps {
  children: React.ReactNode;
  title: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
}

// Wrap in <Dialog>, note for Dropdown https://ui.shadcn.com/docs/components/dialog#notes
// needed to overwrite the dialog component comp. https://www.youtube.com/watch?v=B8EgmMkI8n0
export default function FullScreenDialogContent({
  children,
  title,
  icon,
  className,
}: FullScreenDialogProps) {
  return (
    <ScrollableDialogContent
      className={cn(
        "flex max-h-[calc(100vh-40px)] max-w-[calc(100vw-40px)]",
        "!rounded-xl p-0",
        "top-[20px] left-[20px] translate-x-0 translate-y-0",
        "overflow-y-scroll",
        className
      )}
    >
      <div className="flex h-full w-full flex-col">
        <DialogHeader className="flex flex-row items-center justify-between border-b px-6 py-4">
          <div className="flex items-center space-x-3">
            {icon && <span className="flex items-center">{icon}</span>}
            <DialogTitle className="text-xl">{title}</DialogTitle>
          </div>
        </DialogHeader>

        <div className="flex-1 p-6">{children}</div>
      </div>
    </ScrollableDialogContent>
  );
}
