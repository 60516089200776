import { useQuery } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { FileText, Copy, Check } from "lucide-react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

import { SourceRead } from "@/client";
import { getSourcePreviewOptions } from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { Skeleton } from "../ui/skeleton";
import { numberHandler, useQueryState } from "@/hooks/useQueryState";

function LoadingSkeleton() {
  return (
    <div className="space-y-4 p-4">
      {/* Simulate paragraphs of text */}
      <Skeleton className="h-4 w-3/4" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-5/6" />
      <Skeleton className="h-4 w-full" />

      {/* Simulate a code block or similar content */}
      <Skeleton className="h-24 w-full rounded-md" />

      {/* More text paragraphs */}
      <Skeleton className="h-4 w-4/5" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-2/3" />
    </div>
  );
}

function ChunkSizeSelector({
  value,
  onChange,
}: {
  value: number;
  onChange: (size: number) => void;
}) {
  const [localValue, setLocalValue] = useState(value);
  const debouncedOnChange = debounce(onChange, 2000);

  const handleChange = (newValue: number) => {
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  return (
    <div className="inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground">
      <Label
        htmlFor="chunk-size"
        className="inline-flex items-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium"
      >
        Chunk Size
      </Label>
      <Input
        id="chunk-size"
        type="number"
        value={localValue}
        onChange={(e) => handleChange(parseInt(e.target.value))}
        className="h-8 w-20 rounded-sm bg-background px-2 shadow-sm transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
        min={100}
        max={10000}
      />
    </div>
  );
}

export default function SourceMarkdown({
  source,
  raw = false,
}: {
  source: SourceRead;
  raw?: boolean;
}) {
  const [chunkSize, setChunkSize] = useQueryState(
    "chunk_size",
    1000,
    numberHandler
  ); // HACK(memben): not idomatic - but the way to share chunksize between rendered and raw source
  const [copied, setCopied] = useState(false);

  const { data: preview, isLoading } = useQuery({
    ...getSourcePreviewOptions({
      path: {
        project_id: source.project_id,
        source_id: source.id,
      },
      query: {
        chunk_size: chunkSize,
      },
    }),
  });

  const handleCopy = async () => {
    if (!preview) return;

    const combinedContent = preview.chunks.join(`\n\n${"-".repeat(50)}\n\n`);

    await navigator.clipboard.writeText(combinedContent);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!isLoading && !preview) {
    return (
      <div className="flex flex-col items-center justify-center py-8 text-gray-500">
        <FileText className="mb-2 h-8 w-8" />
        <p>No preview available</p>
      </div>
    );
  }

  const combinedContent = preview?.chunks.join(`\n\n${"-".repeat(50)}\n\n`);

  return (
    <>
      <div className="absolute left-4 top-2 flex items-center gap-4">
        <ChunkSizeSelector value={chunkSize} onChange={setChunkSize} />
      </div>
      <Button
        size="sm"
        variant="secondary"
        className="absolute right-4 top-2 shadow-lg z-10"
        onClick={() => {
          void handleCopy();
        }}
      >
        {copied ? (
          <>
            <Check className="h-4 w-4 mr-2" />
            Copied
          </>
        ) : (
          <>
            <Copy className="h-4 w-4 mr-2" />
            Copy
          </>
        )}
      </Button>

      <div className="p-4 mt-12">
        {isLoading ? (
          <LoadingSkeleton />
        ) : raw ? (
          <pre className="whitespace-pre-wrap text-sm">{combinedContent}</pre>
        ) : (
          <div className="prose prose-sm max-w-none">
            <ReactMarkdown>{combinedContent}</ReactMarkdown>
          </div>
        )}
      </div>
    </>
  );
}
