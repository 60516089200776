import { ReactNode } from "react";

export default function ComingSoon({
  hideCover,
  children,
}: {
  hideCover: boolean;
  children: ReactNode;
}) {
  if (hideCover) {
    return <>{children}</>;
  }
  return (
    <div className="relative pointer-events-none select-none">
      {/* Overlay  */}
      <div className="absolute inset-0 bg-gray-500 opacity-50 flex items-center justify-center z-[5] rounded">
        <span className="bg-white px-5 py-4 rounded font-medium text-lg">
          Coming Soon
        </span>
      </div>
      {children}
    </div>
  );
}
