export interface Conversation {
  messages: Message[];
}

export interface Message {
  role: "user" | "assistant";
  content: string;
}

interface NoGate {
  challenge: "none";
}

interface IdentifierGate {
  challenge: "identifier";
}

interface PasswordGate {
  challenge: "password";
  password: string;
}

interface PlatformUserGate {
  challenge: "platform_user";
}

interface ChatUserGate {
  challenge: "chat_user";
  email_allow_patterns: string[];
}

export type Gate =
  | NoGate
  | IdentifierGate
  | PasswordGate
  | PlatformUserGate
  | ChatUserGate;

export interface AccessControlList {
  gate: Gate;
}

// Define the Alias structure with strict type definition for the 'type' property
export interface Alias {
  project_id: number;
  id: string;
  type: "IMMUTABLE" | "MUTABLE"; // Only 'IMMUTABLE' or 'MUTABLE' are valid values
  deployment_id: number;
  description: string;
  created_at: string;
  updated_at: string;
  access_control_list: AccessControlList;
}

// Define the Deployment structure according to the given JSON schema
export interface Deployment {
  project_id: number;
  id: number;
  aliases: Alias[];
  description: string;
  source_embeddings_index_id: number;
  profile_id: string;
  created_at: string;
}

export interface SemnanticallyEquivalentCheckOutput {
  similarity: number;
}

// Specific check for semantic equivalence
export interface SemanticallyEquivalentCheck {
  comparator: "SEMANTICALLY_EQUIVALENT";
  expected: string;
  threshold: number; // Assumes validation elsewhere (ge=0, le=1)
}

// Empty check output structure (like an empty class in Python)
interface EmptyOutput {}

// Check for matching regular expressions
export interface MatchesCheck {
  comparator: "MATCHES";
  pattern: string;
}

// Check for not matching regular expressions
export interface NotMatchesCheck {
  comparator: "NOT_MATCHES";
  pattern: string;
}

// Output for classification checks
export interface ClassifyAsCheckOutput {
  categories: string[];
}

// Check for classifying input into predefined categories
export interface ClassifyAsCheck {
  comparator: "CLASSIFY_AS";
  options: string[];
  expected: string[];
}

export const ComparatorToName = {
  SEMANTICALLY_EQUIVALENT: "Semantically Equivalent",
  MATCHES: "Matches",
  NOT_MATCHES: "Not Matches",
  CLASSIFY_AS: "Classify As",
};

// Union type combining all the checks
export type Check =
  | SemanticallyEquivalentCheck
  | MatchesCheck
  | NotMatchesCheck
  | ClassifyAsCheck;

// Union type for all possible output types
export type CheckOutput =
  | SemnanticallyEquivalentCheckOutput
  | ClassifyAsCheckOutput
  | EmptyOutput;

export interface Testcase {
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  id: string;
  testsuite_id: string;
  input: Conversation;
  check: Check;
  project_id: number;
}

export interface TestCaseRun {
  id: string;
  testcase_id: string;
  testsuite_run_id: string;
  input: Conversation;
  check: Check;
  status: string;
  passed: boolean | null;
  output: Message | null;
  check_output: CheckOutput | null;
  created_at: Date;
  updated_at: Date;
}

export interface TestSuite {
  id: string;
  name: string;
  description: string;
  project_id: number;
  created_at: string;
  updated_at: string;
  testcases?: Testcase[];
}

export interface TestSuiteRun {
  id: string;
  name: string;
  description: string;
  project_id: number;
  testsuite_id: string;
  deployment_id: number;
  status: "PENDING" | "FAILED" | "COMPLETED";
  passed: boolean | null;
  created_at: Date;
  updated_at: Date;
}
