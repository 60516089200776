import React, { createContext, useContext, useState } from "react";

import { FrameRead } from "@/client";

interface FrameEditorContext {
  currentLocale: string;
  currentTheme: "light" | "dark";
  setCurrentLocale: (locale: string) => void;
  setCurrentTheme: (theme: "light" | "dark") => void;
}

const FrameEditorContext = createContext<FrameEditorContext | undefined>(
  undefined
);

export const FrameEditorProvider: React.FC<{
  children: React.ReactNode;
  frame: FrameRead;
}> = ({ children, frame }) => {
  const [currentLocale, setCurrentLocale] = useState(
    frame.settings.locales[0].locale
  );
  const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("light");

  const value: FrameEditorContext = {
    currentLocale,
    currentTheme,
    setCurrentLocale,
    setCurrentTheme,
  };

  return (
    <FrameEditorContext.Provider value={value}>
      {children}
    </FrameEditorContext.Provider>
  );
};

export const useFrameEditor = (): FrameEditorContext => {
  const context = useContext(FrameEditorContext);
  if (!context) {
    throw new Error("useFrameEditor must be used within a FrameEditorProvider");
  }
  return context;
};
